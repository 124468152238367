import React, { Component } from "react";
import PropTypes from "prop-types";
import { EditorBlock } from "draft-js";
import styled from "styled-components";
import { defaultStyles, capsStyles } from "../../utils/constants";
import { pxToPt } from "../../utils/helpers";

class Block extends Component {
  constructor(props) {
    super(props);

    this.htmlEl = document.getElementsByTagName("html")[0];
  }

  shouldComponentUpdate(nextProps) {
    return this.props.block !== nextProps.block;
  }

  render() {
    const { block } = this.props;

    const blockFontSize = block.getIn(['data', 'font_size']);

    // to calculate our rem output value, we first we need to retrieve the current root font size from the DOM
    // will resolve to either 12 or 14 with our current design system, depending on the responsive breakpoint
    // (narrow screens have a smaller root font size on the html element)
    const rootFontSize = pxToPt(parseInt(window.getComputedStyle(this.htmlEl).fontSize));
    const fontSize =
      blockFontSize !== undefined && blockFontSize !== null && blockFontSize !== 0
        ? blockFontSize / rootFontSize : 1;

    const blockFontFamily = block.getIn(['data', 'font_family']);
    const fontFamily =
      blockFontFamily !== undefined && blockFontFamily !== null && blockFontSize !== ""
        ? blockFontFamily : defaultStyles.fontFamily;

    const blockCaps = block.getIn(['data', 'caps']);
    const transStyle = blockCaps === capsStyles.UPPERCASE ? "uppercase" : "initial";

    let Item = styled.div`
      font-size: ${fontSize}rem;
      font-family: ${fontFamily};
      text-transform: ${transStyle};
    `;
    return (
      <Item>
        <EditorBlock {...this.props}>{this.props.block.text}</EditorBlock>
      </Item>
    );
  }
}

Block.propTypes = {
  block: PropTypes.shape({
    text: PropTypes.string,
    getIn: PropTypes.func,
  })
}

export default Block;
