import { getState } from "../editor";
import Immutable from "immutable";
import { EditorState, Modifier } from "draft-js";

export default function unlockBlock() {
  let { contentState, selectionState } = getState(this.state.editorState);
  let updatedEditorState = this.state.editorState;
  const blockData = Immutable.Map({
    is_modified: true,
    unsupported_content: false,
  });

  if (!this.state.focusedBlocks || this.state.focusedBlocks.length > 1) {
    return;
  }

  contentState = Modifier.mergeBlockData(contentState, selectionState, blockData);
  updatedEditorState = EditorState.push(updatedEditorState, contentState, "change-block-data");
  this.onChange(updatedEditorState);
}
