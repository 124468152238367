import * as React from "react";
const SvgCommonFileTextSettings = (props) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#common-file-text-settings_svg__clip0_4479_37128)" fill="#6134C6">
      <path d="M12.667 5.667a.667.667 0 1 0 0-1.334H8a.667.667 0 0 0 0 1.334h4.667Z" />
      <path d="M15.61 2 14 .39c-.25-.25-.59-.39-.943-.39H5.333A1.333 1.333 0 0 0 4 1.333v4.238a.164.164 0 0 0 .143.165c.328.04.652.11.966.211a.165.165 0 0 0 .224-.154V1.667a.333.333 0 0 1 .334-.334h7.252c.089 0 .173.035.236.098l1.414 1.414a.333.333 0 0 1 .098.236V12a.333.333 0 0 1-.334.333H9.417a.165.165 0 0 0-.162.138.166.166 0 0 0 .004.076c.077.3.088.613.032.918a.165.165 0 0 0 .16.2h5.216A1.333 1.333 0 0 0 16 12.33V2.943A1.333 1.333 0 0 0 15.61 2Z" />
      <path d="M11.666 8A.667.667 0 0 0 11 7.333H8.333A.637.637 0 0 0 8 7.428c-.058.035-.1.125.012.205.396.271.748.6 1.047.974a.157.157 0 0 0 .127.06H11A.667.667 0 0 0 11.666 8ZM8.014 10.436A1.188 1.188 0 0 0 6.88 8.467l-.94.218a.301.301 0 0 1-.356-.207l-.282-.929a1.183 1.183 0 0 0-2.267 0l-.283.929a.301.301 0 0 1-.355.207l-.94-.218a1.188 1.188 0 0 0-1.133 1.968l.658.71a.309.309 0 0 1 0 .417l-.66.71a1.188 1.188 0 0 0 1.134 1.969l.944-.218a.302.302 0 0 1 .356.208l.282.927a1.183 1.183 0 0 0 2.267 0l.283-.928a.3.3 0 0 1 .355-.207l.94.217a1.188 1.188 0 0 0 1.134-1.968l-.659-.71a.309.309 0 0 1 0-.417l.656-.709Zm-3.846 2.918a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z" />
      <path d="M4.168 12.354a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
    </g>
  </svg>
);
export default SvgCommonFileTextSettings;
