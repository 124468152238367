import React, { Component } from "react";
import Switch from "react-switch";
import PropTypes from "prop-types";
import { Color } from "../../utils/constants";

class ToggleSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = { checked: this.props.switchState };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(checked) {
    this.setState({ checked });
  }

  render() {
    return (
      <label>
        <Switch
          disabled={this.props.disabled}
          onChange={this.props.changeHandler}
          checked={this.props.switchState}
          offColor={this.props.offColor ? this.props.offColor : Color.iron}
          onColor={this.props.onColor ? this.props.onColor : Color.iron}
          checkedIcon={false}
          offHandleColor={this.props.offHandleColor ? this.props.offHandleColor : Color.white}
          onHandleColor={this.props.onHandleColor ? this.props.onHandleColor : Color.darkPurple}
          handleDiameter={21}
          uncheckedIcon={false}
          height={14}
          width={36}
          data-test-id={this.props.testId}
        />
      </label>
    );
  }
}

ToggleSwitch.propTypes = {
  disabled: PropTypes.bool,
  switchState: PropTypes.bool,
  changeHandler: PropTypes.func,
  offHandleColor: PropTypes.string,
  onHandleColor: PropTypes.string,
  offColor: PropTypes.string,
  onColor: PropTypes.string,
  testId: PropTypes.string,
};

export default ToggleSwitch;
