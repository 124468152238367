import PropTypes from "prop-types";
import React, { Component } from "react";
import "./Navbar.scss";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <nav className="navbar">
        <div className="navbar__wrapper">{this.props.children}</div>
        {this.props.toolbar}
      </nav>
    );
  }
}

Navbar.propTypes = {
  children: PropTypes.node,
  toolbar: PropTypes.node,
}

export default Navbar;
