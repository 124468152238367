import React, { Component } from "react";
import PropTypes from "prop-types";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import JoButton from "../common/JoButton.js";
import JoLink from "../common/JoLink.js";
import JoIcon from "../common/JoIcon.js";
import RuleGroupSummary from "./RuleGroupSummary";
import RuleGroup from "./RuleGroup";
import Rule from "./Rule";
import ToggleSwitch from "../common/ToggleSwitch";
import "./BaseRuleGroup.scss";
import { Color, VariableType, InputType, VariableSource } from "../../utils/constants";
import classNames from "classnames";
import getIsBlockTextrule, { getIsRowTextrule } from "./lib/textrules/getIsBlockTextrule";
import { clog } from "../../utils/helpers";
import { gaEvent } from "../../utils/analytics";
import { getDateMiddayInTimezone } from "@joseflegal/ui-lib/src/utils/helpers";

class BaseRuleGroup extends Component {
  constructor(props) {
    super(props);

    const baseRuleGroup = this.props.textrule
      ? this.props.ruleGroupsStore.ruleGroupById(this.props.textrule.rule_group_id)
      : null;

    this.state = {
      baseRuleGroup: toJS(baseRuleGroup),
      creating: false,
      selectedRuleGroupId: null,
      selectedRuleGroup: null,
      selectedRuleId: null,
    };

    this.onAddRule = this.onAddRule.bind(this);
    this.onAddGroup = this.onAddGroup.bind(this);
    this.onUpdateTextrule = this.onUpdateTextrule.bind(this);
    this.handleSelectRuleGroup = this.handleSelectRuleGroup.bind(this);
    this.handleSelectRule = this.handleSelectRule.bind(this);

    this.unlinkInvalidTextrule();
  }

  componentDidUpdate(prevProps) {
    if (this.props.textrule && prevProps.textrule !== this.props.textrule) {
      const baseRuleGroup = this.props.ruleGroupsStore.ruleGroupById(this.props.textrule.rule_group_id);
      this.setState({
        baseRuleGroup,
      });
    }
  }

  unlinkInvalidTextrule() {
    const { textrule, textruleId } = this.props;
    if (!textrule) {
      alert("Oops. Something went wrong. Please create the text rule again.");
      this.props.unlinkTextruleHandler(textruleId);
    }
  }

  renderRules(children) {
    const { baseRuleGroup, selectedRuleId } = this.state;
    const { isDisabled } = this.props;

    return children.map((item, i) => {
      if (item.hasOwnProperty("rule_group_id")) {
        return (
          <Rule
            key={item.id}
            operation={baseRuleGroup.logic}
            rule={item}
            variables={this.props.variables}
            isSelected={selectedRuleId === item.id}
            clickHandler={this.handleSelectRule}
            isDisabled={isDisabled}
          />
        );
      } else if (item.hasOwnProperty("parent_group_id")) {
        const headerClasses = classNames({
          "collapsible-header": true,
          "collapsible-header--first": i === 0,
          "collapsible-header--last ": i === children.length - 1,
        });

        // this is the nested ruleGroup that allows user to slide into the nested rules
        return (
          <div
            key={"group-" + item.id}
            data-operation={baseRuleGroup.logic === "all" ? "and" : "or"}
            className={headerClasses}
            onClick={() => this.handleSelectRuleGroup(item)}
          >
            {item.name}
            <div className="collapsible-header__actions-wrapper">
              <span className="collapsible-header__delete" data-test-id="delete-rule-group">
                <JoLink
                  disabled={isDisabled}
                  clickHandler={() => {
                    this.deleteRuleGroup(item);
                  }}
                  title="Delete rule group"
                  iconRight="Bin"
                  testId="delete-rule-group"
                />
              </span>
              <span className="collapsible-header__arrow" data-test-id="open-rule-group">
                <JoIcon title="open group" icon="ArrowRight1" />
              </span>
            </div>
          </div>
        );
      } else {
        return null;
      }
    });
  }

  handleSelectRuleGroup(rule) {
    // Absence of rule (rule = undefined) will close nested rule
    this.setState({
      selectedRuleGroupId: rule ? rule.id : null,
      selectedRuleGroup: rule ? rule : null,
      selectedRuleId: null,
    });
  }

  handleSelectRule(ruleId) {
    this.setState({
      selectedRuleId: ruleId,
    });
  }

  onUpdateTextrule(e) {
    clog("Calling >>> onUpdateTextrule()", Color.green);
    const logic = e ? "all" : "any";
    const { baseRuleGroup } = this.state;
    baseRuleGroup.logic = logic;
    this.setState({
      baseRuleGroup,
    });
    this.props.ruleGroupsStore
      .updateOne({
        updatedRuleGroup: {
          id: baseRuleGroup.id,
          logic: logic,
        },
      })
      .then(() => {
        // Send a request to Google Analystics to register this event
        gaEvent({
          category: "Editing",
          action: "Update rule",
        });
      });
  }

  // set the default props of the rules.
  // we're defaulting to the first variable in the variables array,
  // and setting a default first operation and answer accordingly
  onAddRule() {
    this.setState({
      creating: true,
    });
    const { variables } = this.props;

    let operableVariableCandidate = variables[0];

    for (let i = 0; i < variables.length; i++) {
      const operations = this.props.constantsStore.operationsByVariableTypeId(variables[i].variable_type_id);
      if (operations && operations.length > 0) {
        operableVariableCandidate = variables[i];
        break;
      }
    }

    const variableType = this.props.constantsStore.variableTypeById(operableVariableCandidate.variable_type_id);
    const variableSource = this.props.constantsStore.variableSourceById(operableVariableCandidate.variable_source_id);
    const inputType = this.props.constantsStore.inputTypeById(operableVariableCandidate.input_type_id);
    const parsedDate = getDateMiddayInTimezone({
      date: new Date(),
      targetTimezone: this.props.i18nStore.valuesById("timezone"),
    });
    let initialValue = "*edit this text*";
    if (variableType.shortname === VariableType.Date) {
      initialValue = parsedDate;
    } else if (
      variableType.shortname === VariableType.Number ||
      variableType.shortname === VariableType.Currency ||
      variableType.shortname === VariableType.Percentage
    ) {
      initialValue = "1";
    } else if (variableType.shortname === VariableType.Duration) {
      initialValue = JSON.stringify({
        value: 1,
        unit: "day",
      }); //getDurationInMilliseconds(`1 day`);
    }
    // set up default answerText or get 1st predefined_answer if there are predefined_answers
    const answer =
      variableSource.shortname !== VariableSource.API_Request &&
      [InputType.McMultiple, InputType.McSingle, InputType.McSingleScoring].includes(inputType.shortname)
        ? { value: null, mc_option_ids: [operableVariableCandidate.mc_option_ids[0]] }
        : { value: initialValue, mc_option_ids: [] };

    this.props.rulesStore
      .create({
        createdRule: {
          rule_group_id: this.state.baseRuleGroup.id,
          variable_id: operableVariableCandidate.id,
          operation_id: 1,
          answer,
        },
      })
      .then((rule) => {
        this.setState({
          creating: false,
          selectedRuleId: rule.id,
        });
      });
  }

  onAddGroup() {
    this.setState({
      creating: true,
    });
    clog("Calling >>> onAddGroup()", Color.green);
    this.props.ruleGroupsStore
      .create({
        createdRuleGroup: {
          parent_group_id: this.state.baseRuleGroup.id,
          name: `Group ${this.state.baseRuleGroup.rule_groups.length + 1}`,
        },
      })
      .then(() => {
        this.setState({
          creating: false,
        });
      });
  }

  deleteRuleGroup(group) {
    this.props.ruleGroupsStore.delete({
      deletedRuleGroup: group,
    });
  }

  render() {
    const { baseRuleGroup } = this.state;
    const { blockByKey, textrule, textruleId, variables, isDisabled } = this.props;

    const variablesWithOperations = variables
      ? variables.filter((v) => {
          const variableTypeOperations = this.props.constantsStore.variableTypeOperationById(v.variable_type_id);
          return variableTypeOperations.length;
        })
      : [];

    if (!textrule) {
      console.log("Error: textrule is invalid, textruleId:", textruleId);
      return null;
    }

    if (!baseRuleGroup) {
      return null;
    } else {
      // get all the rules from rulesStore that whose rule_group_id match the baseRuleGroup
      const rules = this.props.rulesStore.allRules.filter((rule) => rule.rule_group_id === baseRuleGroup.id);
      const ruleGroups = this.props.ruleGroupsStore.allRuleGroups.filter(
        (ruleGroup) => ruleGroup.parent_group_id === baseRuleGroup.id
      );

      const children = [...ruleGroups, ...rules].sort((a, b) => a.id - b.id);
      const isBlockLevel = getIsBlockTextrule(blockByKey, textrule.id);
      const isRowLevel = getIsRowTextrule(blockByKey);
      const ruleType = isBlockLevel ? (isRowLevel ? "row" : "block") : "text";

      const containerClasses = classNames({
        "rule-groups-container": true,
        "rule-groups-container--slide-rulegroup": this.state.selectedRuleGroupId,
      });
      return (
        <div className="rule-group-overflow-control-wrapper">
          <div className={containerClasses}>
            <div className="b-rule-group">
              <h2 className="space-below">Rules</h2>
              <RuleGroupSummary
                logic={baseRuleGroup.logic}
                rulesLength={children.length}
                testId="b-rule-group-summary"
              />
              {children.length > 1 && (
                <div className="switch" data-test-id="base-rule-group-logic">
                  <span>Any</span>
                  <ToggleSwitch
                    leftLabel="Any"
                    rightLabel="All"
                    switchState={baseRuleGroup.logic !== "any"}
                    changeHandler={this.onUpdateTextrule}
                    disabled={isDisabled}
                  />
                  <span>All</span>
                </div>
              )}
              {children.length > 0 && (
                <div className="b-rule-group__wrapper">
                  {/* Iterate through children */}
                  {/* Render Rule component */}
                  {this.renderRules(children)}
                </div>
              )}
              <div className="rule-actions-wrapper">
                <div className="rule-actions-add">
                  <JoButton
                    variant="outline"
                    disabled={this.state.creating || !variablesWithOperations.length || isDisabled}
                    clickHandler={this.onAddRule}
                    testId="base-add-rule"
                  >
                    <JoIcon title="add rule" icon="AddCircle" spacing="right" />
                    Add rule
                  </JoButton>
                  <JoButton
                    variant="outline"
                    disabled={this.state.creating || !variablesWithOperations.length || isDisabled}
                    clickHandler={this.onAddGroup}
                    testId="base-add-rule-group"
                  >
                    <JoIcon title="add rule group" icon="AddCircle" spacing="right" />
                    Add group
                  </JoButton>
                </div>
                <JoLink
                  disabled={isDisabled}
                  clickHandler={() => this.props.deleteTextruleHandler(this.props.textrule)}
                  iconLeft="Bin"
                  iconLabel="Bin"
                  testId={"delete-" + ruleType + "-rule"}
                >
                  Delete {ruleType} rule
                </JoLink>
              </div>
            </div>
            <div className="rule-group__column">
              {this.state.selectedRuleGroup && (
                <RuleGroup
                  ruleGroup={this.state.selectedRuleGroup}
                  variables={this.props.variables}
                  closeHandler={this.handleSelectRuleGroup}
                  isDisabled={isDisabled}
                />
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}

BaseRuleGroup.propTypes = {
  isDisabled: PropTypes.bool,
  variables: PropTypes.array,
  textrule: PropTypes.object,
  deleteTextruleHandler: PropTypes.func,
  blockByKey: PropTypes.object,
  ruleGroupsStore: PropTypes.object,
  rulesStore: PropTypes.object,
  constantsStore: PropTypes.object,
  textruleId: PropTypes.number,
  unlinkTextruleHandler: PropTypes.func,
  i18nStore: PropTypes.object,
};
// mobx stores are injected into AutomationEditor so we can access them on component props observer makes component reactive to changes in state
export default inject(
  "textrulesStore",
  "ruleGroupsStore",
  "rulesStore",
  "constantsStore",
  "i18nStore"
)(observer(BaseRuleGroup));
