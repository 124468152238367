import { CharacterMetadata } from "draft-js";
import { generateId } from "../../../../utils/helpers";
import { getState } from ".";
import { getAllTableCellsInRow, getMaxRow } from "../table";

export default function duplicateBlock(editorState, srcKeys) {
  const { contentState } = getState(editorState);
  let blocks = contentState.getBlocksAsArray();
  let srcKey = srcKeys[0];
  let srcIndex = blocks.findIndex(item => item.get("key") === srcKey);
  const srcBlock = blocks[srcIndex];
  let dstRow = 0;

  if (srcBlock.get("type") === "Table") {
    srcKeys = getAllTableCellsInRow(editorState, srcBlock, true);
    srcKey = srcKeys[0];
    srcIndex = blocks.findIndex(item => item.get("key") === srcKey);
    dstRow = 1 + getMaxRow(contentState, srcBlock.getIn(["data", "tableId"]));
  }

  let dstBlocks = [];
  srcKeys.forEach((srcKey) => {
    const copied = copyBlock(contentState, srcKey, dstRow, dstBlocks);
    dstBlocks.push(copied);
  });

  blocks.splice(srcIndex + srcKeys.length, 0, ...dstBlocks);
  return {
    blocks: blocks,
    focusKey: dstBlocks[0].get("key")
  };
}

/**
 *
 * @param {*} contentState
 * @param {*} srcKey Source block
 * @param {*} dstRow If the block is the table cell, we should set Row Number
 * @param {*} dstBlocks If multiple blocks are copied,
 *  we should consider the new generated blocks when generating the new ID/Key
 */
function copyBlock(contentState, srcKey, dstRow, dstBlocks) {
  console.log(srcKey, dstRow, dstBlocks)
  const blocks = contentState.getBlocksAsArray();
  // Generate new unique key / block id
  const srcIds = blocks.map(item => item.getIn(["data", "id"]));
  const srcKeys = blocks.map(item => item.get("key"));
  const dstIds = dstBlocks.map(item => item.getIn(["data", "id"]));
  const dstKeys = dstBlocks.map(item => item.get("key"));
  const blockIds = srcIds.concat(dstIds);
  const blockKeys = srcKeys.concat(dstKeys);
  const newBlockId = generateId(blockIds);
  const newBlockKey = generateId(blockKeys, 5, true);
  const sourceBlockId = contentState.getBlockForKey(srcKey).getIn(["data", "id"]);
  const duplicatedIndex = blocks.findIndex(item => item.get("key") === srcKey);

  /* Construct new block Immutable JS means creating new versions for each change
     possibly a more elegant (if not simple) way of doing this with update() or merge() */
  // first, to avoid errors, simply exit if we can't find these required values
  if (!blocks || !blocks[duplicatedIndex]) {
    return null;
  }
  // init with copied block with updated unique key
  let blk = blocks[duplicatedIndex].set("key", newBlockKey);
  if (blk.get("type") === "Table") {
    blk = blk.setIn(["data", "row"], dstRow);
    console.log('table cell...', blk.toJS())
  }
  // update with newBlockId and
  let copyBlk = blk.setIn(["data", "id"], newBlockId)
    .setIn(["data", "source_id"], sourceBlockId)
    .setIn(["data", "is_looping_block"], false);

  // update list-is-restart with false so that numbering doesn't restart like MS word
  const listitem = copyBlk.getIn(["data", "listItem"]);
  copyBlk = copyBlk.setIn(["data", "listItem"], { ...listitem, isRestartNumbering: false });

  // If there is textrule_id in the blocks data we delete it
  const copyBlkTextruleId = copyBlk.getIn(["data", "textrule_id"])
    ? copyBlk.deleteIn(["data", "textrule_id"])
    : copyBlk;

  // If there are inline_textrules remove them
  const copyBlkInlineTextrules = copyBlkTextruleId.getIn(["data", "inline_textrules"])
    ? copyBlkTextruleId.deleteIn(["data", "inline_textrules"])
    : copyBlkTextruleId;

  // Iterate through block characters removing TEXTRULE !!NOTE add ANSWER_TEXTRULE also!!
  const characterList = copyBlkInlineTextrules.getCharacterList();
  const updatedCharacterList = characterList.map(c => {
    // eslint-disable-next-line
    for (const key of c.style.keys()) {
      if (key.includes("TEXTRULE")) {
        return CharacterMetadata.removeStyle(c, key);
      }
    }
    return c;
  });
  const copyBlkCleanedStyles = copyBlkInlineTextrules.set("characterList", updatedCharacterList);
  return copyBlkCleanedStyles;
}