import { getAllBlocks } from "draftjs-utils";
import Immutable from "immutable";

// returns an Immutable list table cells in that are in the same row
export default function getAllTableCellsInRow(editorState, block, needKey = false) {
  const allBlocks = getAllBlocks(editorState);
  const tableId = block.data.get("tableId");
  const row = block.data.get("row");
  return allBlocks.reduce(
    (acc, next) => {
      // only check if type === Table
      if (next.get("type") === "Table") {
        if (next.getIn(["data", "row"]) === row && next.getIn(["data", "tableId"]) === tableId) {
          if (needKey) {
            acc.push(next.get("key"));
          } else {
            acc = acc.push(next);
          }
        }
        return acc;
      } else {
        return acc;
      }
    },
    needKey ? [] : Immutable.List()
  );
}
