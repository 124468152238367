import { EditorState, Modifier, SelectionState } from "draft-js";

// returns a updatedEditorState with supplied blockData applied whilst avoiding changes to undo/redo stack
export default function changeBlockDataForUnlocked(
  editorState,
  contentState,
  selectionState,
  blockData,
  disableUndo = true
) {
  // to fix merge of undefined error
  if (!blockData || !contentState || !selectionState) {
    return editorState;
  }
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  let key = startKey;
  let updatedContentState = contentState;
  if (startKey === endKey) {
    const block = contentState.getBlockForKey(key);
    if (!block.getIn(["data", "unsupported_content"])) {
      updatedContentState = Modifier.mergeBlockData(updatedContentState, selectionState, blockData);
    }
  } else {
    while (key) {
      const block = contentState.getBlockForKey(key);
      if (!block.getIn(["data", "unsupported_content"])) {
        const newSelectionState = new SelectionState({
          anchorKey: key,
          anchorOffset: key === startKey ? selectionState.getStartOffset() : 0,
          focusKey: key,
          focusOffset: key === endKey ? selectionState.getEndOffset() : block.text.length,
          hasFocus: true,
          isBackward: false,
        });
        updatedContentState = Modifier.mergeBlockData(updatedContentState, newSelectionState, blockData);
      }
      if (key === endKey) {
        break;
      }
      key = contentState.getKeyAfter(key);
    }
  }
  let updatedEditorState = editorState;
  if (disableUndo) {
    updatedEditorState = EditorState.set(updatedEditorState, { allowUndo: false });
  }
  updatedEditorState = EditorState.push(updatedEditorState, updatedContentState, "change-block-data");
  if (disableUndo) {
    updatedEditorState = EditorState.set(updatedEditorState, { allowUndo: true });
  }

  return updatedEditorState;
}
