import { EditorState, SelectionState, Modifier } from "draft-js";
import Immutable from "immutable";
import { gaEvent } from "../../../../utils/analytics";
import { getAllTableCellsInRow } from "../table";

export default function insertBlockTextrule(contentState, selectionState, blockIds, blockKeys) {
  // Send a request to Google Analystics to register this event
  gaEvent({
    category: "Editing",
    action: "Insert rule",
    label: "Block rule",
  });

  let firstBlock = contentState.getBlockForKey(this.state.focusedBlocks[0]);
  let lastBlock = contentState.getBlockForKey(this.state.focusedBlocks[this.state.focusedBlocks.length - 1]);
  // If the first or last block of selection is table cell, we should include all blocks in this row
  if (firstBlock.get("type") === "Table") {
    const firtRowBlocks = getAllTableCellsInRow(this.state.editorState, firstBlock);
    firstBlock = firtRowBlocks.get(0);
    const firstRowIds = firtRowBlocks.map((e) => e.getIn(["data", "id"]));
    blockIds.push(...firstRowIds);
    blockIds = [...new Set(blockIds)];
    const firstRowKeys = firtRowBlocks.map((e) => e.key);
    blockKeys.push(...firstRowKeys);
    blockKeys = [...new Set(blockKeys)];
  }
  if (lastBlock.get("type") === "Table") {
    const lastRowBlocks = getAllTableCellsInRow(this.state.editorState, lastBlock);
    lastBlock = lastRowBlocks.get(lastRowBlocks.size - 1);
    const lastRowIds = lastRowBlocks.map((e) => e.getIn(["data", "id"]));
    blockIds.push(...lastRowIds);
    blockIds = [...new Set(blockIds)];
    const lastRowKeys = lastRowBlocks.map((e) => e.key);
    blockKeys.push(...lastRowKeys);
    blockKeys = [...new Set(blockKeys)];
  }

  this.props.textrulesStore
    .create({
      createdTextrule: {
        block_id: blockIds.join(","),
        document_id: this.props.documentId,
      },
    })
    .then((textrule) => {
      let changedBlocks = this.state.changedBlocks;
      changedBlocks.push(...blockKeys);
      changedBlocks = [...new Set(changedBlocks)];

      // create new EditorState from updated newContentState that contains updated BlockData textrule_id
      const blockData = Immutable.Map({ textrule_id: textrule.id });
      selectionState = new SelectionState({
        anchorKey: firstBlock.get("key"),
        anchorOffset: 0,
        focusKey: lastBlock.get("key"),
        focusOffset: lastBlock.get("text").length,
        hasFocus: true,
        isBackward: false,
      });
      const newContentState = Modifier.mergeBlockData(contentState, selectionState, blockData);
      let newEditorState = this.state.editorState;
      newEditorState = EditorState.set(newEditorState, { allowUndo: false });
      newEditorState = EditorState.push(newEditorState, newContentState);
      newEditorState = EditorState.forceSelection(newEditorState, selectionState);
      newEditorState = EditorState.set(newEditorState, { allowUndo: true });
      this.setState({
        editorState: newEditorState,
        selectedTextruleId: textrule.id,
        creatingTextrule: false,
        changedBlocks,
      });
      this.handleOpenSideBar("textrule");
      this.triggerSave();
    })
    .catch((err) => {
      console.log(err);
      this.setState({
        creatingTextrule: false,
      });
    });
}
