import React, { Component } from "react";
import PropTypes from "prop-types";
import "./BlockMenu.scss";

class BlockMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.htmlEl = document.getElementsByTagName("html")[0];
  }

  // to calculate our rem value, we first we need to retrieve the current root font size from the DOM
  // will resolve to either 12 or 14 with our current design system, depending on the responsive breakpoint
  // (narrow screens have a smaller root font size on the html element)
  rootFontSize() {
    return parseInt(window.getComputedStyle(this.htmlEl).fontSize);
  }

  getOffsetTop(e) {
    if (e.classList.contains("table__cell")) {
      // We should consider table, table__row, table__cell
      return e.offsetParent.offsetTop + e.offsetParent.offsetParent.offsetTop;
    } else {
      return e.offsetTop;
    }
  }

  render() {
    // the block menu is offset dynamically from the top of the document page,
    // but we need to measure this in px, and then apply it in rem
    const { element } = this.props;

    // if the element exists, we want to position the menu
    // if not, we will position it far off the top of the screen (as opposed to hiding it, which would trigger a re-render)
    const topPixels = element ? this.getOffsetTop(element) : -10000;

    // then we divide the measured pixels by this root font size, to get our final rem value
    const offsetTop = topPixels / this.rootFontSize() + "rem";

    // to apply the rem value, its tidy to set up an object to insert
    const BlockMenuStyles = {
      transform: "translateY(" + offsetTop + ") translateZ(0)",
    };

    return (
      <div className="block-menu" style={BlockMenuStyles}>
        <div className="block-menu__wrapper is-vertical">{this.props.children}</div>
      </div>
    );
  }
}

BlockMenu.propTypes = {
  element: PropTypes.object,
  children: PropTypes.node,
};

export default BlockMenu;
