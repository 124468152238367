import { EditorState, Modifier, RichUtils } from "draft-js";
import Immutable from "immutable";
import getOffsetAndLength from "./getOffsetAndLength";

import { gaEvent } from "../../../../utils/analytics";
import { customStyleMap } from "../../../../utils/constants";

export default function insertInlineTextrule(contentState, selectionState, blockByKey) {
  // Send a request to Google Analystics to register this event
  gaEvent({
    category: "Editing",
    action: "Insert rule",
    label: "Text rule",
  });

  // make API request
  this.props.textrulesStore
    .create({
      createdTextrule: {
        block_id: blockByKey.getIn(["data", "id"]),
        document_id: this.props.documentId,
      },
    })
    .then((textrule) => {
      let changedBlocks = this.state.changedBlocks;
      changedBlocks.push(blockByKey.key);
      changedBlocks = [...new Set(changedBlocks)];

      let inline_textrules;
      // get inline_textrules if they exist and push into with new data or create new array with new data
      inline_textrules = blockByKey.hasIn(["data", "inline_textrules"])
        ? blockByKey.getIn(["data", "inline_textrules"])
        : null;
      if (inline_textrules) {
        inline_textrules.push({ id: textrule.id, ...getOffsetAndLength(selectionState) });
      } else {
        inline_textrules = [{ id: textrule.id, ...getOffsetAndLength(selectionState) }];
      }
      const blockData = Immutable.Map({
        inline_textrules,
      });

      // create new EditorState from updated newContentState that contains updated BlockData inline_textrules
      const newContentState = Modifier.mergeBlockData(contentState, selectionState, blockData);
      let newEditorState = this.state.editorState;
      newEditorState = EditorState.set(newEditorState, { allowUndo: false });
      newEditorState = EditorState.push(newEditorState, newContentState);
      const textruleCustomStyle = `TEXTRULE_${textrule.id}`;
      const textruleActiveCustomStyle = `TEXTRULEACTIVE_${textrule.id}`;
      // inserting the custom style for inline textrule should be considered as is_styles_modified=true
      // so word service can handle chunks which includes custom styles for textrule comments
      let editorStateWithStyles = this.handleInlineStyleChange(
        RichUtils.toggleInlineStyle(newEditorState, textruleCustomStyle),
        false
      );
      editorStateWithStyles = EditorState.set(editorStateWithStyles, { allowUndo: true });

      this.handleOpenSideBar("textrule");
      const existingStyles = this.state.customStyleMap;
      this.setState({
        editorState: editorStateWithStyles,
        selectedTextruleId: textrule.id,
        creatingTextrule: false,
        customStyleMap: {
          ...existingStyles,
          [textruleCustomStyle]: customStyleMap.TEXTRULE,
          [textruleActiveCustomStyle]: customStyleMap.TEXTRULE_ACTIVE,
        },
        changedBlocks,
      });
      this.triggerSave();
    })
    .catch((err) => {
      console.log(err);
      this.setState({
        creatingTextrule: false,
      });
    });
}
