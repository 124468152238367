import { EditorState } from "draft-js";

// returns a updatedEditorState with supplied blockData applied whilst avoiding changes to undo/redo stack
export default function changeBlockNumbering(editorState, contentState, dstKey, blockType, depth) {
  let newBlockMap = contentState.getBlockMap();
  let newBlock = contentState.getBlockForKey(dstKey)
    .set('type', blockType)
    .set('depth', depth);
  newBlockMap = newBlockMap.set(dstKey, newBlock);

  let updatedEditorState = EditorState.set(editorState, { allowUndo: false });
  updatedEditorState = EditorState.push(
    updatedEditorState,
    contentState.merge({ blockMap: newBlockMap }),
    'adjust-depth'
  );
  updatedEditorState = EditorState.set(updatedEditorState, { allowUndo: true });
  return updatedEditorState;
}
