import { observable, action, decorate } from "mobx";
import api from "../services/api";

// we dont need to update id ever or data at this stage
const storeKeys = ["name", "send_to_docusign", "expires", "uuid", "document_format_id"];
class DocumentStore {
  id = null;
  data = null;
  name = "";
  send_to_docusign = false;
  expires = false;
  document_format_id = null;
  uuid = null;

  // Ugh we need access to questions but they aren't in a module so send them in here - look at setting questions up in own mobx Module
  setData(data) {
    console.log(data);
    // NOTE! this store provides data for Editor which manages its on state
    // changes in Editor are persited to document.draft_js_state but this store isn't currently being updated based on changes in Editor

    // Turns out entity maps are being persited to db id we dont clear this weird things happen - do we need to generate entities manually
    // data.entityMap = {};
    // As such manual creation of entityMap has been disabled - we will need to implement some checks on init to check for changes to question descriptions
    // or even question deletion? As these will effect inserted answers the offsets of other neighbouring entities
    Object.keys(data).forEach((key) => {
      this[key] = data[key];
    });
    // this.data = data;
    // this.name = name;
    // this.send_to_docusign = send_to_docusign;
  }

  update(updatedDocument) {
    console.log("updatedDocument", updatedDocument);
    return api.documents.update({ id: this.id, ...updatedDocument }).then((document) => {
      console.log(document);
      Object.keys(updatedDocument).forEach((key) => {
        if (storeKeys.includes(key)) {
          this[key] = updatedDocument[key];
        }
      });
      return document;
    });
  }

  modifyEntities(/*data*/) {
    // this is watching for changes in answers / textrules not currently needed but cool left here as fture resources
    // const textRules = toJS(data);
    // const rule = textRules.pop();
    // const ruleJS = rule;
  }
}

decorate(DocumentStore, {
  data: observable,
  textrulesStore: observable,
  modifyEntities: action,
  update: action,
});

export default new DocumentStore();
