import React from "react";
import "./Preloader.scss";
import PropTypes from "prop-types";

const Preloader = ({ ...props }) => {
  // conditional classes for inverted and small variants
  const preloaderClasses = props.inverted ? "preloader is-inverted" : "preloader";
  const spinnerClasses = props.small ? "preloader__spinner is-small" : "preloader__spinner";

  // conditionally render a preloader title or not
  const title = props.title ? <h1 className="preloader__title">{props.title}</h1> : null;

  return (
    <div className={preloaderClasses}>
      {title}
      <div className={spinnerClasses}>
        <div className="preloader__clip"></div>
      </div>
    </div>
  );
};

Preloader.propTypes = {
  title: PropTypes.string,
  small: PropTypes.bool,
  inverted: PropTypes.bool,
};

export default Preloader;
