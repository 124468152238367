import React from "react";
import PropTypes from "prop-types";
import "./Banner.scss";
import classNames from "classnames";

const Banner = ({ ...props }) => {
  const classes = classNames({
    banner: true,
    "is-warning": props.warning,
  });
  return <section className={classes}>{props.children}</section>;
};

Banner.propTypes = {
  warning: PropTypes.bool,
  children: PropTypes.any,
};

export default Banner;
