import {
  SelectionState,
} from "draft-js";
import getAllTableCellsInRow from "./getAllTableCellsInRow";
import validateTableSelection from "./validateTableSelection";
import {
  getState,
} from "../editor";

// In the event user drags selection across multiple table cells this will validate selection
// and expand selection and focusedBlocks to entire row
export default function expandTableSelection(selectedBlocks, editorState) {
  const { contentState } = getState(editorState)
  let selectedTableBlocks, tableSelectionState;

  const selectedBlockKeys = selectedBlocks.map(item => item.key);
  const validSelection = validateTableSelection(selectedBlockKeys, contentState);

  const block = contentState.getBlockForKey(selectedBlockKeys.get(0));

  if (validSelection) {
    selectedTableBlocks = getAllTableCellsInRow(editorState, block);

    const lastTableCell = selectedTableBlocks.get(selectedTableBlocks.size - 1);
    tableSelectionState = new SelectionState({
      anchorKey: selectedTableBlocks.get(0).key,
      anchorOffset: 0,
      focusKey: lastTableCell.get("key"),
      focusOffset: lastTableCell.get("text").length,
      hasFocus: true,
      isBackward: false,
    });

  }
  return { tableSelectionState, selectedTableBlocks };
}