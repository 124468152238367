export default function getIsAnswerBtnDisabled(variables, focusedBlocks, blockByKey, hasInlineStyleInRange) {
  if (!focusedBlocks) {
    return true;
  }
  const blockIsFake = blockByKey.getIn(["data", "is_fake"]);
  const blockIsUnsupported = blockByKey.getIn(["data", "unsupported_content"]);
  if (
    !variables ||
    !variables.length ||
    !focusedBlocks ||
    focusedBlocks.length > 1 ||
    blockIsFake ||
    blockIsUnsupported ||
    hasInlineStyleInRange
  ) {
    return true;
  } else {
    return false;
  }
}
