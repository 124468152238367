import React, { Component } from "react";
import PropTypes from "prop-types";
// import PropTypes from "prop-types";
import "./Table.scss";

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderSingleRow(row, key) {
    return (
      // just wraps a given row of cells in a table__row
      <div key={key} className="table__row">
        {row}
      </div>
    );
  }

  renderRows() {
    // this renders each row from our full array of cells, by checking the "row" property
    // and sorting cells from the same rows into their own arrays
    const rowGroups = this.props.children.reduce((acc, item) => {
      const rowNumber =
        item.props.children.props.block.getIn(["data", "tableId"]) +
        "-" +
        item.props.children.props.block.getIn(["data", "row"]);
      // add cells to the right array for their row (and create the array if it doesn't exist yet)
      if (!acc["row" + rowNumber]) {
        acc["row" + rowNumber] = [];
      }

      // add the cell to the row array
      acc["row" + rowNumber].push(item);
      return acc;
    }, {});

    // make a list of the rows to iterate through
    const values = Object.values(rowGroups);

    // here we will store our rows, wrapped in a div
    const allRows = [];

    // iterate through each row and wrap in a component
    // eslint-disable-next-line
    for (const value in values) {
      allRows.push(this.renderSingleRow(values[value], value));
    }

    return allRows;
  }

  render() {
    return <div className="table">{this.renderRows()}</div>;
  }
}

Table.propTypes = {
  children: PropTypes.node,
};

export default Table;
