import { getState } from ".";
import { getSelectedBlocksList } from "draftjs-utils";

// detect whether the block has changed between newState and previousState (used in onChange)
export default function blockContentHasChanged(newEditorState, oldEditorState) {
  const { contentState: newContentState } = getState(newEditorState);
  const { contentState: oldContentState } = getState(oldEditorState);
  const selectedBlocks = getSelectedBlocksList(newEditorState).filter(
    block => block && block.getIn(["data", "is_deleted"]) === false
  );
  const selectedBlockCount = selectedBlocks.count();
  if (selectedBlockCount > 1 || selectedBlockCount === 0) {
    return newContentState !== oldContentState;
  }
  const curBlock = selectedBlocks.get(0);
  const oldBlock = oldContentState.getBlockForKey(curBlock.get("key"));
  if (!curBlock || !oldBlock) {
    return false;
  }
  return curBlock !== oldBlock;
}
