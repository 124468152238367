import React from "react";

const SvgInformationCircle = (props) => (
  <svg {...props} viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 1.5C3.91421 1.5 4.25 1.16421 4.25 0.75C4.25 0.335786 3.91421 0 3.5 0C3.08579 0 2.75 0.335786 2.75 0.75C2.75 1.16421 3.08579 1.5 3.5 1.5ZM1 2.5C0.723858 2.5 0.5 2.72386 0.5 3C0.5 3.27614 0.723858 3.5 1 3.5H2.5C2.77614 3.5 3 3.72386 3 4V9H1C0.723858 9 0.5 9.22386 0.5 9.5C0.5 9.77614 0.723858 10 1 10H3.5H6C6.27614 10 6.5 9.77614 6.5 9.5C6.5 9.22386 6.27614 9 6 9H4V4C4 3.17157 3.32843 2.5 2.5 2.5H1Z"
    />
  </svg>
);

export default SvgInformationCircle;
