import { Modifier, EditorState, SelectionState } from "draft-js";
import getState from "../editor/getState";
import resetTextruleStyleInBlock from "./resetTextruleStyleInBlock";

// Returns an EditorState with content updated with customStyleRanges TEXTRULEACTIVE_[id] reset and TEXTRULE_[id] applied to matching selectedTextrule
export default function applyTextruleActiveState(editorState, blockKey, selectedTextrule) {
  let { contentState, selectionState } = getState(editorState);

  // reset TEXTRULEACTIVE_[id] resetTextruleStyleInBlock
  editorState = resetTextruleStyleInBlock(editorState, selectionState, blockKey, selectedTextrule.id);
  // Get the contentState out of the editorState post resetTextruleStyleInBlock() so it reflects changes
  contentState = editorState.getCurrentContent();
  const blockByKey = blockKey ? contentState.getBlockForKey(blockKey) : null;

  // set undo to false after resetTextruleStyleInBlock (which returns editorState with undo set to true)
  editorState = EditorState.set(editorState, { allowUndo: false });

  let styleSelection = null;
  let newContentState = contentState;

  const dynTextruleStyle = `TEXTRULE_${selectedTextrule.id}`;
  const dynTextruleActiveStyle = `TEXTRULEACTIVE_${selectedTextrule.id}`;

  // Search block for a styleRange that matches dynTextruleStyle and pass that to the following callback
  blockByKey && blockByKey.findStyleRanges(
    char => {
      return char.hasStyle(dynTextruleStyle);
    },
    (start, end) => {
      // The start and end value may not match actual inline_textrules as they are effected by BOLD, ITALIC etc  
      styleSelection = new SelectionState({
        anchorKey: blockByKey.getKey(),
        anchorOffset: start,
        focusKey: blockByKey.getKey(),
        focusOffset: end,
        hasFocus: true,
        isBackward: false,
      });
    
      newContentState = Modifier.removeInlineStyle(newContentState, styleSelection, dynTextruleStyle);
      newContentState = Modifier.applyInlineStyle(newContentState, styleSelection, dynTextruleActiveStyle);
    }
  );

  editorState = EditorState.push(editorState, newContentState, "change-inline-style");
  // Return selection to its original state before pushing the adjusted editorState
  editorState = EditorState.acceptSelection(editorState, selectionState);
  editorState = EditorState.set(editorState, { allowUndo: true });
  return editorState;
}
