import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { EntityTypes } from "../../../utils/constants";
import { twipToPx, pxToPt } from "../../../utils/helpers";

/*  Tab Entity strategies and components */

// find tab entities in a content block
export function findTabEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === EntityTypes.TAB;
  }, callback);
}

export const TabComponent = (props) => {
  const handleClick = () => {
    props.focusTab(props.blockKey, props.start);
  };

  const htmlEl = document.getElementsByTagName("html")[0];
  const rootFontSize = pxToPt(parseInt(window.getComputedStyle(htmlEl).fontSize));

  // https://docs.microsoft.com/en-us/dotnet/api/documentformat.openxml.wordprocessing.defaulttabstop?view=openxml-2.8.1
  // then automatic tab stops should be generated at 720 twentieths of a point (0.5") intervals across the displayed page.
  const tabWidth = 720;
  const width = twipToPx(tabWidth) / rootFontSize;
  let Span = styled.span`
    &::before {
      width: ${width}rem;
    }
  `;
  return (
    <Span
      title="    "
      className="tab"
      contentEditable={true}
      readOnly
      onClick={handleClick}
      suppressContentEditableWarning
    >
      {props.children}
    </Span>
  );
};

TabComponent.propTypes = {
  blockKey: PropTypes.string,
  start: PropTypes.number,
  children: PropTypes.node,
  focusTab: PropTypes.func,
};
