import React, { useState } from "react";
import PropTypes from "prop-types";
import "./JoToolbarButton.scss";
import classNames from "classnames";
import JoIcon from "./JoIcon";

const JoToolbarButton = ({ ...props }) => {
  const [hover, setHover] = useState(false);
  const classes = classNames({
    "jo-toolbar-button": true,
    "is-active": props.active,
  });

  return (
    <button
      disabled={props.disabled}
      aria-label={props.title}
      className={classes}
      onMouseDown={(event) => {
        event.preventDefault();
        props.clickHandler();
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={(e) => {
        if (props.isStyleCopyBtn) e.stopPropagation();
      }}
      data-tip={props["data-tip"]}
      data-for={props["data-for"]}
      data-test-id={props.testId}
    >
      {hover && props.iconHover && !props.disabled && <JoIcon title={props.title} icon={props.iconHover} />}
      {(!hover || !props.iconHover || props.disabled) && <JoIcon title={props.title} icon={props.icon} />}
      {props.children}
    </button>
  );
};

JoToolbarButton.propTypes = {
  "data-for": PropTypes.string,
  "data-tip": PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.node,
  clickHandler: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  iconHover: PropTypes.string,
  isStyleCopyBtn: PropTypes.bool,
  props: PropTypes.bool,
  title: PropTypes.string,
  testId: PropTypes.string,
};

export default JoToolbarButton;
