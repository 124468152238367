import hasInlineTextrule from "./hasInlineTextrule";

// Use the selectionState and a blocks character List to determine if user has selected a textrule inline or block
// returning it's id if so or 0 if not.
//
// includePrev: indicates if we check the previous character of the cursor
// selectedTextruleId for `Insert Textrule` button should be set
// by also checking the previous one of the cursor.
// But when typing, we should make textrule not-greedy
// by eliminating check-previous.
export default function getSelectionHasTextrule(block, selectionState, includePrev = false) {
  const focusBlockKey = selectionState.getStartKey();
  if (!focusBlockKey) {
    return null;
  }

  // if the data of block contains a textrule_id we have a blocklevel textrule
  if (block && block.hasIn(["data", "textrule_id"]) && block.getIn(["data", "textrule_id"])) {
    return block.getIn(["data", "textrule_id"]);
  }

  const regex = /^TEXTRULE/;

  // Check if the block in question has inline_textrules
  if (hasInlineTextrule(block)) {
    const charList = block.getCharacterList();
    // Lets ge the id from the actual `draft-js` customStyleRanges
    if (selectionState.isCollapsed()) {
      const cursor = selectionState.getStartOffset();
      const char = charList.get(cursor);
      const prevChar = cursor > 0 ? charList.get(cursor - 1) : null;
      // If for some reason char is undefined exit
      // I have noticed this when undoing after pasting text near the end of an inline textrule
      if (!char && !prevChar) {
        return 0;
      }
      const charStyles = char ? char.getStyle() : null;
      const cur = charStyles && charStyles.some((style) => regex.test(style));
      const prevCharStyles = prevChar ? prevChar.getStyle() : null;
      const prev = prevCharStyles && prevCharStyles.some((style) => regex.test(style));
      if (cur) {
        // we've found a TEXTRULE_ or TEXTRULEACTIVE_
        const customStyle = charStyles.toJS().filter((style) => regex.test(style))[0];
        const id = customStyle.split("_")[1];
        return parseInt(id);
        // This may need tweaking dependant on desire UX but here we check the prev char as typing new content
        // in draft-js assumes prev char styles anyway
      } else if (includePrev && prev) {
        // we've found a TEXTRULE_ or TEXTRULEACTIVE_
        const customStyle = prevCharStyles.toJS().filter((style) => regex.test(style))[0];
        const id = customStyle.split("_")[1];
        return parseInt(id);
      } else {
        return 0;
      }
    } else {
      // selection is a range
      let cursorStart = selectionState.getStartOffset();
      let cursorEnd = selectionState.getEndOffset();

      const startChar = charList.get(cursorStart);
      const endChar = charList.get(cursorEnd);

      // If for some reason char is undefined exit copied from above clause as a safeguard
      if (!startChar || !endChar) {
        return 0;
      }

      const endPrevChar = cursorEnd > 0 ? charList.get(cursorEnd - 1) : null;

      const startCharStyles = startChar.getStyle();
      const start = startCharStyles.some((style) => regex.test(style));
      const endCharStyles = endChar.getStyle();
      const end = endCharStyles.some((style) => regex.test(style));
      const endPrevCharStyles = endPrevChar ? endPrevChar.getStyle() : null;
      const endPrev = endPrevCharStyles && endPrevCharStyles.some((style) => regex.test(style));
      if (start && (end || endPrev)) {
        // both start and end regex a textrule but are the id's the same ???
        // could be a selection across adjacent tetxrules
        const customStartStyle = startCharStyles.toJS().filter((style) => regex.test(style))[0];
        const customEndStyle = endCharStyles.toJS().filter((style) => regex.test(style))[0];
        const customEndPrevStyle = endPrevCharStyles
          ? endPrevCharStyles.toJS().filter((style) => regex.test(style))[0]
          : null;

        if (customStartStyle === customEndStyle || customStartStyle === customEndPrevStyle) {
          // style ranges match lets extract id
          const id = customStartStyle.split("_")[1];
          return parseInt(id);
        }
      } else {
        return 0;
      }
    }
  }

  return 0;
}
