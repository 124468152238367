export const gaEvent = event => {
  if (!window.gtag || !event || !event.action) return;

  const data = {};

  if (event.category) {
    data["event_category"] = event.category;
  }

  if (event.label) {
    data["event_label"] = event.label;
  }

  if (event.value) {
    data["value"] = event.value;
  }

  try {
    window.gtag("event", event.action, data);
  } catch (err) {
    console.error(err);
  }
}