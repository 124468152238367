import { observable, action, decorate } from "mobx";

class I18nStore {
  i18nData = [];

  setData(data) {
    for (const constant in data) {
      // set the constant on state obj first
      this.i18nData[constant] = {};

      const elem = data[constant];
      elem.forEach((o) => {
        this.i18nData[constant][o.id] = o;
      });
    }
  }

  setPair(key, value) {
    this.i18nData[key] = value;
  }

  valuesById(id) {
    return this.i18nData[id];
  }
}

decorate(I18nStore, {
  i18nData: observable,
  setData: action,
  valuesById: action,
});

export default new I18nStore();
