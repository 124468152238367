import { EditorState, Modifier, SelectionState } from "draft-js";
import { getState } from "../editor";
import { getSelectionHasTextrule } from "../textrules"

// Here, we should prevent the greedy textrule
// from making the new chars the part of rule
export default function handleBeforeInput(chars) {
  const { editorState } = this.state;
  let { contentState, selectionState } = getState(editorState);
  const startKey = selectionState.getStartKey();
  const block = contentState.getBlockForKey(startKey);
  const ruleId = getSelectionHasTextrule(block, selectionState, false);
  const startOffset = selectionState.getStartOffset();
  let shouldProcess = false;

  if (startOffset === 0 || !ruleId) {
    shouldProcess = true;
  } else if (ruleId) {
    if (selectionState.isCollapsed() && selectionState.getStartOffset() !== 0) {
      const newSelectionState = new SelectionState({
        anchorKey: selectionState.getStartKey(),
        anchorOffset: selectionState.getStartOffset() - 1,
        focusKey: selectionState.getEndKey(),
        focusOffset: selectionState.getEndOffset() - 1,
        hasFocus: true,
        isBackward: false,
      });
      const prevRuleId = getSelectionHasTextrule(block, newSelectionState, false);
      if (ruleId !== prevRuleId) {
        shouldProcess = true;
      }
    }
  }
  if (shouldProcess) {
    // We use the empty style and the empty entity
    // for a character inserted at the beginning of block.
    let style = null;
    const entity = null;

    // we need to check that our selection is collapsed before inserting
    // if not collapsed we need to first handle the selected text (remove it before inserting)
    if (!selectionState.isCollapsed()) {
      // Because our selection state is not collapsed, we first need to remove the text inside the current selection
      contentState = Modifier.removeRange(contentState, selectionState, "forward");
      // Get the selection state AFTER the text is removed
      selectionState = contentState.getSelectionAfter();
    }

    // If selection is considered as not having textrules (ruleId is null),
    // we remove textrule styles at one copied from the previous character
    if (startOffset !== 0) {
      const regex = /^TEXTRULE/;
      style = block.getInlineStyleAt(startOffset - 1);
      style = style.filter(s => !regex.test(s))
    }
    // Insert the text manually so we can control the inline style behavior.
    contentState = Modifier.insertText(contentState, selectionState, chars, style, entity);
    const newEditorState = EditorState.push(editorState, contentState, "insert-characters");
    this.onChange(newEditorState);
    return true;
  }
  return false;
}
