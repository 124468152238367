import React, { Component } from "react";
import PropTypes from "prop-types";
import "./InsertAnswer.scss";
import { inject, observer } from "mobx-react";
import ReactTooltip from "react-tooltip";
import JoCheckbox from "../common/JoCheckbox";

class DocusignEnvelope extends Component {
  constructor(props) {
    super(props);

    this.handleAutoSendUpdate = this.handleAutoSendUpdate.bind(this);
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  handleAutoSendUpdate() {
    const value = !this.props.recipientsStore.envelopeAutoSend;
    this.props.recipientsStore.updateDocusignEnvelopeAutoSend({
      enable_auto_send: value,
    });
  }

  render() {
    const isAutoSendEnabled = this.props.recipientsStore.envelopeAutoSend;

    return (
      <div className="insert-answer">
        <h2 className="tab-header">Send DocuSign envelope</h2>
        <div className="ds-sign-order-checkbox">
          <JoCheckbox
            clickHandler={this.handleAutoSendUpdate}
            checked={isAutoSendEnabled}
            label="Send envelope directly to Recipients"
            id="send-envelope"
          />
        </div>
      </div>
    );
  }
}

DocusignEnvelope.propTypes = {
  recipientsStore: PropTypes.object,
};

export default inject("recipientsStore")(observer(DocusignEnvelope));
