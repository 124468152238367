import React, { Component } from "react";
import PropTypes from "prop-types";
import "./InsertAnswer.scss";
import Select from "react-select";
import JoButton from "../common/JoButton.js";
import JoIcon from "../common/JoIcon.js";
import { resetSelectStyles, InputType, VariableType } from "../../utils/constants";
import DropdownIndicator from "../common/DropdownIndicator";
import { gaEvent } from "../../utils/analytics";
import { inject, observer } from "mobx-react";
import JoTooltip from "../common/JoTooltip";
import ReactTooltip from "react-tooltip";

class InsertAnswer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      connectedVariablesOptions: props.connectedVariables
        .filter((v) => {
          const variableType = this.props.constantsStore.variableTypeById(v.variable_type_id);
          return variableType.shortname !== VariableType.RichText;
        })
        .map((v) => ({ value: v.id, label: v.name })),
      selectedOption: props.connectedVariables
        .filter((v) => {
          const variableType = this.props.constantsStore.variableTypeById(v.variable_type_id);
          return variableType.shortname !== VariableType.RichText;
        })
        .map((v) => ({ value: v.id, label: v.name }))[0],
      selectedAttachmentType: { value: "link", label: "Insert as a link" },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  isFileVariable(variableId) {
    const selVariable = this.props.connectedVariables.find((v) => v.id === variableId);
    const inputType = this.props.constantsStore.inputTypeById(selVariable.input_type_id);
    if (inputType?.shortname === InputType.File) {
      return true;
    }
    return false;
  }

  handleSubmit() {
    const { selectedOption, selectedAttachmentType } = this.state;
    const { label, value } = selectedOption;
    this.props.onSubmit({
      variable_id: value,
      text: label,
      attachment_type: this.isFileVariable(selectedOption.value) ? selectedAttachmentType.value : "",
    });

    gaEvent({
      category: "Editing",
      action: "Insert Answer",
      label: label,
    });

    // If this is removed intermittently weird "crashes" occur on insertAnswer with no error reporting app just reloads
    // is means that handleCloseSideBar in AutomationEditor gets called twice!!
    this.props.closeSideBarHandler();
  }

  handleChangeSelection = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleChangeAttachmentType = (selectedAttachmentType) => {
    this.setState({ selectedAttachmentType });
  };

  render() {
    const { selectedOption, connectedVariablesOptions } = this.state;
    const { selectedAttachmentType } = this.state;
    const attachmentTypes = [
      { value: "link", label: "Insert as a link" },
      { value: "appendix", label: "Insert as an attachment" },
    ];

    let showAttachType = false;
    if (selectedOption && this.isFileVariable(selectedOption.value)) {
      showAttachType = true;
    }

    return (
      <div className="insert-answer">
        <h2 className="space-below">Insert variable</h2>
        <JoTooltip id="top" position="top"></JoTooltip>

        <p>Insert a user response or calculation from available variables.</p>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSubmit();
          }}
        >
          <label className="attachment-label">Variable</label>
          <Select
            value={selectedOption}
            styles={resetSelectStyles}
            components={{ DropdownIndicator }}
            classNamePrefix="react-select"
            onChange={this.handleChangeSelection}
            options={connectedVariablesOptions}
            data-test-id="select-variable"
          />
          {showAttachType && (
            <>
              <div className="attachment-label">
                <span>Choose how you want the file to appear</span>
                <span data-tip="attachment" data-for="top">
                  <span className="attachment-hint">
                    <JoIcon title="info" icon="Info" spacing="" />
                  </span>
                </span>
              </div>
              <Select
                value={selectedAttachmentType}
                styles={resetSelectStyles}
                components={{ DropdownIndicator }}
                classNamePrefix="react-select"
                onChange={this.handleChangeAttachmentType}
                options={attachmentTypes}
              />
            </>
          )}
          <div className="actions-wrapper">
            <JoButton clickHandler={this.handleSubmit} type="submit" testId="confirm-insert-variable">
              <JoIcon title="insert answer" icon="CheckCircle1" spacing="right" />
              Insert
            </JoButton>
            <JoButton clickHandler={this.props.closeSideBarHandler} variant="outline" testId="cancel-sidebar">
              Cancel
            </JoButton>
          </div>
        </form>
      </div>
    );
  }
}

InsertAnswer.propTypes = {
  constantsStore: PropTypes.object,
  closeSideBarHandler: PropTypes.func,
  connectedVariables: PropTypes.array,
  onSubmit: PropTypes.func,
};

export default inject("constantsStore")(observer(InsertAnswer));
