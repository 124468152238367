// this will find a block before or after the given block
// will find the first one in that direction that is NOT deleted
export default function getNotDeletedBlock(contentState, blockKeyToStartFrom, direction) {
  // check we have a valid direction string provided, else return nothing
  if (!(direction === "after" || direction === "before")) {
    return null;
  }
  // this function should return nothing if the contentState isn't given
  // a bit of a catch-all ..
  if (!contentState) {
    return null;
  }

  // find our block, using our direction to choose it
  let blockToCheck;
  if (direction === "before") {
    blockToCheck = contentState.getBlockBefore(blockKeyToStartFrom);
  } else {
    blockToCheck = contentState.getBlockAfter(blockKeyToStartFrom);
  }

  // if no block, exit
  if (!blockToCheck) {
    return null;
  }

  // figure out if the found block is deleted or not
  const blockIsDeleted =
    blockToCheck.hasIn(["data", "is_deleted"]) && blockToCheck.getIn(["data", "is_deleted"]) === true;

  if (blockIsDeleted) {
    // Found a deleted block. Running again to look before the deleted block
    return getNotDeletedBlock(contentState, blockToCheck.getKey(), direction);
  } else {
    // Found a NOT deleted block; Hurrah! Return the block
    return blockToCheck;
  }
}

// tidier alias for above
export function getNotDeletedBlockBefore(contentState, blockKeyToStartFrom) {
  return getNotDeletedBlock(contentState, blockKeyToStartFrom, "before");
}
// tidier alias for above
export function getNotDeletedBlockAfter(contentState, blockKeyToStartFrom) {
  return getNotDeletedBlock(contentState, blockKeyToStartFrom, "after");
}
