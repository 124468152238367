import Immutable from "immutable";

// This function extracts all ranges of TEXTRULE_[id] / TETXRULEACTIVE_[id] and combines them (should they be spilt by BOLD/ITALIC instances)
// via reduce then returns an Immutable map of inline_textrule data for use in replacing block.data.inline_textrules after content changes.
export default function extractTextruleRanges(BlockByKey) {
  const regex = /^TEXTRULE/;
  const textRulesSegments = [];
  let foundStyles;
  BlockByKey.findStyleRanges(
    (char) => {
      const stylesList = char.getStyle();
      if (stylesList.some((style) => regex.test(style))) {
        foundStyles = stylesList.toJS();
        return stylesList.some((style) => regex.test(style));
      }
    },
    (start, end) => {
      textRulesSegments.push({
        style: foundStyles.filter((style) => regex.test(style))[0],
        start: start,
        end: end,
      });
    }
  );

  /* Example created value of extRulesSegments */

  // // 0:
  //     style: "TEXTRULE_1"
  //     start: 19
  //     end: 24
  // // 1:
  //     style: "TEXTRULE_1"
  //     start: 24
  //     end: 28
  // // 2:
  //     style: "TEXTRULE_1"
  //     start: 28
  //     end: 31
  // // 3:
  //     style: "TEXTRULE_2"
  //     start: 51
  //     end: 55
  // // 4:
  //     style: "TEXTRULE_3"
  //     start: 243
  //     end: 266

  const reducedTextrulesObj = textRulesSegments.reduce((acc, next) => {
    const id = parseInt(next.style.split("_")[1]);

    // does acc already have id as prop? If so we are extending its length value
    if (Object.prototype.hasOwnProperty.call(acc, id)) {
      const currentEnd = acc[id].offset + acc[id].length;
      const addedLength = next.end - next.start;
      // if the next start matches the currentEnd then we will extend the current length value with addedLength
      if (next.start === currentEnd) {
        acc[id].length = acc[id].length + addedLength;
      }

      // new Id add a key with id with next values
    } else {
      acc[id] = { id: id, offset: next.start, length: next.end - next.start };
    }
    return acc;
  }, {});

  // Convert to Array
  const textrulesArray = Object.entries(reducedTextrulesObj).map(([, v]) => v);

  return Immutable.Map({ inline_textrules: textrulesArray });
}
