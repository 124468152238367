import React from "react";
import PropTypes from "prop-types";
import "./JoPopupMenu.scss";
import Popup from "reactjs-popup";

class JoPopupMenu extends React.PureComponent {
  render() {
    const { defaultOpen, lockCount, corruptCount, goToFirstUnsupportedBlock } = this.props;
    let triggerText = "locked block";
    let lockText = "locked block";
    const jumpFirst = "Jump to the first block";
    const jumpText = "Jump to the block";
    const issueCount = lockCount + corruptCount;
    if (issueCount !== 1) {
      triggerText += "s";
    }
    if (lockCount !== 1) {
      lockText += "s";
    }
    const trigger = (
      <div className="popup-trigger">
        <span className="circle"></span>
        {issueCount} {triggerText}
      </div>
    );
    return (
      <Popup
        defaultOpen={defaultOpen}
        offsetX={-20}
        offsetY={10}
        trigger={trigger}
        position="bottom right"
        on={["hover", "click", "focus"]}
        mouseLeaveDelay={100}
        contentStyle={{ padding: 0 }}
      >
        {(close) => (
          <div>
            <button type="button" className="close" onClick={close} style={{ position: "absolute" }}>
              &times;
            </button>
            <div className="menu">
              <div className="menu-item">
                <div>
                  {lockCount} {lockText}
                </div>
                {lockCount > 0 && (
                  <button type="button" className="link" onClick={goToFirstUnsupportedBlock}>
                    {lockCount !== 1 ? jumpFirst : jumpText}
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </Popup>
    );
  }
}

JoPopupMenu.propTypes = {
  defaultOpen: PropTypes.bool,
  lockCount: PropTypes.number,
  corruptCount: PropTypes.number,
  goToFirstUnsupportedBlock: PropTypes.func,
};

JoPopupMenu.defaultProps = {
  defaultOpen: true,
};

export default JoPopupMenu;
