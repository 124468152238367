import { EntityTypes } from "../../../../utils/constants";

export default function populateVariableName(state, variables) {
  const entityMap = state.entityMap;
  for (const id in entityMap) {
    const entity = entityMap[id];
    if (entity.type === EntityTypes.ANSWER) {
      const variable = variables.find((v) => v.id === entity.data.variable_id);
      if (variable && entity.data.text) {
        entity.data.text = variable.name;
      }
    }
  }
}
