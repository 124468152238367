import { getState } from "..";
import { getTopRow } from "../../table";

export default function getIsFirstRowMove(editorState, srcKeys) {
  const { contentState } = getState(editorState);
  const blocks = contentState.getBlocksAsArray();
  const srcKey = srcKeys[0];
  const srcBlock = blocks.find((item) => item.get("key") === srcKey);

  if (srcBlock.get("type") === "Table") {
    // prevent table from splitting by moving up first row
    const topRow = getTopRow(contentState, srcBlock.getIn(["data", "tableId"]));
    if (srcBlock.getIn(["data", "row"]) === topRow) {
      return true;
    }
  }
  return false;
}
