import React from "react";

const SvgLogout1 = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M7 14.5a3 3 0 01-2.943-3.594A3.072 3.072 0 017.111 8.5H14a.5.5 0 00.5-.5V1.5a1 1 0 00-1-1H1a1 1 0 00-1 1v21a1 1 0 001 1h12.5a1 1 0 001-1V15a.5.5 0 00-.5-.5z" />
    <path d="M23.625 10.719l-1.159-.926-1.343-1.075-2.5-2A1 1 0 0017 7.5V10H7a1.5 1.5 0 000 3h10v2.5a1 1 0 001.625.781l2.5-2 1.343-1.074 1.159-.927a1 1 0 000-1.562z" />
  </svg>
);

export default SvgLogout1;
