import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import JoIcon from "./JoIcon";
import "./JoCheckbox.scss";

const JoCheckbox = ({ ...props }) => {
  const classes = classNames({
    "jo-checkbox": true,
    "is-disabled": props.disabled,
  });

  return (
    <label className={classes} htmlFor={props.id}>
      <input
        type="checkbox"
        onChange={props.clickHandler}
        checked={props.checked}
        disabled={props.disabled}
        className="jo-checkbox__input"
        id={props.id}
      />
      {props.checked ? (
        <JoIcon icon={"IconCheckboxChecked"} spacing="right" />
      ) : (
        <JoIcon icon={"iconCheckboxUnchecked"} spacing="right" />
      )}
      <span className="jo-checkbox__label">{props.label}</span>
    </label>
  );
};

JoCheckbox.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  label: PropTypes.string,
  clickHandler: PropTypes.func.isRequired,
};

JoCheckbox.defaultProps = {
  disabled: false,
};

export default JoCheckbox;
