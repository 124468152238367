import { toJS } from "mobx";

export default function deleteEmptyTextrule(textruleId, blockKey) {
  if (textruleId && blockKey) {
    const textrule = this.props.textrulesStore.textruleById(textruleId);
    if (textrule) {
      const ruleGroup = toJS(this.props.ruleGroupsStore.ruleGroupById(textrule.rule_group_id));
      if (ruleGroup.rules.length === 0 && ruleGroup.rule_groups.length === 0) {
        this.onDeleteTextrule(textrule, blockKey);
      }
    }
  }
}
