import axios from "axios";
import { getAPIURL, getAuthURL } from "../../utils/url";

import { requestInterceptor, responseInterceptor, authErrorInterceptor } from "./persistentAuth";

// First, checks if it isn't implemented yet.
/* eslint-disable */
if (!String.prototype.format) {
  String.prototype.format = function () {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] !== "undefined" ? args[number] : match;
    });
  };
}
/* eslint-disable */

const httpService = axios.create({
  baseURL: getAPIURL(), // "/api" //
});

export const authService = axios.create({
  baseURL: getAuthURL(),
});

authService.interceptors.request.use(requestInterceptor);
authService.interceptors.response.use(responseInterceptor, authErrorInterceptor);

httpService.interceptors.request.use(requestInterceptor);
httpService.interceptors.response.use(responseInterceptor, authErrorInterceptor);

export default httpService;
