import { getState } from "..";
import { getAllTableCellsInRow, getAllTableCellsInTable, getMaxRow } from "../../table";
import copyBlock from "./copyBlock";
import { procNumberingRestart } from "./moveUpBlock";

export default function moveDownBlock(editorState, srcKeys) {
  const { contentState } = getState(editorState);
  let blocks = contentState.getBlocksAsArray();
  let srcKey = srcKeys[0];
  let srcIndex = blocks.findIndex((item) => item.get("key") === srcKey);
  const srcBlock = blocks[srcIndex];
  let dstRow = 0;

  if (srcBlock.get("type") === "Table") {
    srcKeys = getAllTableCellsInRow(editorState, srcBlock, true);
    srcKey = srcKeys[0];
    srcIndex = blocks.findIndex((item) => item.get("key") === srcKey);
    dstRow = 1 + getMaxRow(contentState, srcBlock.getIn(["data", "tableId"]));
  }

  if (srcIndex + srcKeys.length >= blocks.length) {
    return {
      blocks: blocks,
      focusKey: srcKey,
    };
  }
  let nextIndex = srcIndex + srcKeys.length;
  let nextBlock = blocks[nextIndex];
  let cntBlockToSkip = 0;

  while (nextBlock.getIn(["data", "is_deleted"]) === true) {
    if (nextBlock.get("type") === "Table") {
      if (srcBlock.get("type") === "Table") {
        const blockKeysInRow = getAllTableCellsInRow(editorState, nextBlock, true);
        cntBlockToSkip += blockKeysInRow.length;
      } else {
        const blockKeysInTable = getAllTableCellsInTable(editorState, nextBlock, true);
        cntBlockToSkip += blockKeysInTable.length;
      }
    } else {
      cntBlockToSkip++;
    }
    nextIndex = srcIndex + srcKeys.length + cntBlockToSkip;
    nextBlock = blocks[nextIndex];
  }

  if (nextBlock.get("type") === "Table") {
    if (srcBlock.get("type") === "Table") {
      const blockKeysInRow = getAllTableCellsInRow(editorState, nextBlock, true);
      cntBlockToSkip += blockKeysInRow.length;
    } else {
      const blockKeysInTable = getAllTableCellsInTable(editorState, nextBlock, true);
      cntBlockToSkip += blockKeysInTable.length;
    }
  } else {
    cntBlockToSkip++;
  }

  let dstBlocks = [];
  let checkNumberingRestart = true;
  srcKeys.forEach((srcKey) => {
    let copied = copyBlock(contentState, srcKey, dstRow, false);
    /*
      refs #1242, swap isRestartNumbering only once
     */
    if (checkNumberingRestart) {
      const changedNumberingInfo = procNumberingRestart(copied, nextBlock);
      if (changedNumberingInfo) {
        copied = changedNumberingInfo.srcBlk;
        blocks[nextIndex] = changedNumberingInfo.dstBlk;
        nextBlock = blocks[nextIndex];
        // swap isRestartNumbering only once
        checkNumberingRestart = false;
      }
    }
    dstBlocks.push(copied);
  });

  blocks.splice(srcIndex + srcKeys.length + cntBlockToSkip, 0, ...dstBlocks);
  blocks.splice(srcIndex, srcKeys.length);

  return {
    blocks: blocks,
    focusKey: dstBlocks[0].get("key"),
  };
}
