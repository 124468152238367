import { between } from "../../../../utils/helpers";
import { getSelectionStartEnd } from "../helpers";
// searches blockByKey for given style (eg TEXTRULE) and compares with selectionState for overlap
export default function hasInlineStyleInRange(selectionState, blockByKey, styleRegex) {
  if (!blockByKey) {
    return false;
  }
  // make sure selection is within block
  let styleRangeOverlaps = [];
  if (
    selectionState.getStartKey() === selectionState.getFocusKey() &&
    selectionState.getStartKey() === blockByKey.key
  ) {
    const { start, end } = getSelectionStartEnd(selectionState);
    // https://draftjs.org/docs/api-reference-content-block#findstyleranges
    blockByKey.findStyleRanges(
      function(char) {
        // return char.hasStyle(style);
        const stylesList = char.getStyle();
        if (!stylesList.size) return false;
        return stylesList.some(style => styleRegex.test(style));
      },
      function(rangeStart, rangeEnd) {
        // check found styleRange start or end is within our selection range
        // Determine whether there's an overlap
        // Note that a selection or cursor that *touches* a character counts as an "overlap"
        let overlap = between(rangeStart, start, end) || between(rangeEnd, start, end);

        // For non-textrule formats, we can return this simple overlap
        // if (style === "TEXTRULE") {
        // For textrules, we need to return false if the overlap is zero
        // This is when a selection pushes right up beside an existing textrule, but doesn't contain characters
        const zeroOverlapStart = rangeStart === end;
        const zeroOverlapEnd = rangeEnd === start;

        if (zeroOverlapStart || zeroOverlapEnd) {
          overlap = false;
        }
        // }

        styleRangeOverlaps.push(overlap);
      }
    );
    return styleRangeOverlaps.includes(true);

    // else selection ranges spans multiple blocks so exit
  } else {
    return false;
  }
}
