import React from "react";
import PropTypes from "prop-types";
import "./JoLink.scss";
import classNames from "classnames";
import JoIcon from "../common/JoIcon.js";

const JoLink = ({ ...props }) => {
  const checkIcon = (id, spacing) => {
    if (id) {
      return <JoIcon icon={id} title={props.iconDescription} spacing={spacing} />;
    } else {
      return null;
    }
  };

  const classes = classNames({
    "jo-link": true,
    "is-red": props.variant === "red",
    "is-disabled": props.disabled,
  });

  return (
    <a
      disabled={props.disabled}
      className={classes}
      data-test-id={props.testId}
      title={props.title}
      href={props.href ? props.href : null}
      target={props.target ? props.target : null}
      rel={props.target === "_blank" ? "noopener noreferrer" : null}
      onClick={
        !props.disabled
          ? (e) => {
            e.stopPropagation();
            props.clickHandler(e);
          }
          : null
      }
    >
      {checkIcon(props.iconLeft, "right")}
      {props.children}
      {checkIcon(props.iconRight, "left")}
    </a>
  );
};

JoLink.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  href: PropTypes.string,
  clickHandler: (props, propName, componentName) => {
    if (!props["href"] && (!props[propName] || typeof props[propName] !== "function")) {
      return new Error(componentName + " requires a clickHandler function if not providing a href!");
    }
  },
  disabled: PropTypes.bool,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  iconDescription: PropTypes.string,
  testId: PropTypes.string,
  title: PropTypes.string,
  target: PropTypes.string,
};

export default JoLink;
