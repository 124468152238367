import React, { Component } from "react";
import PropTypes from "prop-types";
import "./DebugInfo.scss";
import { detectBrowser } from "../automation-editor/lib/helpers";
import classNames from "classnames";
import JoButton from "../common/JoButton";
import { convertToRaw } from "draft-js";

class DebugInfo extends Component {
  constructor(props) {
    super(props);

    this.export = this.export.bind(this);
  }

  export() {
    const entityMap = convertToRaw(this.props.contentState)["entityMap"]; //this.props.contentState.getEntityMap();
    const data = {
      blocks: [this.props.blockByKey],
      entityMap: entityMap,
    };
    // Output data so it can be copied for mock / testing
    console.log(JSON.stringify(data));
  }

  renderInlineTextrules(rules, selectedTextruleId) {
    return rules.map((rule, i) => {
      const classes = classNames({
        "debug-info__section": true,
        "debug-info__section--rule": true,
        "debug-info__section--highlight": rule.id === selectedTextruleId,
      });

      return (
        <div className={classes} key={i}>
          <span>
            <strong>InlineTextrule Id: </strong> {rule.id}
          </span>
          <span>
            <strong>Offset: </strong> {rule.offset}
          </span>
          <span>
            <strong>Offset End: </strong> {rule.offset + rule.length}
          </span>
          <span>
            <strong>Length: </strong> {rule.length}
          </span>
        </div>
      );
    });
  }

  renderListData(blockByKey) {
    const listData = blockByKey && blockByKey.getIn(["data", "listItem"]);
    if (listData && listData.listType !== null) {
      return (
        <div>
          <strong> depth: </strong> {blockByKey && blockByKey.getDepth() ? blockByKey.getDepth() : "n/a"}
          <br />
          <strong> list-format: </strong>{" "}
          {blockByKey && blockByKey.getIn(["data", "listItem"]) ? blockByKey.getIn(["data", "listItem"]).format : "n/a"}
          <br />
          <strong> list-instanceId: </strong>{" "}
          {blockByKey && blockByKey.getIn(["data", "listItem"])
            ? blockByKey.getIn(["data", "listItem"]).instanceId
            : "n/a"}
          <br />
          <strong> list-is-restart: </strong>{" "}
          {blockByKey &&
          blockByKey.getIn(["data", "listItem"]) &&
          blockByKey.getIn(["data", "listItem"]).isRestartNumbering
            ? "true"
            : "false"}
          <br />
          <strong> list-type: </strong>{" "}
          {blockByKey && blockByKey.getIn(["data", "listItem"])
            ? blockByKey.getIn(["data", "listItem"]).listType
            : "n/a"}
          <br />
          <strong> list-start: </strong>{" "}
          {blockByKey && blockByKey.getIn(["data", "listItem"]) ? blockByKey.getIn(["data", "listItem"]).start : "n/a"}
          <br />
        </div>
      );
    } else {
      return null;
    }
  }
  render() {
    const { blockByKey, selectionState, selectedTextruleId, selectedLoop, debugTextruleData } = this.props;
    const browser = detectBrowser();

    let answers = [];
    if (blockByKey) {
      blockByKey.findEntityRanges(
        (char) => {
          return char.getEntity();
        },
        (offset) => {
          answers.push(offset);
        }
      );
    }
    return (
      <div className={browser.name === "ie" ? "debug-info is-pushed-down" : "debug-info"}>
        {blockByKey && (
          <div>
            <JoButton buttonType="Editor" variant="gradient" clickHandler={this.export}>
              Export selected Block Data
            </JoButton>
          </div>
        )}
        <div className="debug-info__section">
          <h4>Block data</h4>
          <div>
            <strong>block: </strong>
            {blockByKey ? blockByKey.getKey() : "none"}
            <br />
            <strong> type: </strong>
            {blockByKey ? blockByKey.type : "n/a"}
            <br />
            <strong> length: </strong>
            {blockByKey ? blockByKey.text.length : "n/a"}
            <br />
            <strong> font_size: </strong>
            {blockByKey ? blockByKey.getIn(["data", "font_size"]) : "n/a"}
            <br />
            <strong> font_family: </strong>
            {blockByKey ? blockByKey.getIn(["data", "font_family"]) : "n/a"}
            <br />
            <strong> caps: </strong>
            {blockByKey ? blockByKey.getIn(["data", "caps"]) : "n/a"}
            <br />
            <strong> is_deleted: </strong> {blockByKey && blockByKey.getIn(["data", "is_deleted"]) ? "true" : "false"}
            <br />
            <strong> is_modified: </strong> {blockByKey && blockByKey.getIn(["data", "is_modified"]) ? "true" : "false"}
            <br />
            <strong> is_styles_modified: </strong>{" "}
            {blockByKey && blockByKey.getIn(["data", "is_styles_modified"]) ? "true" : "false"}
            <br />
            <strong> is_fake: </strong> {blockByKey && blockByKey.getIn(["data", "is_fake"]) ? "true" : "false"}
            <br />
            <strong> unsupported_content: </strong>{" "}
            {blockByKey && blockByKey.getIn(["data", "unsupported_content"]) ? "true" : "false"}
            <br />
            <strong> unsupported_tags: </strong>{" "}
            {blockByKey && blockByKey.getIn(["data", "unsupported_tags"])
              ? blockByKey.getIn(["data", "unsupported_tags"])
              : "n/a"}
            <br />
            <strong> cell width/row width: </strong>{" "}
            {blockByKey ? blockByKey.getIn(["data", "width"]) + "/" + blockByKey.getIn(["data", "tableWidth"]) : "n/a"}
            <br />
            <strong> paragraph_id: </strong>
            {blockByKey && blockByKey.getIn(["data", "id"]) ? blockByKey.getIn(["data", "id"]) : "n/a"}
            <br />
            <strong> source_id: </strong>
            {blockByKey && blockByKey.getIn(["data", "source_id"]) ? blockByKey.getIn(["data", "source_id"]) : "n/a"}
            <br />
            <strong> style_source_id: </strong>
            {blockByKey && blockByKey.getIn(["data", "style_source_id"])
              ? blockByKey.getIn(["data", "style_source_id"])
              : "n/a"}
            <br />
            {this.renderListData(blockByKey)}
          </div>
        </div>
        <div className="debug-info__section">
          <h4>Selection State</h4>
          <span>
            <strong> anchorBlock: </strong> {selectionState.getAnchorKey()}
          </span>
          <span>
            <strong> anchorOffset: </strong> {selectionState.getAnchorOffset()}
          </span>
          <span>
            <strong>focusBlock: </strong> {selectionState.getFocusKey()}
          </span>
          <span>
            <strong>focusOffset: </strong> {selectionState.getFocusOffset()}
          </span>
        </div>
        {!!answers.length && (
          <div className="debug-info__section">
            <h4>Entities</h4>
            <span>
              <strong>offsets: </strong> {answers.join(", ")}
            </span>
          </div>
        )}
        {blockByKey && (
          <div className="debug-info__section">
            <h4>Block Textrule</h4>
            <span>
              <strong>Block Textrule Id: </strong>
              {blockByKey.getIn(["data", "textrule_id"]) === null ? "null" : blockByKey.getIn(["data", "textrule_id"])}
            </span>
          </div>
        )}
        {debugTextruleData && debugTextruleData.length && (
          <div className="debug-info__section">
            <h4>Inline Textrules</h4>
            {this.renderInlineTextrules(debugTextruleData, selectedTextruleId)}
          </div>
        )}
        {blockByKey && (
          <div className="debug-info__section">
            <h4>Automation Editor State</h4>
            <span>
              <strong>selectedTextruleId:</strong> {selectedTextruleId === null ? "null" : selectedTextruleId}
            </span>

            <span>
              <strong>selectedLoop:</strong> {selectedLoop.toString()}
            </span>
          </div>
        )}
      </div>
    );
  }
}

DebugInfo.propTypes = {
  blockByKey: PropTypes.object,
  selectionState: PropTypes.object,
  selectedTextruleId: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  debugTextruleData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  contentState: PropTypes.shape({}),
  selectedLoop: PropTypes.any,
};

export default DebugInfo;
