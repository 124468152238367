import getState from "../editor/getState";
import { EntityTypes } from "../../../../utils/constants";
import { gaEvent } from "../../../../utils/analytics";
import { makeClipboardReport } from "../helpers";

// Copy by cmd + C
// We should copy answers, inline-styles as well as the selected text.
export default function handleCopy() {
  const editorState = this.state.editorState;

  makeClipboard(editorState, this.clipboard);

  // Send a request to Google Analystics to register this event
  gaEvent({
    category: "Clipboard",
    action: "Copy",
    label: makeClipboardReport(this.clipboard),
  });
}

// We should copy answers, inline-styles as well as the selected text.
export function makeClipboard(editorState, clipboard = null) {
  if (clipboard === null) {
    clipboard = {};
  }
  const { contentState, selectionState } = getState(editorState);
  var anchorKey = selectionState.getAnchorKey();
  var block = contentState.getBlockForKey(anchorKey);

  // Copy the text
  var selectionStart = selectionState.getStartOffset();
  var selectionEnd = selectionState.getEndOffset();
  var selectedText = block.getText().slice(selectionStart, selectionEnd);
  clipboard.key = block.getKey();
  clipboard.text = selectedText;
  clipboard.entities = [];
  clipboard.inlineStyles = [];

  for (let i = 0; i < selectedText.length; i++) {
    const focusOffset = selectionStart + i;
    // Copy entities
    const entityKey = block.getEntityAt(focusOffset);
    if (entityKey !== null) {
      const entity = contentState.getEntity(entityKey);
      if (
        entity.getType() === EntityTypes.ANSWER ||
        entity.getType() === EntityTypes.TAB ||
        entity.getType() === EntityTypes.DOCUSIGN_TAB
      ) {
        clipboard.entities.push({
          type: entity.getType(),
          key: entityKey,
          offset: i, // the offset of answer inside the selected text
        });
      }
    }
    // Copy inline styles
    const styles = block.getInlineStyleAt(focusOffset);
    if (styles !== null && styles.size !== 0) {
      clipboard.inlineStyles.push({
        styles: styles.toJS().slice(0),
        offset: i, // the offset of answer inside the selected text
      });
    }
  }
  return clipboard;
}
