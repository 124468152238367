import * as React from "react";
const SvgDownloadArrow = (props) => (
  <svg width={16} height={17} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 .5a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8Zm3.165 9.776-2.666 3a.667.667 0 0 1-1 0l-2.667-3a.667.667 0 0 1 .501-1.11h1.5A.167.167 0 0 0 7 9V4.167a1 1 0 0 1 2 0V9a.167.167 0 0 0 .167.167h1.5a.667.667 0 0 1 .498 1.109Z"
      fill="#6134C6"
    />
  </svg>
);
export default SvgDownloadArrow;
