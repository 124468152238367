import PropTypes from "prop-types";
import React, { Component } from "react";
import "./Toolbar.scss";

class Toolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="toolbar">
        <div className="toolbar__wrapper is-horizontal">{this.props.children}</div>
      </div>
    );
  }
}

Toolbar.propTypes = {
  children: PropTypes.node,
};

export default Toolbar;
