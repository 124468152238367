import { getDefaultKeyBinding, KeyBindingUtil } from "draft-js";
import { isAlphaNumericOrSymbol } from "../helpers";

export default function keyBindingFn(e) {
  // There is no reason to be editing content when multiple blocks are selected so we will uses this to prevent keyboard editing content
  if (this.state.focusedBlocks && this.state.focusedBlocks.length !== 1) {
    if (isAlphaNumericOrSymbol(e.keyCode)) {
      return "prevent-keypress";
    }
  }

  if (this.isUnsupportedOrFake()) {
    return "prevent-keypress";
  }

  // Enter/Return - prevent split-block behaviour of draft-js - return 'soft-return' and handle in handleKeyCommand
  if (e.keyCode === 13) {
    return "soft-return";
  }

  // Tab - insert TabEntity
  if (e.keyCode === 9) {
    return "tab";
  }

  if (e.keyCode === 27) {
    return "escape";
  }

  // switch case for keybindings using modifier
  if (KeyBindingUtil.hasCommandModifier(e)) {
    switch (e.keyCode) {
      case 65: // Cmd A
        // If editor is not focused allow default browser behaviour select all text
        if (this.state.focusedBlocks) {
          return "select-all";
        } else {
          return getDefaultKeyBinding(e);
        }
      case 66: // Cmd B
        return "BOLD";
      case 73: // Cmd I
        return "ITALIC";
      case 85: // Cmd U
        return "UNDERLINE";
      case 82: // Cmd R
        return "RELOAD";
      case 67: // Cmd C
        return "copy";
      case 88: // Cmd X
        return "cut";
      default:
        break;
    }
  }

  return getDefaultKeyBinding(e);
}
