// Will check supplied block for inline_textrules for supplied textruleId and return boolean
export default function getIsInlineTextrule(blockByKey, textruleId) {
  const inline_textrules =
    blockByKey.hasIn(["data", "inline_textrules"]) && blockByKey.getIn(["data", "inline_textrules"]);
  const inlineTextruleIndex = inline_textrules && inline_textrules.findIndex(item => item.id === textruleId);
  // coercion means null will equal 0 so handle it seperately
  if (inlineTextruleIndex === null) {
    return false;
  } else if (inlineTextruleIndex >= 0) {
    return true;
  }
  return false;
}
