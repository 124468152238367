import React from "react";

const SvgLoopRemove = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M7.14 5.887a.254.254 0 01-.124.014 6.067 6.067 0 00-2.827.266 6.142 6.142 0 00-2.408 1.525 6.267 6.267 0 00-1.478 2.462 6.341 6.341 0 00.829 5.546 6.185 6.185 0 002.13 1.905 6.08 6.08 0 005.524.134 6.078 6.078 0 001.641-1.222l-.937-3.03-.456.664a3.583 3.583 0 01-1.341 1.244 3.57 3.57 0 01-2.161.298 3.6 3.6 0 01-1.938-1.018 3.665 3.665 0 01-.94-1.65 3.706 3.706 0 01.007-1.907c.169-.624.498-1.19.953-1.643.455-.453 1.425-1.033 2.08-1.033 0 0 .089.019.12.03a.102.102 0 01.068.097.105.105 0 01-.018.06c-.102.155-.47.757-.478.944a1.05 1.05 0 00.496.938 1.008 1.008 0 00.728.126l1.816-.593L7.14 5.887zM15.084 6.508l.788 2.551c.143-.105.294-.2.451-.284a3.57 3.57 0 012.162-.295c.734.13 1.41.486 1.937 1.02a3.774 3.774 0 011.025 2.591c0 .966-.367 1.894-1.025 2.59a3.586 3.586 0 01-2.481 1.07l.78 2.526a6.132 6.132 0 003.504-1.77A6.38 6.38 0 0024 12.085a6.38 6.38 0 00-1.775-4.423 6.144 6.144 0 00-3.306-1.737 6.092 6.092 0 00-3.689.507c-.05.025-.098.05-.146.077z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.402 1.607a1.423 1.423 0 011.78.94l5.741 18.465a1.423 1.423 0 01-2.72.84L8.463 3.387a1.423 1.423 0 01.939-1.78z"
    />
  </svg>
);

export default SvgLoopRemove;
