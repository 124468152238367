import { getState } from "..";
import { getAllTableCellsInRow, getAllTableCellsInTable, getMaxRow } from "../../table";
import copyBlock from "./copyBlock";
import { isListItem } from "../../../../../utils/helpers";

export default function moveUpBlock(editorState, srcKeys) {
  const { contentState } = getState(editorState);
  let blocks = contentState.getBlocksAsArray();
  let srcKey = srcKeys[0];
  let srcIndex = blocks.findIndex((item) => item.get("key") === srcKey);
  const srcBlock = blocks[srcIndex];
  let dstRow = 0;

  if (srcBlock.get("type") === "Table") {
    srcKeys = getAllTableCellsInRow(editorState, srcBlock, true);
    srcKey = srcKeys[0];
    srcIndex = blocks.findIndex((item) => item.get("key") === srcKey);
    dstRow = 1 + getMaxRow(contentState, srcBlock.getIn(["data", "tableId"]));
  }

  if (srcIndex - 1 < 0) {
    return {
      blocks: blocks,
      focusKey: srcKey,
    };
  }
  let prevIndex = srcIndex - 1;
  let prevBlock = blocks[prevIndex];
  let cntBlockToSkip = 0;

  while (prevBlock.getIn(["data", "is_deleted"]) === true) {
    if (prevBlock.get("type") === "Table") {
      if (srcBlock.get("type") === "Table") {
        const blockKeysInRow = getAllTableCellsInRow(editorState, prevBlock, true);
        cntBlockToSkip += blockKeysInRow.length;
      } else {
        const blockKeysInTable = getAllTableCellsInTable(editorState, prevBlock, true);
        cntBlockToSkip += blockKeysInTable.length;
      }
    } else {
      cntBlockToSkip++;
    }
    prevIndex = srcIndex - cntBlockToSkip - 1;
    prevBlock = blocks[prevIndex];
  }

  if (prevBlock.get("type") === "Table") {
    if (srcBlock.get("type") === "Table") {
      const blockKeysInRow = getAllTableCellsInRow(editorState, prevBlock, true);
      cntBlockToSkip += blockKeysInRow.length;
    } else {
      const blockKeysInTable = getAllTableCellsInTable(editorState, prevBlock, true);
      cntBlockToSkip += blockKeysInTable.length;
    }
  } else {
    cntBlockToSkip++;
  }

  let dstBlocks = [];
  let checkNumberingRestart = true;
  srcKeys.forEach((srcKey) => {
    let copied = copyBlock(contentState, srcKey, dstRow, false);
    /*
      refs #1242, swap isRestartNumbering only once
     */
    if (checkNumberingRestart) {
      const changedNumberingInfo = procNumberingRestart(copied, prevBlock);
      if (changedNumberingInfo) {
        copied = changedNumberingInfo.srcBlk;
        blocks[prevIndex] = changedNumberingInfo.dstBlk;
        prevBlock = blocks[prevIndex];
        // swap isRestartNumbering only once
        checkNumberingRestart = false;
      }
    }
    dstBlocks.push(copied);
  });

  blocks.splice(srcIndex, srcKeys.length);
  blocks.splice(srcIndex - cntBlockToSkip, 0, ...dstBlocks);

  return {
    blocks: blocks,
    focusKey: dstBlocks[0].get("key"),
  };
}

export function procNumberingRestart(srcBlk, dstBlk) {
  if (!isListItem(srcBlk) || !isListItem(dstBlk)) {
    return null;
  }
  const srcListItem = srcBlk.getIn(["data", "listItem"]);
  const dstListItem = dstBlk.getIn(["data", "listItem"]);

  // swap isRestartNumbering for the same level, same numbering instance
  if (srcListItem.instanceId === dstListItem.instanceId && srcBlk.depth === dstBlk.depth) {
    srcBlk = srcBlk.setIn(["data", "listItem"], { ...srcListItem, isRestartNumbering: dstListItem.isRestartNumbering });
    dstBlk = dstBlk.setIn(["data", "listItem"], { ...dstListItem, isRestartNumbering: srcListItem.isRestartNumbering });
  }
  return {
    srcBlk,
    dstBlk,
  };
}
