import { EditorState, Modifier } from "draft-js";
import getState from "../editor/getState";
import { makeClipboard } from "./handleCopy";
import { gaEvent } from "../../../../utils/analytics"
import { makeClipboardReport } from "../helpers";

// Cut by cmd + X
// We should copy answers, inline-styles as well as the selected text.
export default function handleCut() {
  const editorState = this.state.editorState;

  // Send a request to Google Analystics to register this event
  gaEvent({
    category: "Clipboard",
    action: "Cut",
    label: makeClipboardReport(this.clipboard),
  });

  makeClipboard(editorState, this.clipboard);
  removeSelection.call(this);
}

function removeSelection() {
  const editorState = this.state.editorState;
  const { contentState, selectionState } = getState(editorState);

  // we need to check that our selection is collapsed
  if (!selectionState.isCollapsed()) {
    const newContentState = Modifier.removeRange(contentState, selectionState, "forward");

    // Use onChange beacuse this is changeing content and changes to inline_textrule data maybe necessary
    this.onChange(EditorState.push(editorState, newContentState, "insert-characters"));
  }
}