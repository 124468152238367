import getState from "../getState";
import getIsFirstRowMove from "./getIsFirstRowMove";
import getIsLastRowMove from "./getIsLastRowMove";

export default function getIsMoveBtnDisabled(editorState, focusKeys, moveDirection) {
  if (!focusKeys || focusKeys.length === 0) {
    return true;
  }
  const { contentState } = getState(editorState);
  const blocks = contentState.getBlocksAsArray();
  if (!blocks || blocks.length === 0) {
    return true;
  }
  const focusKey = focusKeys[0];
  const focusBlock = blocks.find((item) => item.get("key") === focusKey);
  let isMixed = false;
  focusKeys.forEach((k) => {
    const blk = blocks.find((item) => item.get("key") === k);
    if (blk && focusBlock.get("type") !== blk.get("type")) {
      isMixed = true;
    }
  });
  if (isMixed) {
    return true;
  }

  if (moveDirection === "up") {
    // disallow to move up the first row of the table
    if (focusBlock.get("type") === "Table") {
      if (getIsFirstRowMove(editorState, focusKeys)) {
        return true;
      }
    }
    // disallow to move up the first block
    if (blocks[0].get("key") === focusKeys[0]) {
      return true;
    }
  } else if (moveDirection === "down") {
    // disallow to move down the last row of the table
    if (focusBlock.get("type") === "Table") {
      if (getIsLastRowMove(editorState, focusKeys)) {
        return true;
      }
    }
    // disallow to move down the last block
    if (blocks[blocks.length - 1].get("key") === focusKeys[focusKeys.length - 1]) {
      return true;
    }
  }
  return false;
}
