import React, { Component } from "react";
import PropTypes from "prop-types";
import "./ErrorBoundary.scss";

// https://reactjs.org/blog/2017/07/26/error-handling-in-react-16.html
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error) {
    // Display fallback UI
    this.setState({ hasError: true });
    // Have tested this sends error to stackDriver in development
    console.log(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h2 className="space-below text-white error-boundary-message">Something went wrong.</h2>;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
}

export default ErrorBoundary;
