import React, { Component } from "react";
import "./DocuSignRecipientSideBar.scss";
import JoButton from "../common/JoButton.js";
import JoIcon from "../common/JoIcon.js";
import DocuSignRecipient from "./DocuSignRecipient";
import { inject, observer } from "mobx-react";
import InsertDocusignTag from "./InsertDocusignTag";
import Tabs, { Tab } from "./DocusignNavigationTabs";
import DocusignEnvelope from "./DocusignEnvelope";
import JoCheckbox from "../common/JoCheckbox";
import { DocuSignRecipientSourceType, DocuSignRecipientTypeId } from "../../utils/constants";

class DocuSignRecipientSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddingRecipient: false,
      selectedRecipient: null,
    };

    this.addDocusignRecipient = this.addDocusignRecipient.bind(this);
    this.handleSigningOrderUpdate = this.handleSigningOrderUpdate.bind(this);
    this.handleSelectRecipient = this.handleSelectRecipient.bind(this);
  }

  addDocusignRecipient() {
    const generatedKey = new Date().getTime();
    this.props.recipientsStore.add({
      email: "",
      envelope_template_id: this.props.recipientsStore.envelopeTempId,
      envelope_template_recipient_type_id: DocuSignRecipientTypeId.Signer,
      full_name: "",
      id: null,
      question_id_email: null,
      question_id_fullname: null,
      routing_order: 1,
      source: DocuSignRecipientSourceType.Bot,
      key: generatedKey, // To render list with unique key
    });

    // since the recipient is added to store state directly instead of backend,
    // we want to set a artifical delay for the Collapsible animation to play
    setTimeout(() => {
      this.setState({ selectedRecipient: generatedKey });
    });
  }

  handleSigningOrderUpdate() {
    const value = !this.props.recipientsStore.signingOrderStatus;

    this.props.recipientsStore.updateSigningOrderEnabled({
      enable_signing_order: value,
    });
  }

  handleSelectRecipient(id) {
    this.setState({ selectedRecipient: id });
  }

  render() {
    const recipients = this.props.recipientsStore.allRecipients;
    const { selectedRecipient } = this.state;
    const isDocuSignSigningOrderEnabled = this.props.recipientsStore.signingOrderStatus;
    const recipientLength = recipients.length;
    const variables = this.props.variables;
    return (
      <>
        <div className="tabs">
          <Tabs>
            <Tab label="Recipients">
              <div className="b-rule-group">
                <h2 className="tab-header">Add recipients to your DocuSign envelope.</h2>
                {recipientLength > 0 && (
                  <>
                    <div className="ds-sign-order-checkbox">
                      {/* checkbox should be enabled when 2 more recipients are added */}
                      <JoCheckbox
                        clickHandler={this.handleSigningOrderUpdate}
                        label="Signing order"
                        checked={isDocuSignSigningOrderEnabled}
                        disabled={recipientLength < 2}
                        id="signing-order"
                      />
                    </div>
                    <div className="b-rule-group__wrapper">
                      {recipients.map((recipient, index) => (
                        <DocuSignRecipient
                          key={recipient.key || recipient.id}
                          index={index} // To display the Recipient Number
                          keyTracker={recipient.key || recipient.id}
                          hasSigningOrder={isDocuSignSigningOrderEnabled}
                          signingOrderLength={recipientLength}
                          variables={variables}
                          recipient={recipient}
                          clickHandler={this.handleSelectRecipient}
                          isSelected={
                            selectedRecipient &&
                            (selectedRecipient === recipient.key || selectedRecipient === recipient.id)
                              ? true
                              : false
                          }
                        ></DocuSignRecipient>
                      ))}
                    </div>
                  </>
                )}
                <div className="rule-actions-wrapper">
                  <div className="rule-actions-add">
                    <JoButton
                      clickHandler={this.addDocusignRecipient}
                      variant="outline"
                      testId="insert-docusign-recipient"
                      disabled={this.state.isAddingRecipient}
                    >
                      <JoIcon title="add recipient" icon="AddCircle" spacing="right" />
                      Add recipient
                    </JoButton>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab label="Tags">
              <InsertDocusignTag
                onSubmit={this.props.onSubmit}
                closeSideBarHandler={this.props.closeSideBarHandler}
                connectedVariables={this.props.variables}
              />
            </Tab>
            <Tab label="Envelope">
              <DocusignEnvelope />
            </Tab>
          </Tabs>
        </div>
      </>
    );
  }
}

export default inject("recipientsStore")(observer(DocuSignRecipientSidebar));
