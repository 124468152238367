import { Modifier, EditorState } from "draft-js";
import getState from "../editor/getState";
import extractTextruleRanges from "./extractTextruleRanges";

export default function updateInlineTextrules(editorState, blockByKey) {
  const { contentState, selectionState } = getState(editorState);

  const textrulesDataFromStyleRanges = extractTextruleRanges(blockByKey);

  const updatedContentState = Modifier.mergeBlockData(contentState, selectionState, textrulesDataFromStyleRanges);
  editorState = EditorState.set(editorState, { allowUndo: false });
  editorState = EditorState.push(editorState, updatedContentState, "change-block-data");
  editorState = EditorState.set(editorState, { allowUndo: true });
  return editorState;
}
