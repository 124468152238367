import React, { Component } from "react";
import Modal from "react-modal";
import JoButton from "../common/JoButton";
import JoIcon from "../common/JoIcon";
import "./UnlockBlockModal.scss";
import PropTypes from "prop-types";
import { Links } from "../../utils/constants";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class UnlockBlockModal extends Component {
  render() {
    const { show, handleOk, handleClose } = this.props;
    return (
      <Modal
        isOpen={show}
        contentLabel="Example Modal"
        style={customStyles}
        ariaHideApp={false}
        onRequestClose={handleClose}
        shouldReturnFocusAfterClose={false}
      >
        <h1>Are you sure you want to make this block editable?</h1>
        <div className="body">
          Making this block editable will convert all of the content in the block to regular text.<br></br>
          <br></br>
          You will lose any advanced functionality in the original document template.<br></br>
          <br></br>
          This can&apos;t be undone.<br></br>
          <br></br>
          <br></br>
          <a href={Links.HelpUnlock} target="_blank" rel="noopener noreferrer">
            Learn more about unsupported content →
          </a>
        </div>
        <div className="btn-group">
          <JoButton flush="bottom" buttonType="Editor" clickHandler={handleOk}>
            <JoIcon title="Unlock block" icon="CheckCircle1" spacing="right" />
            Unlock block
          </JoButton>
          <JoButton flush="bottom" variant="outline" buttonType="Editor" clickHandler={handleClose}>
            Cancel
          </JoButton>
        </div>
      </Modal>
    );
  }
}

UnlockBlockModal.propTypes = {
  show: PropTypes.bool,
  handleOk: PropTypes.func,
  handleClose: PropTypes.func,
};

export default UnlockBlockModal;
