import { CharacterMetadata } from "draft-js";

/**
 *
 * @param {*} contentState
 * @param {*} srcKey Source block
 * @param {*} dstRow If the block is the table cell, we should set Row Number
 * @param {*} dstBlocks If multiple blocks are copied,
 *  we should consider the new generated blocks when generating the new ID/Key
 */
export default function copyBlock(contentState, srcKey, dstRow, isDeleteRule = true) {
  const blocks = contentState.getBlocksAsArray();
  // Generate new unique key / block id
  const duplicatedIndex = blocks.findIndex((item) => item.get("key") === srcKey);

  /* Construct new block Immutable JS means creating new versions for each change
     possibly a more elegant (if not simple) way of doing this with update() or merge() */
  // first, to avoid errors, simply exit if we can't find these required values
  if (!blocks || !blocks[duplicatedIndex]) {
    return null;
  }
  // init with copied block with updated unique key
  let blk = blocks[duplicatedIndex];
  if (blk.get("type") === "Table") {
    blk = blk.setIn(["data", "row"], dstRow);
  }
  // update with newBlockId and
  const copyBlk = blk;

  // If there is textrule_id in the blocks data we delete it
  const copyBlkTextruleId =
    isDeleteRule && copyBlk.getIn(["data", "textrule_id"]) ? copyBlk.deleteIn(["data", "textrule_id"]) : copyBlk;

  // If there are inline_textrules remove them
  const copyBlkInlineTextrules =
    isDeleteRule && copyBlkTextruleId.getIn(["data", "inline_textrules"])
      ? copyBlkTextruleId.deleteIn(["data", "inline_textrules"])
      : copyBlkTextruleId;

  // Iterate through block characters removing TEXTRULE !!NOTE add ANSWER_TEXTRULE also!!
  const characterList = copyBlkInlineTextrules.getCharacterList();
  const updatedCharacterList = isDeleteRule
    ? characterList.map((c) => {
        // eslint-disable-next-line
        for (const key of c.style.keys()) {
          if (key.includes("TEXTRULE")) {
            return CharacterMetadata.removeStyle(c, key);
          }
        }
        return c;
      })
    : characterList.map((c) => c);
  const copyBlkCleanedStyles = copyBlkInlineTextrules.set("characterList", updatedCharacterList);
  return copyBlkCleanedStyles;
}
