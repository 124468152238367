import * as React from "react";

const SvgVariableX = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 8c0-1.1.194-2.166.582-3.199A8.859 8.859 0 012.256 2H3.39c-.51.844-.922 1.806-1.234 2.886-.312 1.08-.468 2.114-.468 3.1 0 .995.156 2.038.468 3.128.312 1.08.724 2.038 1.234 2.872H2.256a8.9 8.9 0 01-1.674-2.787A9.033 9.033 0 010 8zM6.755 8L4.896 4.488h2.412c.113.218.213.422.298.612.094.19.18.364.255.526l.454.938h.057c.369-.455.903-1.147 1.603-2.076h2.241L9.365 7.986l1.986 3.768H8.954l-1.15-2.346h-.056l-1.816 2.346H3.705L6.755 8zM16 8c0 1.1-.194 2.17-.582 3.213A8.898 8.898 0 0113.745 14H12.61c.5-.834.908-1.796 1.22-2.886.312-1.1.468-2.142.468-3.128 0-.977-.156-2.005-.468-3.086-.313-1.09-.72-2.056-1.22-2.9h1.135a8.858 8.858 0 011.673 2.801C15.806 5.834 16 6.9 16 8z"
      fill="#6134C6"
    />
  </svg>
);

export default SvgVariableX;
