import { Component } from "react";
import authStore from "../../store/authStore";

const PENDO_API_KEY = process.env.REACT_APP_PENDO_API_KEY;
const APP_NAME = "NO_CODE";
const isInProduction = process.env.NODE_ENV === "production";

export default class InstrumentationProvider extends Component {
  initializePendo() {
    (function (p, e, n, d, o) {
      var v, w, x, y, z;
      o = p[d] = p[d] || {};
      o._q = o._q || [];
      v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
            };
        })(v[w]);
      y = e.createElement(n);
      y.async = !0;
      y.src = "https://cdn.pendo.io/agent/static/" + PENDO_API_KEY + "/pendo.js";
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, "script", "pendo");
  }

  startPendo({ email, organizationName, organizationId, organizationHubspotId }) {
    this.initializePendo();

    window.pendo.initialize({
      visitor: {
        id: email,
        email,
        appName: APP_NAME,
      },
      account: {
        id: organizationHubspotId || organizationId,
        name: organizationName,
      },
    });
  }

  componentDidMount() {
    const shouldStartTrackingVisitor = Boolean(isInProduction);

    const { email, id, organizationName, organizationId, organizationHubspotId } = authStore.decodedTokenData;

    if (shouldStartTrackingVisitor) {
      this.startPendo({
        email,
        id,
        organizationName,
        organizationId,
        organizationHubspotId,
      });
    }
  }

  render() {
    return this.props.children;
  }
}
