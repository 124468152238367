import { SelectionState, EditorState } from "draft-js";
import { getState } from "../editor";

export default function isUnsupportedBlock(editorState, focusedBlocks) {
  // look into some props on our selected blocks to determine if a block is disabled
  if (!focusedBlocks) {
    return false;
  }
  const { contentState } = getState(editorState);
  // when we arrive in the func as a result of inline style change or content change there can only be one focused block
  const blockByKey = contentState.getBlockForKey(focusedBlocks[0]);
  // console.log(selectedBlocks.toJS());
  return blockByKey.getIn(["data", "unsupported_content"]);
}

export function updateUnsupportedCount(editorState) {
  const { contentState } = getState(editorState);
  const blocks = contentState.getBlocksAsArray();
  const unsupportedBlocks = blocks.filter((block) => block.getIn(["data", "unsupported_content"]));
  const unsupportedCount = unsupportedBlocks.length;
  this.setState({ unsupportedCount });
}

export function getFirstUnsupportedBlock(editorState) {
  const { contentState } = getState(editorState);
  const blocks = contentState.getBlocksAsArray();
  const unsupportedBlocks = blocks.filter((block) => block.getIn(["data", "unsupported_content"]));
  if (unsupportedBlocks.length > 0) {
    return unsupportedBlocks[0];
  }
  return null;
}

export function goToFirstUnsupportedBlock(editorState) {
  const targetBlock = getFirstUnsupportedBlock(editorState);
  if (!targetBlock) {
    return;
  }
  const key = targetBlock.get("key");
  const elem = document.querySelector(`[data-offset-key^="${key}"]`);
  if (!elem) {
    return;
  }
  const pos = elem.offsetTop;
  document.getElementById("page-wrap").scrollTop = pos;
  const newSelectionState = new SelectionState({
    anchorOffset: 0,
    focusOffset: 0,
    anchorKey: key,
    focusKey: key,
    hasFocus: true,
  });
  const newEditorState = EditorState.forceSelection(editorState, newSelectionState);
  this.setState({ newEditorState });
}
