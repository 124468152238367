import { EditorState, Modifier, RichUtils } from "draft-js";
import getState from "../editor/getState";
import { insertEntity } from "../entities";
import { getSelectionLength } from "../helpers";
import { gaEvent } from "../../../../utils/analytics";
import { makeClipboardReport } from "../helpers";
import { EntityTypes } from "../../../../utils/constants";

// paste selectionState (cmd V)
export default function handlePaste() {
  pasteText.call(this);
}

function pasteText() {
  const { text, entities, inlineStyles } = this.clipboard;
  if (text === null || text === undefined || text === "") {
    return;
  }
  const editorState = this.state.editorState;
  const { contentState, selectionState } = getState(editorState);

  // here is where we will store our modified content with the inserted text
  let updatedEditorState = editorState;
  let newContentState = contentState;
  let newSelectionState = selectionState;
  const pasteOffset = selectionState.getStartOffset();
  let blockByKey = contentState.getBlockForKey(selectionState.getStartKey());

  // we need to check that our selection is collapsed before pasting
  // if not collapsed we need to first handle the selected text (remove it before pasting)
  if (!selectionState.isCollapsed()) {
    // Because our selection state is not collapsed, we first need to remove the text inside the current selection
    newContentState = Modifier.removeRange(contentState, selectionState, "forward");
    // Get the selection state AFTER the text is removed
    newSelectionState = newContentState.getSelectionAfter();
  }

  let styles = blockByKey.getInlineStyleAt(pasteOffset);
  let prevStyles = pasteOffset === 0 ? null : blockByKey.getInlineStyleAt(pasteOffset - 1);
  if (styles.some((s) => s.includes("TEXTRULE")) && prevStyles && prevStyles.some((s) => s.includes("TEXTRULE"))) {
    // if the cursor is between characters with inline textrule,
    // we should not eliminate inline textrule style.
  } else {
    styles = styles.filter((s) => !s.includes("TEXTRULE"));
  }

  // insert the pasted text after our selection has been collapsed and text removed
  newContentState = Modifier.insertText(newContentState, newSelectionState, text, styles, null);

  if (entities.length > 0 || inlineStyles.length > 0) {
    updatedEditorState = EditorState.push(
      updatedEditorState,
      newContentState,
      "insert-characters" // One or more characters is being inserted at a selection state
    );
    if (inlineStyles.length > 0) {
      updatedEditorState = pasteInlineStyles.call(this, updatedEditorState, pasteOffset);
    }

    // With selectionIsCollapsed, changeLength will be the pasted text length.
    // Otherwise the difference of the selection being replaced and the pasted text length added
    const changeLength = selectionState.isCollapsed()
      ? text.length
      : -Math.abs(getSelectionLength(selectionState)) + text.length;
    pasteEntity.call(this, updatedEditorState, pasteOffset, changeLength);
  } else {
    // update our state with the new editor content
    // returning true avoids the default paste behaviour of draftjs
    // Use onChange beacuse this is changeing content and changes to inline_textrule data maybe necessary
    this.onChange(EditorState.push(updatedEditorState, newContentState, "insert-characters"));
  }

  // Send a request to Google Analystics to register this event
  gaEvent({
    category: "Clipboard",
    action: "Paste",
    label: makeClipboardReport(this.clipboard),
  });
}

function pasteInlineStyles(editorState, pasteOffset) {
  let updatedEditorState = editorState;
  let { selectionState } = getState(updatedEditorState);
  const { inlineStyles } = this.clipboard;

  updatedEditorState = EditorState.set(updatedEditorState, { allowUndo: false });
  for (let i = 0; i < inlineStyles.length; i++) {
    const { styles, offset } = inlineStyles[i];

    // Change the cursor to the end of pasted text
    selectionState = selectionState.merge({
      anchorOffset: pasteOffset + offset,
      focusOffset: pasteOffset + offset + 1,
    });
    updatedEditorState = EditorState.forceSelection(updatedEditorState, selectionState);

    for (let i = 0; i < styles.length; i++) {
      if (!styles[i].includes("TEXTRULE")) {
        updatedEditorState = RichUtils.toggleInlineStyle(updatedEditorState, styles[i]);
      }
    }
  }
  updatedEditorState = EditorState.set(updatedEditorState, { allowUndo: true });
  return updatedEditorState;
}

function pasteEntity(editorState, pasteOffset, changeLength) {
  const { entities } = this.clipboard;
  let updatedEditorState = editorState;
  for (let i = 0; i < entities.length; i++) {
    const { contentState, selectionState } = getState(updatedEditorState);
    const { type, key, offset } = entities[i];

    // Because our selection state is not collapsed, we first need to remove the text inside the current selection
    let newSelectionState = selectionState.merge({
      anchorOffset: pasteOffset + offset,
      focusOffset: pasteOffset + offset + 1,
    });
    const newContentState = Modifier.removeRange(contentState, newSelectionState, "forward");
    updatedEditorState = EditorState.set(updatedEditorState, { allowUndo: false });
    updatedEditorState = EditorState.push(updatedEditorState, newContentState);
    updatedEditorState = EditorState.set(updatedEditorState, { allowUndo: true });

    // Now we insert an entity
    newSelectionState = selectionState.merge({
      anchorOffset: pasteOffset + offset,
      focusOffset: pasteOffset + offset,
    });

    let meta = {};
    if (type === EntityTypes.ANSWER) {
      const entity = contentState.getEntity(key);
      const { variable_id: vid, text: t } = entity.getData();
      meta = {
        variable_id: vid,
        text: t,
      };
    } else if (type === EntityTypes.DOCUSIGN_TAB) {
      const entity = contentState.getEntity(key);
      const { recipientId: rid, text: t } = entity.getData();
      meta = {
        recipientId: rid,
        text: t,
      };
    }
    updatedEditorState = insertEntity.call(this, type, meta, updatedEditorState, newSelectionState);
  }

  // Change the cursor to the end of pasted text
  let { selectionState } = getState(updatedEditorState);
  selectionState = selectionState.merge({
    anchorOffset: pasteOffset + changeLength,
    focusOffset: pasteOffset + changeLength,
  });
  updatedEditorState = EditorState.forceSelection(updatedEditorState, selectionState);

  this.onChange(updatedEditorState);
}
