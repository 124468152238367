import getState from "../editor/getState";

// A modification of getSelectionHasTextrule initially for use with debug if this will likely need rethinking and if it remains in debug
// possibly only selectively import in development
export default function getSelectedInlineTextruleData(editorState) {
  const { contentState, selectionState } = getState(editorState);
  const focusBlockKey = selectionState.getStartKey();
  if (!focusBlockKey) {
    return null;
  }
  const blockByKey = contentState.getBlockForKey(focusBlockKey);
  if (blockByKey.hasIn(["data", "inline_textrules"])) {
    const inline_textrules = blockByKey.getIn(["data", "inline_textrules"]);
    if (inline_textrules !== null && inline_textrules !== undefined) {
      let detectedTextrules = [];
      // iterate thru all inline_textrules checking if current selection start is between stored textrule offset and length
      // when we find one we can exit the loop
      for (let i = 0; i < inline_textrules.length; i++) {
        detectedTextrules.push(inline_textrules[i]);
      }
      if (detectedTextrules.length) {
        return detectedTextrules;
      }
    }
  }

  return null;
}
