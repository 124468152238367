import { hasInlineStyleInRange, getState } from "../editor";
import { getAllTableCellsInRow } from "../table";
import Immutable from "immutable";
import hasInlineTextrule from "./hasInlineTextrule";

function detectInlineTextrule(blocksList) {
  let hasInline = false;
  for (let i = 0; i < blocksList.size; i++) {
    if (hasInlineTextrule(blocksList.get(i))) {
      hasInline = true;
      break;
    }
  }
  return hasInline;
}

// There a numerous situtation when we want to prevent user being able to create a textrule.
// Use of !selectedTextruleId allows user to still edit existing textrules.
export default function getIsTextruleBtnDisabled(
  editorState,
  focusedBlocks,
  blockByKey,
  selectedTextruleId,
  variables,
  creatingTextrule
) {
  // case 1. if there are no focused blocks we cant add textrules.
  //         This also prevents this func erroring due to app data not being ready yet
  //         (focusedBlocks is null on first render)
  // case 2. get previous variables that can have operations for use in textrules
  //         and disabling textrule btn if need be.
  // case 3. if a textrule is in creating, disable the textrule button
  const variablesEmpty = !variables || !variables.length;
  if (!focusedBlocks || variablesEmpty || creatingTextrule) {
    return true;
  }
  const { contentState, selectionState } = getState(editorState);
  const isMultipleBlocks = focusedBlocks.length > 1;
  const blockIsFake = blockByKey.getIn(["data", "is_fake"]);
  const blockHasTextrules = hasInlineTextrule(blockByKey);
  const hasTextruleInRange = hasInlineStyleInRange(selectionState, blockByKey, /^TEXTRULE/);

  if (blockByKey.get("type") === "Table") {
    // Handle tables
    // case 6-1. We need check any cells in rows contain inline_textrules
    const selectedTableBlocks = getAllTableCellsInRow(editorState, blockByKey);
    const hasInlineTextrule = detectInlineTextrule(selectedTableBlocks);
    return (
      // case 4. if the block is fake
      blockIsFake ||
      // case 5. if the selection range includes some inline textures
      hasTextruleInRange ||
      // case 6-1. Block level textrule is not allowed in a block having already inline textrules.
      (hasInlineTextrule && selectionState.isCollapsed() && !selectedTextruleId)
    );
  } else {
    // Handle not table
    if (isMultipleBlocks) {
      // case 6-2. Handle multiple block selections
      const selectedBlocks = Immutable.List(focusedBlocks.map((key) => contentState.getBlockForKey(key)));
      const hasInlineTextrule = detectInlineTextrule(selectedBlocks);
      return hasInlineTextrule;
    } else {
      // Handle selections within a single block
      // case 4. if the block is fake
      // case 5. we will check whether our cursor or selection has a textrule in it
      // case 6-3. it is collapsed and outside of a textrule
      const isCollapsedOutsideRule = blockHasTextrules && selectionState.isCollapsed() && !selectedTextruleId;
      return blockIsFake || hasTextruleInRange || isCollapsedOutsideRule;
    }
  }
}
