/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "../../styles/styles.scss";
import AutomationEditor from "../../components/automation-editor/AutomationEditor";
import Preloader from "../../components/common/Preloader";
import { inject, observer } from "mobx-react";
import api from "../../services/api/";
import Error from "../error/Error";
import { getQueryStringValue, dlog, LEVEL } from "../../utils/helpers";
import { errorMessages, ExportFormat } from "../../utils/constants";
import { queryString } from "../../utils/helpers";
import populateVariableName from "../../components/automation-editor/lib/entities/populateVariableName";
import { getDashboardURL } from "../../utils/url";

export default inject(
  "authStore",
  "documentStore",
  "textrulesStore",
  "recipientsStore",
  "ruleGroupsStore",
  "rulesStore",
  "constantsStore",
  "mcOptionsStore",
  "i18nStore"
)(
  observer(
    class Document extends Component {
      constructor(props) {
        super(props);

        this.updateDocument = this.updateDocument.bind(this);

        this.state = {
          variables: null,
          issue_id: null,
          question_id: null,
          error: null,
          uuid: null,
          document_format_id: ExportFormat.WORD.id,
          debugOn: false,
          is_disabled: false,
          envelopeTemplateRequestPending: false,
        };
      }

      componentDidMount() {
        const token = getQueryStringValue("token");
        this.props.authStore.setToken(token);

        if (!this.props.authStore.userIsLoggedIn) {
          this.setState({
            error: errorMessages[401],
          });
        }

        // set debug mode for production
        const debugOn = queryString["debug"] === "true" || false;
        if (debugOn) {
          console.log("Production debug mode is on 🛠");
        }

        api.constants.get().then((res) => {
          this.props.constantsStore.setData(res);
        });

        api.documents
          // remove this.props.id to trigger catch and error page
          .getOne({ id: this.props.id })
          .then((res) => {
            // dlog(LEVEL.DEBUG, 0, "API get document, res=", res);
            console.log("------API get document  --------------------");
            console.log(res);
            const variables = res.variables;
            this.props.textrulesStore.setData(res.textrules);
            this.props.ruleGroupsStore.setData(res.rule_groups);
            this.props.rulesStore.setData(res.rules);
            this.props.mcOptionsStore.setData(res.mc_options);
            this.props.i18nStore.setData({ date_formats: res.date_formats, currencies: res.currencies });
            this.props.i18nStore.setPair("date_format_id", res.user_account.date_format_id);
            this.props.i18nStore.setPair("timezone", res.user_account.timezone);
            this.props.i18nStore.setPair("currency_id", res.user_account.currency_id);
            this.props.authStore.setPair("docusign_base_uri", res.user_account.docusign_base_uri);
            this.props.authStore.setPair("features", res.organisation.features);

            if (res.send_to_docusign) {
              console.log("get env template");
              this.setState({
                envelopeTemplateRequestPending: true,
              });
              api.documents
                .getEnvelopeTemplate({ id: res.id })
                .then((res) => {
                  console.log(res);
                  this.props.recipientsStore.setData(res.envelopeTemplate.data.envelope_template);
                  this.props.recipientsStore.setDocusignStatus(true);
                  this.setState({
                    envelopeTemplateRequestPending: false,
                  });
                })
                .catch((err) => {
                  console.log(err);
                  this.setState({
                    envelopeTemplateRequestPending: false,
                  });
                });
            }

            populateVariableName(res.draft_js_state, variables);
            const documentStoreData = {
              id: parseInt(this.props.id),
              data: res.draft_js_state,
              name: res.name,
              send_to_docusign: res.send_to_docusign,
              expires: res.expires,
              document_format_id: res.document_format_id,
              uuid: res.uuid,
            };
            this.props.documentStore.setData(documentStoreData);
            // We don't need everything in this object for questions
            this.setState({
              variables,
              issue_id: res.issue_id,
              question_id: res.question_id,
              uuid: res.uuid,
              document_format_id: res.document_format_id,
              debugOn: debugOn,
              is_disabled: res.is_disabled,
            });
          })
          .catch((err) => {
            console.log(err.response);
            console.log(err);
            let message = "Please return to the dashboard and launch editor again.";
            if (err.response) {
              console.log("we in err.message");
              if (errorMessages.hasOwnProperty(err.response.status)) {
                message = errorMessages[err.response.status];
              }
            }
            this.setState({
              error: message,
            });
          });
      }

      isDebugOn() {}

      updateDocument(data, saveData, logIndent) {
        // manually add in the id before make the request
        let newState = {};
        newState.id = this.props.id;
        if (saveData) {
          newState = { ...saveData, ...newState };
        } else {
          newState.draft_js_state = data;
        }
        // make PUT request to update data

        dlog(LEVEL.DEBUG, logIndent, "updateDocument, calling PUT api ...");

        // Dont catch errors here - catch is AutomationEditor.js
        return api.documents.update(newState).then((res) => {
          return res;
        });
      }

      render() {
        // create a dashboardUrl based on wheather we have qusetion/issue values to work with
        const dashboardUrl =
          this.state.issue_id && this.state.question_id
            ? getDashboardURL() + "/#/issues/" + this.state.issue_id + "/questions/" + this.state.question_id
            : getDashboardURL();

        // Dont render AutomationEditor until envelopeTemplateRequest is returned to avoid reactivity headaches with DocusignRecipient Button
        const envelopeTemplateRequestPending =
          this.props.documentStore.send_to_docusign && this.state.envelopeTemplateRequestPending;
        return (
          <div className="App" id="App">
            {this.state.error && <Error dashboardUrl={dashboardUrl}>{this.state.error}</Error>}
            {!envelopeTemplateRequestPending &&
            this.props.documentStore.name &&
            this.props.documentStore.data !== null &&
            this.props.authStore.userIsLoggedIn ? (
              <AutomationEditor
                data={this.props.documentStore.data}
                debugOn={this.state.debugOn}
                updateDocument={this.updateDocument}
                textrules={this.props.textrulesStore.textrules}
                variables={this.state.variables}
                documentId={parseInt(this.props.id)}
                documentUuid={this.state.uuid}
                documentFormatId={this.state.document_format_id}
                questionId={this.state.question_id}
                issueId={this.state.issue_id}
                isDisabled={this.state.is_disabled}
              />
            ) : (
              <Preloader title="Loading&hellip;" />
            )}
          </div>
        );
      }
    }
  )
);
