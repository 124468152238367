import { getState } from "../editor";
import { getAllTableCellsInRow } from "../table";
import Immutable from "immutable";
import {
  EditorState,
  SelectionState,
  Modifier,
} from "draft-js";

export default function markLoopingBlock(editorState, setTo) {
  let { contentState, selectionState } = getState(editorState);
  let updatedEditorState = editorState;

  // set looping and merge with block data
  const blockData = Immutable.Map({ is_looping_block: setTo });

  const firstBlock = this.state.focusedBlocks.reduce((acc, next) => {
    acc = contentState.getBlockForKey(next);
    if (acc.get("type") === "Table") {
      const selectedTableBlocks = getAllTableCellsInRow(editorState, acc);
      acc = selectedTableBlocks.get(0);
    }
    return acc;
  }, false);

  const lastBlock = this.state.focusedBlocks.reduce((acc, next) => {
    acc = contentState.getBlockForKey(next);
    if (acc.get("type") === "Table") {
      const selectedTableBlocks = getAllTableCellsInRow(editorState, acc);
      acc = selectedTableBlocks.get(selectedTableBlocks.size - 1);
    }
    return acc;
  }, false);
  selectionState = new SelectionState({
    anchorKey: firstBlock.get("key"),
    anchorOffset: 0,
    focusKey: lastBlock.get("key"),
    focusOffset: lastBlock.get("text").length,
    hasFocus: true,
    isBackward: false,
  });
  contentState = Modifier.mergeBlockData(contentState, selectionState, blockData);
  selectionState = new SelectionState({
    anchorKey: this.state.focusedBlocks[0],
    anchorOffset: 0,
    focusKey: this.state.focusedBlocks[0],
    focusOffset: 0,
    hasFocus: true,
    isBackward: false,
  });
  updatedEditorState = EditorState.push(updatedEditorState, contentState, "change-block-data");
  updatedEditorState = EditorState.forceSelection(updatedEditorState, selectionState);
  this.onChange(updatedEditorState);
}