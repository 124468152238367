import { getState } from "..";
import { getBottomRow } from "../../table";

export default function getIsLastRowMove(editorState, srcKeys) {
  const { contentState } = getState(editorState);
  const blocks = contentState.getBlocksAsArray();
  const srcKey = srcKeys[srcKeys.length - 1];
  const srcBlock = blocks.find((item) => item.get("key") === srcKey);

  if (srcBlock.get("type") === "Table") {
    // prevent table from splitting by moving down last row
    const bottomRow = getBottomRow(contentState, srcBlock.getIn(["data", "tableId"]));
    if (srcBlock.getIn(["data", "row"]) === bottomRow) {
      return true;
    }
  }
  return false;
}
