import { SelectionState, EditorState, Modifier, RichUtils } from "draft-js";
import Immutable from "immutable";
import { getState } from ".";
import { getIsBlockTextrule } from "../textrules";

export default function unlinkTextrule(textruleId, editorState, focusedBlocks, blockKey) {
  const { contentState, selectionState, allBlocks } = getState(editorState, true);

  // if no focused textblock, exit to avoid errors
  if ((!focusedBlocks || !focusedBlocks.length) && !blockKey) {
    return;
  }

  // detect whether given textrule is isBlockLevel based on checks to focusedBlocks
  const blockByKey = contentState.getBlockForKey(blockKey ? blockKey : focusedBlocks[0]);
  const isBlockLevel = getIsBlockTextrule(blockByKey, textruleId);
  let changedBlocks = this.state.changedBlocks;

  if (isBlockLevel) {
    // find block by that has data.textrule_id // and edit block data
    const blocksForUpdate = allBlocks.filter((item) => {
      return item.data.textrule_id && item.data.textrule_id === textruleId;
    });
    // we need to expand selectionState so multiblock textrules are fully removed
    // move this action into reusable function
    const newSelectionState = new SelectionState({
      anchorKey: blocksForUpdate[0].key, // key of block
      anchorOffset: 0,
      focusKey: blocksForUpdate[blocksForUpdate.length - 1].key,
      focusOffset: blocksForUpdate[blocksForUpdate.length - 1].text.length,
      hasFocus: true,
      isBackward: false,
    });
    const blockData = Immutable.Map({ textrule_id: 0 });
    const newContentState = Modifier.mergeBlockData(contentState, newSelectionState, blockData);
    let newEditorState = EditorState.set(editorState, { allowUndo: false });
    newEditorState = EditorState.push(newEditorState, newContentState);
    // We want to keep selection as it is after deleting table row textrules
    newEditorState = EditorState.forceSelection(newEditorState, editorState.getSelection());
    newEditorState = EditorState.set(newEditorState, { allowUndo: true });

    changedBlocks.push(...blocksForUpdate.map((e) => e.key));
    changedBlocks = [...new Set(changedBlocks)];

    this.updateEditorState({
      newEditorState,
      focusedBlocks,
      changedBlocks,
    });
    this.triggerSave();
  } else {
    // We are dealing with inline_textrule and need to update inlineStyleRanges and block.data {}
    // Find inline_textrule that matches supplied textrule.id
    const inline_textrules = blockByKey.getIn(["data", "inline_textrules"]);
    if (!inline_textrules || inline_textrules.length === 0) {
      return;
    }

    const index = inline_textrules.findIndex((item) => item.id === textruleId);
    if (index < 0) {
      return;
    }
    const entityData = inline_textrules[index];
    // forceSelection with textrule offset/length so toggleInlineStyle will remove customStyle TEXTRULE
    const newSelectionState = new SelectionState({
      anchorKey: blockByKey.key, // key of block
      anchorOffset: entityData.offset,
      focusKey: blockByKey.key,
      focusOffset: entityData.offset + entityData.length,
      hasFocus: true,
      isBackward: false,
    });
    let updatedEditorState = EditorState.set(editorState, { allowUndo: false });
    updatedEditorState = EditorState.forceSelection(updatedEditorState, newSelectionState);

    // This will tell us the whether we have TEXTRULE or TEXTRULE_ACTIVE styles applied a the beginning of our selection
    const stylesAtSelection = blockByKey.getInlineStyleAt(entityData.offset);
    if (stylesAtSelection.includes(`TEXTRULE_${textruleId}`)) {
      updatedEditorState = RichUtils.toggleInlineStyle(updatedEditorState, `TEXTRULE_${textruleId}`);
    } else if (stylesAtSelection.includes(`TEXTRULEACTIVE_${textruleId}`)) {
      updatedEditorState = RichUtils.toggleInlineStyle(updatedEditorState, `TEXTRULEACTIVE_${textruleId}`);
    }
    // Reset selection
    updatedEditorState = EditorState.forceSelection(updatedEditorState, selectionState);

    // remove the textrule from inline_textrules, convert to Map and create new ContentState with Modifier.mergeBlockData
    inline_textrules.splice(index, 1);
    const blockData = Immutable.Map({ inline_textrules });
    const updatedDataContentState = Modifier.mergeBlockData(
      updatedEditorState.getCurrentContent(),
      newSelectionState,
      blockData
    );

    changedBlocks.push(blockByKey.key);
    changedBlocks = [...new Set(changedBlocks)];

    let newEditorState = EditorState.push(updatedEditorState, updatedDataContentState, "change-block-data");
    newEditorState = EditorState.set(newEditorState, { allowUndo: true });
    this.updateEditorState({ newEditorState, focusedBlocks, changedBlocks });
    this.triggerSave();
  }
}
