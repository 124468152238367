import PropTypes from "prop-types";
import React, { Component } from "react";
import JoIcon from "../common/JoIcon";
import JoLink from "../common/JoLink";

export class DocusignRecipientsButton extends Component {
  render() {
    return (
      <div className={this.props.docusignStatus ? "sidebar__tab" : "sidebar__tab__hidden"}>
        {this.props.docusignStatus && (
          <div className="docusign_recipients_button">
            <JoLink clickHandler={() => this.props.handleOpenSideBar("recipients")} testId="recipient-library">
              <span className="docusign_recipients_button__label" ref="label">
                DocuSign
              </span>
              <span className="docusign_recipients_button__border"></span>
              <JoIcon icon="DocuSignIcon" title="docusign" spacing="none" />
            </JoLink>
          </div>
        )}
      </div>
    );
  }
}

DocusignRecipientsButton.propTypes = {
  docusignStatus: PropTypes.bool.isRequired,
  handleOpenSideBar: PropTypes.func,
};
