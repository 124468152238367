import { EditorState, Modifier } from "draft-js";
import Immutable from "immutable";
import { getState, changeBlockDataForUnlocked } from "../editor";
import { applyInlineStyles } from "../inline-styles";

export default function insertEntity(type, meta, editorState, selectionState) {
  const { contentState } = getState(editorState);
  const selectionIsCollapsed = selectionState.isCollapsed();
  // set is_modified flag to true
  const blockData = Immutable.Map({ is_modified: true });
  let updatedEditorState = changeBlockDataForUnlocked(editorState, contentState, selectionState, blockData);
  const { contentState: newContentState } = getState(updatedEditorState);

  // depending on whether selection is collapsed we will want to use different methods on Modifier
  const modifierMethod = selectionIsCollapsed ? "insertText" : "replaceText";
  if (!this.state.focusedBlocks) {
    return;
  }
  const blockByKey = newContentState.getBlockForKey(this.state.focusedBlocks[0]);
  const contentStateWithEntity = newContentState.createEntity(type, "IMMUTABLE", meta);
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  // Create a newEditorSate with changed contentState that inludes entity text (one whitespace)
  const contentStateWithEntityText = Modifier[modifierMethod](
    contentStateWithEntity,
    selectionState,
    " ",
    null,
    entityKey
  );
  updatedEditorState = EditorState.push(
    updatedEditorState,
    contentStateWithEntityText,
    "insert-characters" // One or more characters is being inserted at a selection state
  );

  // Apply inlineStyles of the previous character of inserted entity
  const entityOffset = Math.min(selectionState.anchorOffset, selectionState.focusOffset);
  const styles = blockByKey.getInlineStyleAt(entityOffset - 1);
  updatedEditorState = applyInlineStyles(updatedEditorState, styles, entityOffset, 1);

  // After applying inline styles, we should get selection back to collapsed
  selectionState = selectionState.merge({
    anchorOffset: entityOffset + 1,
    focusOffset: entityOffset + 1,
  });
  updatedEditorState = EditorState.forceSelection(updatedEditorState, selectionState);
  return updatedEditorState;
}
