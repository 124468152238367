import isInSameTableRow from "./isInSameTableRow";
import { getState } from "../editor";

//
export default function shouldTableCellSelected(block, focusKeys, editorState) {
  if (!block || !focusKeys || !editorState) {
    return false;
  }
  const { contentState } = getState(editorState);
  const key = block.getKey();
  let isInFocusRange = focusKeys.includes(key);
  if (focusKeys.length === 1) {
    const focusKey = focusKeys[0];
    const focusBlock = contentState.getBlockForKey(focusKey);
    isInFocusRange = isInSameTableRow(block, focusBlock);
  }
  return isInFocusRange;
}
