import { Modifier, SelectionState } from "draft-js";
import { detect } from "detect-browser";

// TODO functions within need to be better categorized and sorted!! //

// given a start/end (possibly from entity) will remove text within that range  // ! NOT CURRENTLY BEING USED !
export function removeTextRange(contentState, start, end, blockKey) {
  const selection = SelectionState.createEmpty(blockKey);
  // Modifier is based on selection so we need to updateSelection to start/end
  const updatedSelection = selection.merge({
    anchorOffset: start,
    focusOffset: end,
  });
  const updatedState = Modifier.removeRange(contentState, updatedSelection, "forward");

  return updatedState;
}

// returns an object of start and end of a selection range takin into consideration selection being backwards
export function getSelectionStartEnd(selectionState) {
  const selectionBackwards = selectionState.getIsBackward();
  return {
    start: selectionState.getStartOffset(),
    end: selectionBackwards ? selectionState.getAnchorOffset() : selectionState.getFocusOffset(),
  };
}
// returns boolean based on keyCode being detected
export function isAlphaNumericOrSymbol(keyCode) {
  return (
    // numbers
    (keyCode >= 48 && keyCode <= 57) ||
    // alpha
    (keyCode >= 65 && keyCode <= 90) ||
    // space and return
    keyCode === 32 ||
    keyCode === 13 ||
    // backspace clear and delete
    keyCode === 8 ||
    keyCode === 12 ||
    keyCode === 46 ||
    // t ;=,-./`
    (keyCode > 185 && keyCode < 193) ||
    // [\]'
    (keyCode > 218 && keyCode < 223) ||
    // numpad -./
    (keyCode >= 109 && keyCode <= 111) ||
    // numpad * +
    keyCode === 106 ||
    keyCode === 107
  );
}

export function getSelectionLength(selectionState) {
  if (!selectionState) {
    return;
  }
  return selectionState.getFocusOffset() - selectionState.getStartOffset();
}

export function isBlockLevel(offset, length) {
  return !offset && !length;
}

export function detectBrowser() {
  let browser = detect();
  if (!browser) {
    browser = {
      name: "unknown",
      os: "unknown",
      version: "unknown",
    };
  }
  return browser;
}

// clipboard reporting — for sending nice reports on what's being copy/cut/pasted to GA
export function makeClipboardReport(clipboard) {
  const answerCount = clipboard.answers ? clipboard.answers.length + " ANSWERS" : "0 ANSWERS";
  const rulesCount = clipboard.inlineStyles.filter((s) => {
    return s.styles[0].includes("TEXTRULE");
  }).length
    ? "at least 1 RULE"
    : "0 RULES";
  return clipboard.text + " — [Including " + answerCount + " and " + rulesCount + "]";
}
