import { getAllBlocks } from "draftjs-utils";

// A helper function that when used with ES6 destructuring gives us access to contentState, selectionState and exrras with one line of code
// allBlocks, selectionData booleans allow returning extra props if desireed without extra computation if not. Can possibly be expanded,.
export default function getState(editorState, allBlocks = false, selectionData = false) {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const extraProps = {};
  // avoid overhead of doing this if its not needed
  if (allBlocks) {
    // getAllBlocks is a helper func from draftjs-utils
    extraProps["allBlocks"] = getAllBlocks(editorState).toJS();
  }

  if (selectionData) {
    extraProps["selectionIsCollapsed"] = selectionState.isCollapsed();
    extraProps["selectionStart"] = selectionState.getStartOffset();
  }

  return {
    contentState,
    selectionState,
    ...extraProps,
  };
}
