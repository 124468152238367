import { debounce } from "lodash";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import Collapsible from "react-collapsible";
import Select from "react-select";
import { DocuSignRecipientSourceType, resetSelectStyles } from "../../utils/constants";
import DropdownIndicator from "../common/DropdownIndicator";
import JoLink from "../common/JoLink";
import "./DocuSignRecipient.scss";

class DocuSignRecipient extends Component {
  constructor(props) {
    super(props);

    // Recipient Source Options
    // here recipientSourceConstants is returned as object of objects
    const recipientSourceConstants = this.props.constantsStore.allEnvelopeTemplateRecipientsSourceType();
    var convertedReceipSourceArray = [];
    // Converting an object of objects into an array of objects.
    for (let key in recipientSourceConstants) {
      convertedReceipSourceArray.push(Object.assign(recipientSourceConstants[key], { name: key }));
    }
    const recipientSourceOptions = convertedReceipSourceArray.map((options) => {
      return {
        value: options.source_type_name,
        label: options.source_type_name,
      };
    });

    // Recipient Type Options
    // here recipientTypesContants is returned as object of objects
    const recipientTypesConstants = this.props.constantsStore.allEnvelopeTemplateRecipientsType();

    var convertedReceipArray = [];
    /* Converting an object of objects into an array of objects. */
    for (let key in recipientTypesConstants) {
      convertedReceipArray.push(Object.assign(recipientTypesConstants[key], { name: key }));
    }

    // Full name and email question options
    const variableTypes = this.props.constantsStore.allVariableTypes();

    var convertedVarTypeArray = [];
    /* Converting an object of objects into an array of objects. */
    for (let key in variableTypes) {
      convertedVarTypeArray.push(Object.assign(variableTypes[key], { name: key }));
    }

    const emailVariableType = convertedVarTypeArray.find((variable) => variable.shortname === "email");
    const fullNameVariableType = convertedVarTypeArray.find((variable) => variable.shortname === "fullname");

    /* Filtering out the variables that are of type email. */
    const emailVariablesReduced = this.props.variables.reduce((emailVariables, variable) => {
      if (variable.variable_type_id === emailVariableType.id) emailVariables.push(variable);
      return emailVariables;
    }, []);

    /* Filtering out the variables that are of type fullname. */
    const fullNameVariablesReduced = this.props.variables.reduce((fullNameVariables, variable) => {
      if (variable.variable_type_id === fullNameVariableType.id) fullNameVariables.push(variable);
      return fullNameVariables;
    }, []);

    const fullNameVariablesOptions = fullNameVariablesReduced.map((v) => ({ value: v.question_id, label: v.name }));
    const emailVariablesOptions = emailVariablesReduced.map((v) => ({ value: v.question_id, label: v.name }));

    // mapping to react-select format
    const recipientTypesOptions = convertedReceipArray.map((options) => {
      return {
        value: options.id,
        label: options.display_name,
      };
    });

    this.state = {
      recipientActive: this.props.recipient.id ? false : true,
      recipientSourceOptions,
      recipientTypesOptions,
      fullNameVariablesOptions,
      emailVariablesOptions,
      selectedRecepientSource: null,
      selectedRecepientFullName: null,
      selectedRecepientEmail: null,
      selectedRecepientType: null,
      email: "",
      isEmailValid: true,
      fullName: "",
      isFullNameValid: true,
      selectedDocusignContact: null,
      docusignContacts: [],
      isContactsValid: true,
      contactRequestFailStatusCode: 0,
      isLoadingDocusign: false,
      selectedEmailQuestionId: null,
      selectedFullNameQuestionId: null,
      signingOrderValue: null,
    };

    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.deleteRecipient = this.deleteRecipient.bind(this);
  }

  async componentDidMount() {
    this.initializeStates();
  }

  async componentDidUpdate() {
    // this.initializeStates();
  }

  initializeStates = async () => {
    // mapping to react-select format
    const signingOrderOptions = [];

    for (let i = 1; i <= this.props.signingOrderLength; i++) {
      signingOrderOptions.push({
        value: i,
        label: i,
      });
    }

    const selectedEmailQuestionId = this.state.emailVariablesOptions.find(
      (source) => source.value === this.props.recipient.question_id_email
    );
    const selectedFullNameQuestionId = this.state.fullNameVariablesOptions.find(
      (source) => source.value === this.props.recipient.question_id_fullname
    );

    const selectedRecepientSource = this.state.recipientSourceOptions.find(
      (source) => source.value === this.props.recipient.source
    );
    const selectedRecepientType = this.state.recipientTypesOptions.find(
      (type) => type.value === this.props.recipient.envelope_template_recipient_type_id
    );
    const signingOrderValue = signingOrderOptions.find((order) => order.value === this.props.recipient.routing_order);

    let docusignContacts = [];
    let selectedDocusignContact = null;

    this.setState({
      // selectedDocusignContact,
      // docusignContacts,

      fullName: this.props.recipient.full_name,
      email: this.props.recipient.email,
      selectedRecepientSource,
      selectedRecepientType,
      signingOrderValue,

      selectedEmailQuestionId,
      selectedFullNameQuestionId,
    });

    if (selectedRecepientSource.value === DocuSignRecipientSourceType.Docusign) {
      docusignContacts = await this.loadDocusignContacts();

      selectedDocusignContact = docusignContacts.find(
        (contact) => contact.label === this.props.recipient.full_name + " | " + this.props.recipient.email
      );
      // Set the docusign contact options and selected docusign contact
      this.setState({
        docusignContacts,
        isLoadingDocusign: false,
        selectedDocusignContact,
      });
    }
  };

  handleRecipientChangeSelection = (selectedRecepientSource, action) => {
    const previousSource = this.state.selectedRecepientSource;
    /* Checking if the previous source is the same as the selected source. If it is. dont do anything */
    if (previousSource && previousSource.value === selectedRecepientSource.value) return;

    this.setState({
      selectedRecepientSource,
      // reset state
      email: "",
      fullName: "",
      selectedDocusignContact: null,
      selectedEmailQuestionId: null,
      selectedFullNameQuestionId: null,
      isEmailValid: true,
      isFullNameValid: true,
      isContactsValid: true,
      contactRequestFailStatusCode: 0,
    });

    this.setState({
      selectedRecepientSource,
    });

    if (selectedRecepientSource.value === DocuSignRecipientSourceType.Docusign) {
      this.loadDocusignContacts();
    }
  };

  loadDocusignContacts = async () => {
    this.setState({
      isLoadingDocusign: true,
    });

    const docusignContactResults = await this.props.recipientsStore.docusignContacts;

    // handle when the docusign fetch fails
    if (docusignContactResults.response) {
      this.setState({
        contactRequestFailStatusCode: docusignContactResults.response.status,
        isLoadingDocusign: false,
      });
      return;
    }

    // handle When the number of docusign contacts are 0
    if (docusignContactResults.length < 1) {
      this.setState({
        isContactsValid: false,
        isLoadingDocusign: false,
      });
      return;
    }

    // mapping docusign results to react-select options
    // reduce the docusign contacts to only include the ones that have a full name and email
    const docusignContacts = docusignContactResults.reduce((acc, contact) => {
      if (contact.emails && contact.name) {
        acc.push({
          value: contact,
          label: contact.name + " | " + contact.emails[0],
        });
      }
      return acc;
    }, []);

    this.setState({
      docusignContacts,
      isLoadingDocusign: false,
    });

    return docusignContacts;
  };

  handleSelectionChange(selection, action) {
    this.setState(
      {
        [action.name]: selection,
      },
      () => {
        if (this.state.selectedRecepientSource.value === DocuSignRecipientSourceType.Bot) {
          this.postBotAnswerRecipient();
        } else if (this.state.selectedRecepientSource.value === DocuSignRecipientSourceType.Manual) {
          this.postManuallyInsertedRecipient();
        } else if (this.state.selectedRecepientSource.value === DocuSignRecipientSourceType.Docusign) {
          this.postDocusignContactRecipients();
        }
      }
    );
  }

  handleSelectionChangeBotInteraction(selection, action) {
    this.setState(
      {
        [action.name]: selection,
      },
      () => {
        this.postBotAnswerRecipient();
      }
    );
  }

  /**
   * Validate if all required states for Docusign Contact Recipients
   * @returns true if valid
   */
  isDocusignContactInputValid() {
    if (
      this.state.selectedRecepientSource.value === DocuSignRecipientSourceType.Docusign &&
      this.state.selectedDocusignContact
    )
      return true;

    return false;
  }

  /**
   * Validate if all required states for Manually Inserted Recipients
   * @returns true if valid
   */
  isManualInsertValid() {
    if (
      this.state.selectedRecepientSource.value === DocuSignRecipientSourceType.Manual &&
      this.state.fullName &&
      this.state.email &&
      this.state.isEmailValid &&
      this.state.isFullNameValid
    ) {
      return true;
    }
    return false;
  }

  /**
   * Validate if all required states for Collected from Bot Interaction recipient
   * @returns true if valid
   */
  isBotInteractionInputsValid() {
    if (
      this.state.selectedRecepientSource.value === DocuSignRecipientSourceType.Bot &&
      this.state.selectedFullNameQuestionId &&
      this.state.selectedEmailQuestionId
    ) {
      return true;
    }
    return false;
  }

  createNewRecipient = () => {
    this.props.recipientsStore.create(
      {
        source: this.state.selectedRecepientSource.value,
        full_name: this.state.fullName,
        email: this.state.email,
        question_id_email: this.state.selectedEmailQuestionId?.value,
        question_id_fullname: this.state.selectedFullNameQuestionId?.value,
        envelope_template_recipient_type_id: this.state.selectedRecepientType?.value,
        routing_order: this.state.signingOrderValue?.value,
        envelope_template_id: this.props.recipientsStore.envelopeTempId,
      },
      this.props.index,
      this.props.keyTracker
    );
  };

  createNewDocusignRecipient = () => {
    this.props.recipientsStore.create(
      {
        source: this.state.selectedRecepientSource.value,
        email: this.state.selectedDocusignContact.value.emails[0],
        full_name: this.state.selectedDocusignContact.value.name,
        question_id_email: this.state.selectedEmailQuestionId?.value,
        question_id_fullname: this.state.selectedFullNameQuestionId?.value,
        envelope_template_recipient_type_id: this.state.selectedRecepientType?.value,
        routing_order: this.state.signingOrderValue?.value,
        envelope_template_id: this.props.recipientsStore.envelopeTempId,
      },
      this.props.index,
      this.props.keyTracker
    );
  };

  postBotAnswerRecipient = () => {
    if (this.isBotInteractionInputsValid()) {
      if (this.props.recipient.id) {
        this.props.recipientsStore
          .updateOne(
            {
              id: this.props.recipient.id,
              source: this.state.selectedRecepientSource.value,
              question_id_email: this.state.selectedEmailQuestionId.value,
              question_id_fullname: this.state.selectedFullNameQuestionId.value,
              envelope_template_recipient_type_id: this.state.selectedRecepientType.value,
              routing_order: this.state.signingOrderValue?.value,
            },
            this.props.keyTracker
          )
          .then((res) => {});
      } else {
        this.createNewRecipient();
      }
    }
  };

  // debounced Manually Insert Recipient
  postManuallyInsertedRecipient = debounce(() => {
    if (this.isManualInsertValid()) {
      if (this.props.recipient.id) {
        this.props.recipientsStore.updateOne(
          {
            id: this.props.recipient.id,
            source: this.state.selectedRecepientSource.value,
            full_name: this.state.fullName,
            email: this.state.email,
            envelope_template_recipient_type_id: this.state.selectedRecepientType.value,
            routing_order: this.state.signingOrderValue?.value,
          },
          this.props.keyTracker
        );
      } else {
        this.createNewRecipient();
      }
    }
  }, 300);

  postDocusignContactRecipients = () => {
    if (this.isDocusignContactInputValid())
      if (this.props.recipient.id) {
        this.props.recipientsStore.updateOne(
          {
            id: this.props.recipient.id,
            email: this.state.selectedDocusignContact.value.emails[0],
            full_name: this.state.selectedDocusignContact.value.name,
            envelope_template_recipient_type_id: this.state.selectedRecepientType.value,
            source: this.state.selectedRecepientSource.value,
            routing_order: this.state.signingOrderValue?.value,
          },
          this.props.keyTracker
        );
      } else {
        this.createNewDocusignRecipient();
      }
  };

  handleDocusignContactSelection(selection) {
    this.setState(
      {
        selectedDocusignContact: selection,
      },
      () => {
        this.postDocusignContactRecipients();
      }
    );
  }

  /**
   * This function checks if the email is valid
   * @param email - The email that the user has entered.
   * @returns The function isEmailValid is returning a boolean value.
   */
  isEmailValid(email) {
    const re =
      /* The above code is a regular expression that checks if the email is valid. */
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(email)) return true;

    return false;
  }

  /**
   * Checks if the full name is valid by checking if it matches the regular expression
   * @param fullName - The full name of the user.
   * @returns true if valid
   */
  isFullNameValid(fullName) {
    const re = /^[a-zA-Z]([-']?[a-zA-Z]+)*( [a-zA-Z]([-']?[a-zA-Z]+)*)+$/;

    if (re.test(fullName)) return true;

    return false;
  }

  handleRecipientEmailChangeInput = (event) => {
    const email = event.target.value;
    this.setState({ email }, () => {
      if (this.isEmailValid(email)) {
        this.setState({ isEmailValid: true }, () => this.postManuallyInsertedRecipient());
      } else {
        this.setState({ isEmailValid: false });
      }
    });
  };

  handleFullNameChange = (event) => {
    const fullName = event.target.value;

    this.setState({ fullName }, () => {
      if (this.isFullNameValid(fullName)) {
        this.setState({ isFullNameValid: true }, () => this.postManuallyInsertedRecipient());
      } else {
        this.setState({ isFullNameValid: false });
      }
    });
  };

  deleteRecipient = () => {
    if (this.props.recipient.id) {
      this.props.recipientsStore.delete(this.props.recipient.id);
    } else {
      // delete recipients not stored in DB
      this.props.recipientsStore.deleteLocal(this.props.index);
    }
  };

  /* A function that returns a div element based on the status of docusign contacts fetch */
  determineContactsPlaceholder = () => {
    if (this.state.isLoadingDocusign) {
      return (
        <div className="docusign-contact-loader-wrapper">
          <div className="docusign-contact-loader"></div>
          &nbsp;&nbsp;Loading DocuSign contacts&hellip;
        </div>
      );
    }
    if (this.state.contactRequestFailStatusCode !== 0) {
      return <div>No contacts found</div>;
    }
    if (!this.state.isContactsValid) {
      return <div>No contacts available</div>;
    }
    return <div>Search contact</div>;
  };

  render() {
    const { isSelected, clickHandler, keyTracker } = this.props;

    const signingOrderOptions = [];

    for (let i = 1; i <= this.props.recipientsStore.recipientsLength; i++) {
      signingOrderOptions.push({
        value: i,
        label: i,
      });
    }

    /* Destructuring the state object. */
    const {
      selectedRecepientSource,
      recipientSourceOptions,
      recipientTypesOptions,
      selectedRecepientType,
      signingOrderValue,
      fullName,
      email,
      selectedDocusignContact,
      docusignContacts,
      isLoadingDocusign,
      fullNameVariablesOptions,
      emailVariablesOptions,
      selectedEmailQuestionId,
      selectedFullNameQuestionId,
      isEmailValid,
      isFullNameValid,
      isContactsValid,
      contactRequestFailStatusCode,
    } = this.state;

    const docuSignContactSelectPlaceHolder = this.determineContactsPlaceholder();

    const emailQuestionSelectPlaceholder = emailVariablesOptions.length ? (
      <div>Select from messages</div>
    ) : (
      <div>No email variables available</div>
    );
    const fullNameQuestionSelectPlaceholder = fullNameVariablesOptions.length ? (
      <div>Select from messages</div>
    ) : (
      <div>No full name variables available</div>
    );

    const collapsibleLabel =
      "Recipient " +
      (this.props.index + 1) +
      (this.props.hasSigningOrder ? " | Sign Order " + signingOrderValue?.value : "");

    const collapsibleElement = (
      <span className="Collapsible__trigger-element">
        {collapsibleLabel}{" "}
        <JoLink
          iconLeft="Bin"
          title="Delete Recipient"
          testId="delete-recipient"
          clickHandler={() => {
            this.deleteRecipient();
          }}
        ></JoLink>
      </span>
    );

    const triggerClickProp = isSelected ? null : keyTracker;

    return (
      <Collapsible
        open={isSelected}
        transitionTime={200}
        easing="ease-out"
        overflowWhenOpen="visible"
        trigger={collapsibleElement}
        handleTriggerClick={() => clickHandler(triggerClickProp)}
      >
        <>
          <div className="input-field">
            <div className="input-field__label-wrapper">
              <label className="input-field__label" htmlFor="textrule-message">
                Recipient source
              </label>
            </div>
            <Select
              value={selectedRecepientSource}
              name="selectedRecepientSource"
              styles={resetSelectStyles}
              components={{ DropdownIndicator }}
              className="react-select-container"
              classNamePrefix="react-select"
              onChange={this.handleRecipientChangeSelection}
              options={recipientSourceOptions}
            />
          </div>
          <div className="input-field">
            <div className="input-field__label-wrapper">
              <label className="input-field__label" htmlFor="textrule-message">
                Recipient type
              </label>
            </div>
            <Select
              value={selectedRecepientType}
              styles={resetSelectStyles}
              components={{ DropdownIndicator }}
              name="selectedRecepientType"
              inputId="envelope_template_recipient_type_id"
              className="react-select-container"
              classNamePrefix="react-select"
              onChange={(value, action) => this.handleSelectionChange(value, action)}
              options={recipientTypesOptions}
            />
          </div>
          {selectedRecepientSource?.label !== DocuSignRecipientSourceType.Docusign ? (
            <>
              <div className="input-field">
                <div className="input-field__label-wrapper">
                  <label className="input-field__label" htmlFor="textrule-message">
                    Full name
                  </label>
                </div>
                {selectedRecepientSource?.label !== DocuSignRecipientSourceType.Manual ? (
                  <>
                    <Select
                      value={selectedFullNameQuestionId}
                      name="selectedFullNameQuestionId"
                      styles={resetSelectStyles}
                      components={{ DropdownIndicator }}
                      className="react-select-container docusign-recipient-answer__dropdown"
                      classNamePrefix="react-select"
                      onChange={(value, action) => this.handleSelectionChangeBotInteraction(value, action)}
                      options={fullNameVariablesOptions}
                      placeholder={fullNameQuestionSelectPlaceholder}
                    />
                    {!fullNameVariablesOptions.length ? (
                      <span className="input-field__error">You must enter a valid full name</span>
                    ) : (
                      !selectedFullNameQuestionId && <span className="input-field__error">Full name is required</span>
                    )}
                  </>
                ) : (
                  <>
                    <input
                      type="text"
                      className="docusign-recipient-answer__input"
                      value={fullName}
                      name="fullName"
                      id="full_name"
                      onChange={this.handleFullNameChange}
                      placeholder="Enter a full name"
                    />
                    {!fullName ? (
                      <span className="input-field__error">Full name is required</span>
                    ) : (
                      !isFullNameValid && <span className="input-field__error">You must enter a valid full name</span>
                    )}
                  </>
                )}
              </div>

              <div className="input-field">
                <div className="input-field__label-wrapper">
                  <label className="input-field__label" htmlFor="textrule-message">
                    Email
                  </label>
                </div>
                {selectedRecepientSource?.label !== DocuSignRecipientSourceType.Manual ? (
                  <>
                    <Select
                      value={selectedEmailQuestionId}
                      name="selectedEmailQuestionId"
                      styles={resetSelectStyles}
                      components={{ DropdownIndicator }}
                      className="react-select-container docusign-recipient-answer__dropdown"
                      classNamePrefix="react-select"
                      // onChange={this.handleRecipientEmailChange}
                      onChange={(value, action) => this.handleSelectionChangeBotInteraction(value, action)}
                      placeholder={emailQuestionSelectPlaceholder}
                      options={emailVariablesOptions}
                    />
                    {!emailVariablesOptions.length ? (
                      <span className="input-field__error">You must enter a valid email</span>
                    ) : (
                      !selectedEmailQuestionId && <span className="input-field__error">Email is required</span>
                    )}
                  </>
                ) : (
                  <>
                    <input
                      type="text"
                      id="docusign-recipient-email-response"
                      className="docusign-recipient-answer__input"
                      onChange={(event) => this.handleRecipientEmailChangeInput(event)}
                      value={email}
                      placeholder="Enter an email address"
                    />
                    {!email ? (
                      <span className="input-field__error">Email is required</span>
                    ) : (
                      !isEmailValid && <span className="input-field__error">You must enter a valid email</span>
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            <div className="input-field">
              <div className="input-field__label-wrapper">
                <label className="input-field__label" htmlFor="textrule-message">
                  Contact
                </label>
              </div>
              <Select
                value={selectedDocusignContact}
                styles={resetSelectStyles}
                components={{ DropdownIndicator }}
                name="selectedDocusignContact"
                inputId="envelope_template_recipient_type_id"
                className="react-select-container docusign-recipient-answer__dropdown"
                classNamePrefix="react-select"
                onChange={(value) => this.handleDocusignContactSelection(value)}
                options={docusignContacts}
                isDisabled={isLoadingDocusign}
                placeholder={docuSignContactSelectPlaceHolder}
              />
              {!isContactsValid ? (
                <span className="input-field__error">You must enter a valid contact</span>
              ) : !selectedDocusignContact ? (
                <span className="input-field__error">Contact is required</span>
              ) : (
                contactRequestFailStatusCode !== 0 && <span className="input-field__error">Couldn't load contacts</span>
              )}
            </div>
          )}

          {this.props.hasSigningOrder && (
            <div className="input-field">
              <div className="input-field__label-wrapper">
                <label className="input-field__label" htmlFor="textrule-message">
                  Signing order
                </label>
              </div>
              <Select
                value={signingOrderValue}
                styles={resetSelectStyles}
                components={{ DropdownIndicator }}
                className="react-select-container"
                classNamePrefix="react-select"
                name="signingOrderValue"
                onChange={(value, action) => this.handleSelectionChange(value, action)}
                options={signingOrderOptions}
              />
            </div>
          )}
        </>
      </Collapsible>
    );
  }
}

export default inject("recipientsStore", "constantsStore")(observer(DocuSignRecipient));
