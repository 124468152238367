import React from "react";
import PropTypes from "prop-types";
import { EntityTypes } from "../../../utils/constants";

/*  Answer Entity strategies and components */

// find answer entities in a content block
export function findAnswerEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === EntityTypes.ANSWER;
  }, callback);
}

export const AnswerComponent = (props) => {
  const handleClick = () => {
    props.focusAnswer(props.blockKey, props.start);
  };
  const entityData = props.contentState.getEntity(props.entityKey).get("data");

  // To preserve styles on ansers we need to detect the styles on the first child and apply them to the parent
  const styleSet = props.children[0].props.styleSet.toJS();
  const styleObject = createStylesFromStyleSet(styleSet);

  return (
    <span
      title={entityData.text}
      className="answer"
      contentEditable={true}
      readOnly
      onClick={handleClick}
      suppressContentEditableWarning
      style={styleObject}
    >
      {props.children}
    </span>
  );
};

AnswerComponent.propTypes = {
  children: PropTypes.node,
  blockKey: PropTypes.string,
  start: PropTypes.number,
  entityKey: PropTypes.string,
  focusAnswer: PropTypes.func,
  contentState: PropTypes.shape({
    getEntity: PropTypes.func,
  }),
};

function createStylesFromStyleSet(styleSet) {
  let styleObject = {};

  styleSet.forEach((styleKeyword) => {
    switch (styleKeyword) {
      case "BOLD":
        styleObject.fontWeight = "bold";
        break;
      case "ITALIC":
        styleObject.fontStyle = "italic";
        break;
      case "UNDERLINE":
        styleObject.textDecoration = "underline";
        break;
      default:
        break;
    }
  });

  return styleObject;
}
