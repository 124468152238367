import { Modifier, EditorState, SelectionState } from "draft-js";
import Immutable from "immutable";
import { getState, changeBlockDataForUnlocked, changeBlockNumbering } from "../editor";
import { isListItem } from "../../../../utils/helpers";
import { gaEvent } from "../../../../utils/analytics";
import getBlockForParaId from "../editor/getBlockForParaId";

export default function blockStylePaste(focusedBlocks) {
  let editorState = this.state.editorState;
  if (!focusedBlocks) {
    return true;
  }
  let { contentState } = getState(editorState);
  const clipboard = this.state.clipboard;
  let srcBlk = contentState.getBlockForKey(clipboard.key);

  let changedCount = 0;

  const numberingRestartInfo = procNumberingRestart(editorState, srcBlk, focusedBlocks);
  /*
    If the source block is list item and
    isRestartNumbering is true and
    this source block isn't restarting list block by
    a target block which newly copied list info,
    then we remove isRestartNumbering of the source block to false
   */
  if (numberingRestartInfo) {
    contentState = numberingRestartInfo.editorState.getCurrentContent();
    srcBlk = numberingRestartInfo.srcBlk;
    changedCount++;
  }

  let changedBlocks = this.state.changedBlocks;

  focusedBlocks.forEach((key) => {
    // We should skip the same key as the source block
    console.log(`paste, destKey=${key}, srcKey=${clipboard.key}`);
    if (key === clipboard.key) {
      return;
    }
    let listItem = srcBlk.getIn(["data", "listItem"]);
    const dstIndex = contentState.getBlocksAsArray().findIndex((e) => e.getKey() === key);
    if (numberingRestartInfo && numberingRestartInfo.restartIndex === dstIndex) {
      /*
        If the source block is list item and
        isRestartNumbering is true and
        this source block isn't restarting list block by
        a target block which newly copied list info,
        then we set isRestartNumbering of the target block to true
      */
      const tmpBlk = srcBlk.setIn(["data", "listItem"], { ...listItem, isRestartNumbering: true });
      listItem = tmpBlk.getIn(["data", "listItem"]);
    } else {
      const tmpBlk = srcBlk.setIn(["data", "listItem"], { ...listItem, isRestartNumbering: false });
      listItem = tmpBlk.getIn(["data", "listItem"]);
    }
    let blockData = Immutable.Map({
      is_styles_modified: true,
      font_size: srcBlk.getIn(["data", "font_size"]),
      font_family: srcBlk.getIn(["data", "font_family"]),
      caps: srcBlk.getIn(["data", "caps"]),
      block_classes: srcBlk.getIn(["data", "block_classes"]),
      listItem: listItem,
    });

    const targetBlk = contentState.getBlockForKey(key);
    // We set style_source_id of target block only if root style source block is not target block
    // Fist Get root style source block
    let rootStyleSrcBlk = srcBlk;
    let style_source_id = rootStyleSrcBlk.getIn(["data", "style_source_id"]);
    while (style_source_id) {
      rootStyleSrcBlk = getBlockForParaId(contentState, style_source_id);
      style_source_id = rootStyleSrcBlk.getIn(["data", "style_source_id"]);
      if (style_source_id === targetBlk.getIn(["data", "id"])) {
        break;
      }
    }
    if (rootStyleSrcBlk.getIn(["data", "id"]) !== targetBlk.getIn(["data", "id"])) {
      blockData = blockData.set("style_source_id", rootStyleSrcBlk.getIn(["data", "id"]));
    }
    const newSelectionState = new SelectionState({
      anchorKey: key,
      focusKey: key,
    });
    editorState = changeBlockDataForUnlocked(
      editorState,
      contentState,
      newSelectionState,
      blockData,
      false /* enable undo */
    );
    contentState = editorState.getCurrentContent();
    if (isListItem(srcBlk)) {
      const blockType = srcBlk.get("type");
      const depth = srcBlk.getDepth();
      editorState = changeBlockNumbering(editorState, contentState, key, blockType, depth);
      contentState = editorState.getCurrentContent();
    }

    // copy the style of the first character of the source block
    const charList = srcBlk.getCharacterList();
    if (charList.size > 0) {
      const srcStyles = charList.get(0).getStyle();
      srcStyles.forEach((style) => {
        if (style.includes("TEXTRULE")) {
          return;
        }
        // The start and end value may not match actual inline_textrules as they are effected by BOLD, ITALIC etc
        const styleSelection = new SelectionState({
          anchorKey: key,
          anchorOffset: 0,
          focusKey: key,
          focusOffset: targetBlk.getLength(),
        });

        contentState = Modifier.applyInlineStyle(contentState, styleSelection, style);
      });
    }

    changedBlocks.push(key);

    changedCount++;
  });
  if (changedCount > 0) {
    editorState = EditorState.push(editorState, contentState, "insert-characters");
    changedBlocks = [...new Set(changedBlocks)];
    this.updateEditorState({ newEditorState: editorState, focusedBlocks, changedBlocks });
    this.triggerSave();
  }

  // We initialize the clipboard after pasting style
  clipboard.key = null;
  this.setState({ clipboard: clipboard });
  document.getElementById("container-root").classList.remove("painter-selected");

  // Send a request to Google Analystics to register this event
  gaEvent({
    category: "Clipboard",
    action: "Paste",
    label: makeClipboardReport(clipboard, focusedBlocks),
  });
}

function makeClipboardReport(clipboard, focusedBlocks) {
  const dst = focusedBlocks.reduce((acc, next) => {
    return acc + ", " + next;
  }, "");
  return "Source: " + clipboard.key + ", Destination: " + dst;
}

/*
  Find which block is the restarting list block
  between a style-source block and target blocks
 */
function getNumberingRestartBlockIndex(contentState, srcIndex, focusedBlocks) {
  const blkArray = contentState.getBlocksAsArray();
  let restartIndex = srcIndex;
  focusedBlocks.forEach((dstKey) => {
    const dstIndex = blkArray.findIndex((e) => e.getKey() === dstKey);
    // if a target block positions before the source one, it can be the restart numbering block
    if (dstIndex < restartIndex) {
      restartIndex = dstIndex;
    }
  });
  return restartIndex;
}

function procNumberingRestart(editorState, srcBlk, focusedBlocks) {
  if (isListItem(srcBlk)) {
    const contentState = editorState.getCurrentContent();
    const blkArray = contentState.getBlocksAsArray();
    const srcKey = srcBlk.getKey();
    const srcIndex = blkArray.findIndex((e) => e.getKey() === srcKey);
    const listItem = srcBlk.getIn(["data", "listItem"]);
    if (listItem.isRestartNumbering) {
      const restartIndex = getNumberingRestartBlockIndex(contentState, srcIndex, focusedBlocks);
      /*
        If the source block is list item and
        isRestartNumbering is true and
        this source block isn't restarting list block by
        a target block which newly copied list info,
        then we remove isRestartNumbering of the source block to false
      */
      if (srcIndex !== restartIndex) {
        let listItem = srcBlk.getIn(["data", "listItem"]);
        srcBlk = srcBlk.setIn(["data", "listItem"], { ...listItem, isRestartNumbering: false });
        listItem = srcBlk.getIn(["data", "listItem"]);
        let blockData = Immutable.Map({
          listItem: listItem,
        });
        const newSelectionState = new SelectionState({
          anchorKey: srcKey,
          focusKey: srcKey,
        });
        editorState = changeBlockDataForUnlocked(
          editorState,
          contentState,
          newSelectionState,
          blockData,
          false /* enable undo */
        );
        return {
          editorState,
          srcBlk,
          restartIndex,
        };
      }
    }
  }
  return null;
}
