import apiService, { authService } from "./httpService.js";

let api = {};

function generateApiFrom(name) {
  return {
    get: () => apiService.get(`${name}`).then((res) => res.data.objects),
    getOne: ({ id }) => apiService.get(`${name}/${id}`).then((res) => res.data),
    update: (obj) => apiService.put(`${name}/${obj.id}`, obj).then((res) => res.data),
    create: (obj) => apiService.post(`${name}`, obj).then((res) => res.data),
    remove: (obj) => apiService.delete(`${name}/${obj.id}`).then((res) => res), // removed .data because there was none
  };
}

["rule", "rule_group", "textblock", "textrule", "document", "envelope_template"].forEach((name) => {
  api[name + "s"] = generateApiFrom(name); // this makes answer_in_documents which is kinda confusing
});

api["envelope_template_recipient"] = {
  create: (obj) => apiService.post(`envelope_template_recipient`, obj).then((res) => res.data),
  update: (obj) => apiService.put(`envelope_template_recipient/${obj.id}`, obj).then((res) => res.data),
  remove: (id) => apiService.delete(`envelope_template_recipient/${id}`).then((res) => res.status),
  getFromDocusign: (documentId) =>
    apiService.get(`envelope_template_recipient/docusign_contacts/${documentId}`).then((res) => res.data.contacts),
};

api["envelope_template"] = {
  update: (id, obj) => apiService.put(`envelope_template/${id}`, obj).then((res) => res.data),
  getTabs: (id, obj) => apiService.put(`envelope_template/${id}/tabs`, obj).then((res) => res.data),
};

api["envelope_template_tab"] = {
  create: (obj) => apiService.post(`envelope_template_tab`, obj).then((res) => res.data),
  update: (obj) => apiService.put(`envelope_template_tab/${obj.id}`, obj).then((res) => res.data),
  remove: (id) => apiService.delete(`envelope_template_tab/${id}`).then((res) => res.status),
};

// creating API to get all recipients for a envelope template TODO: refactor to above
api.envelope_templates.getRecipients = ({ id }) => {
  return apiService.get(`envelope_template/${id}/recipients`).then((res) => res.data);
};

api["constants"] = { get: () => apiService.get(`constants`).then((res) => res.data) };
// override the documents getOne for the multi-region design
api.documents.getOne = async ({ id }) => {
  const document = await apiService.get(`document/${id}`);
  const account = await authService.get("dashboard");
  const response = {
    ...document.data,
    ...account.data,
  };
  return response;
};

api.documents.getEnvelopeTemplate = async ({ id }) => {
  const envelopeTemplate = await apiService.get(`document/${id}/envelope_template`);
  const response = {
    envelopeTemplate,
  };
  return response;
};

console.log(api);

export default api;
