import React from "react";

const SvgLayoutModule1 = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <rect y={-0.003} width={11} height={11} rx={1.5} ry={1.5} />
    <rect x={13} y={-0.003} width={11} height={11} rx={1.5} ry={1.5} />
    <rect y={12.997} width={11} height={11} rx={1.5} ry={1.5} />
    <rect x={13} y={12.997} width={11} height={11} rx={1.5} ry={1.5} />
  </svg>
);

export default SvgLayoutModule1;
