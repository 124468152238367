import getState from "../editor/getState";
import { insertEntity } from "../entities";
import { EntityTypes } from "../../../../utils/constants";

// In place of draft-js default behaviour of moving focus this function
// returns a new editorState with added new TabEntity (clearing any content if selection is range)
export default function handleTab() {
  const editorState = this.state.editorState;
  const { selectionState } = getState(editorState);
  const meta = {};
  const updatedEditorState = insertEntity.call(this, EntityTypes.TAB, meta, editorState, selectionState);
  updatedEditorState && this.onChange(updatedEditorState);
}
