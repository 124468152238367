import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import "./JoSelectableCards.scss";

// Reference: https://codepen.io/sheefu/pen/mddGQqb?editors=0110

class JoSelectableCards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: this.props.value || [],
    };

    this.onCardSelect = this.onCardSelect.bind(this);
  }

  //Update style after selection
  onCardSelect(index) {
    var selectedIndexes;
    var selectedIndex; // Whether the new index was already in the selected[]

    this.setState(
      (prevState, props) => {
        selectedIndexes = prevState.selected;
        selectedIndex = selectedIndexes.indexOf(index);
        if (props.multiple) {
          if (selectedIndex > -1) {
            selectedIndexes.splice(selectedIndex, 1);
          } else {
            selectedIndexes.push(index);
          }
          return { selected: selectedIndexes };
        } else {
          return {
            selected: [index],
          };
        }
      },
      () => {
        // this needs to be in the then of setState so the update selected value is sent
        if (this.props.multiple || (selectedIndex === -1 && !this.props.multiple)) {
          this.props.selectHandler(this.state.selected);
        }
      }
    );
  }

  render() {
    const { options } = this.props;

    const content = options.map((option, i) => {
      const selected = this.state.selected.indexOf(i) > -1;
      return (
        <Fragment key={i}>
          <SelectableCard option={option} selected={selected} onClick={(e) => this.onCardSelect(i)} />
        </Fragment>
      );
    });

    return <div className="cardlist"> {content} </div>;
  }
}

class SelectableCard extends React.Component {
  render() {
    const { option, selected } = this.props;
    const isSelected = selected ? "selected" : "";
    return (
      <div
        className={"card " + isSelected}
        onClick={this.props.onClick}
        data-test-id={this.props.testId + "-" + this.props.key}
      >
        <div className={"radio " + isSelected} />
        <span> {option} </span>
      </div>
    );
  }
}

JoSelectableCards.propTypes = {
  options: PropTypes.array,
  multiple: PropTypes.bool,
  selectHandler: PropTypes.func,
  testId: PropTypes.string,
  value: PropTypes.array,
};

export default JoSelectableCards;
