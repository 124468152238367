import React from "react";

const SvgNavigationMenuHorizontal = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <circle cx={3.25} cy={12} r={3.25} />
    <circle cx={12} cy={12} r={3.25} />
    <circle cx={20.75} cy={12} r={3.25} />
  </svg>
);

export default SvgNavigationMenuHorizontal;
