// Extract _[id] and apply it do desired customStyle so we can turn TEXTRULEACTIVE_666 into TEXTRULE_666
export default function convertTextruleStyleName(style) {
  const regex = /^TEXTRULEACTIVE_/;
  const id = style.split("_").pop();

  if (regex.test(style)) {
    return `TEXTRULE_${id}`;
  } else {
    return `TEXTRULEACTIVE_${id}`;
  }
}
