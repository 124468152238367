import React from "react";

const SvgTextUnderline = () => (
	<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="28px" viewBox="0 0 463 463" className="caps">
		<path d="M463,158.001H190.417l-21.13-45.587c-2.456-5.301-7.767-8.692-13.608-8.692h-5.263c-5.868,0-11.196,3.421-13.639,8.756
			l-20.841,45.523H0v5h113.646L34.662,335.533c-2.126,4.644-1.743,10.05,1.017,14.349c1.143,1.779,2.632,3.264,4.343,4.396H0v5h463
			v-5h-40.016c0.84-0.558,1.63-1.197,2.352-1.924c2.815-2.832,4.386-6.67,4.362-10.663l-0.64-110.12
			c-0.376-39.413-24.399-64.987-63.387-68.568H463V158.001z M150.559,163.001h5.265l42.272,91.349h-88.984L150.559,163.001z
			M66.533,347.994l27.354-60.066H213.53l27.661,60.119c1.19,2.588,3.064,4.719,5.348,6.229H61.163
			C63.46,352.756,65.344,350.604,66.533,347.994z M396.117,341.904c0.044,5.172,2.702,9.708,6.711,12.372h-39.629
			c12.265-2.679,23.328-7.479,32.9-14.256L396.117,341.904z M395.779,288.203c-10.518,24.176-27.968,34.995-56.235,34.995
			c-16.729,0-34.604-7.394-34.604-28.142c0-19.983,26.339-22.97,42.038-22.97h48.789L395.779,288.203z M283.645,209.935l2.15,2.867
			c2.409,3.213,6.006,5.325,9.985,5.865c3.979,0.535,8.009-0.54,11.188-2.995c17.49-13.516,32.621-19.543,49.062-19.543
			c34.971,0,39.277,20.318,39.454,35.644v6.739h-48.506c-55.753,0-75.616,29.21-75.616,56.547c0,29.242,19.087,51.918,47.541,59.219
			h-51.556c1.722-1.14,3.218-2.633,4.361-4.426c2.756-4.313,3.121-9.736,0.968-14.382l-79.942-172.468h153.828
			c-20.627,1.946-39.965,10.371-60.142,26.105C279.977,194.134,278.741,203.395,283.645,209.935z"/>
	</svg>
);

export default SvgTextUnderline;
