import { format, utcToZonedTime } from "date-fns-tz";

export const isInt = (value) => {
  if (typeof value !== "number") {
    return false;
  }
  return value % 1 === 0;
};

export const formatNumber = (num) => {
  let parsedNumber = parseFloat(num);

  if (isNaN(parsedNumber)) {
    return num;
  }

  if (isInt(parsedNumber)) {
    return `${parsedNumber}`;
  }

  parsedNumber = parsedNumber.toFixed(2);

  return isInt(parseFloat(parsedNumber)) ? `${parseInt(parsedNumber)}` : parsedNumber.replace(/0+$/, ""); // Replace trailing 0s on decimals
};

export function getCurrency(currencies, currency_id) {
  let currency = Object.values(currencies)[0];
  if (currency_id) {
    currency = currencies[currency_id];
  }
  return currency;
}

export function currencyFormater(currencies, currency_id) {
  const currency = getCurrency(currencies, currency_id);
  return new Intl.NumberFormat(currency.locale, {
    style: "currency",
    currency: currency.code,
  });
}

export function formatCurrency(value, currencies, currency_id) {
  return currencyFormater(currencies, currency_id).format(value);
}

export function getDateFormat(date_formats, date_format_id) {
  let dateFormat = Object.values(date_formats)[0];
  if (date_format_id) {
    dateFormat = date_formats[date_format_id];
  }
  return dateFormat;
}

export function formatDate(value, date_formats, date_format_id, timezone) {
  try {
    const dateFormat = getDateFormat(date_formats, date_format_id);
    return format(utcToZonedTime(new Date(value), timezone), dateFormat.format, {
      timeZone: timezone,
    });
  } catch (error) {
    console.error(error);
    return value;
  }
}

export function formatPercentage(value) {
  return `${formatNumber(value)}%`;
}

// TODO: the function name is wrong, it's not Midday in UTC
// Instead, it's Midday in local timezone
export function getDateMiddayUTC(date) {
  const parsedDate = date ? new Date(date) : new Date();
  parsedDate.setHours(12);
  parsedDate.setMinutes(0);
  parsedDate.setSeconds(0);
  parsedDate.setMilliseconds(0);

  return parsedDate.toISOString();
}
