import Block from "../../../block/Block";
import ListItem from "../../../list-item/ListItem";
import { detectBrowser } from "../helpers";

// <Editor> prop which sets a function to define custom block rendering.
export default function customBlockRenderer(contentBlock) {
  const type = contentBlock.getType();
  const browser = detectBrowser();
  const editable = !this.isUnsupportedOrFake(contentBlock);

  if (type === "unordered-list-item") {
    return {
      component: ListItem,
      editable: editable || browser.name !== "ie", // list items crash in ie, so we need to make them unsupported for now
    };
  } else if (type === "Table") {
    return {
      component: Block,
      editable: editable || browser.name !== "ie", // table cells crash in ie, so we need to make them unsupported for now
    };
  }
  return {
    component: Block,
    editable: editable,
  }
}
