export default function getMaxRow(contentState, tableId) {
  const blocks = contentState.getBlocksAsArray();
  return blocks.reduce((acc, next) => {
    if (next.get("type") === "Table" && next.getIn(["data", "tableId"]) === tableId) {
      const row = next.getIn(["data", "row"]);
      if (row > acc ) {
        acc = row;
      }
    }
    return acc;
  }, 0);
}