import React from "react";

const SvgLayoutCornersDashboard1 = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <circle cx={5} cy={5} r={5} />
    <circle cx={19} cy={5} r={5} />
    <circle cx={5} cy={19} r={5} />
    <circle cx={19} cy={19} r={5} />
  </svg>
);

export default SvgLayoutCornersDashboard1;
