import React from "react";

const SvgCogSquare1 = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M19.5 0h-15A4.505 4.505 0 000 4.5v15A4.5 4.5 0 004.5 24h15a4.5 4.5 0 004.5-4.5v-15A4.505 4.505 0 0019.5 0zm-1.215 12.534l.648.586a1.719 1.719 0 01-1.24 2.992l-.872-.045a.72.72 0 00-.755.755l.045.872a1.719 1.719 0 01-2.992 1.24l-.586-.648a.741.741 0 00-1.066 0l-.586.649a1.719 1.719 0 01-2.992-1.24l.045-.872a.728.728 0 00-.21-.545.706.706 0 00-.545-.21l-.872.045a1.719 1.719 0 01-1.24-2.992l.648-.585a.719.719 0 000-1.067l-.648-.586a1.719 1.719 0 011.24-2.993l.872.045a.72.72 0 00.755-.755l-.045-.872a1.719 1.719 0 012.992-1.24l.586.649a.742.742 0 001.066 0l.586-.649a1.719 1.719 0 012.992 1.24l-.045.872a.728.728 0 00.21.545.711.711 0 00.545.21l.872-.045a1.719 1.719 0 011.24 2.992l-.648.585a.719.719 0 000 1.067z" />
    <circle cx={11.997} cy={12.001} r={3} />
  </svg>
);

export default SvgCogSquare1;
