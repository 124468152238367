import React from "react";

function Logo(props) {
  return (
    <div style={{ display: "flex" }}>
      <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.21907 9.4762C6.85007 9.42718 6.51527 9.2347 6.28724 8.94047C6.05922 8.64625 5.95636 8.274 6.00096 7.90444L6.15813 6.7099C6.21753 6.34957 6.41398 6.02617 6.70638 5.80738C6.99878 5.58858 7.36447 5.49135 7.72692 5.53602C8.08937 5.58069 8.42051 5.76381 8.65103 6.04705C8.88155 6.3303 8.9936 6.69172 8.96373 7.05569L8.80655 8.25023C8.78328 8.43516 8.72369 8.61367 8.63121 8.7755C8.53873 8.93733 8.41518 9.07929 8.26766 9.19321C8.12014 9.30714 7.95156 9.39079 7.77161 9.43935C7.59166 9.48792 7.40388 9.50044 7.21907 9.4762Z"
          fill="#6134C6"
        />
        <path
          d="M19.0936 11.1655C18.7246 11.1165 18.3898 10.924 18.1618 10.6297C17.9337 10.3355 17.8309 9.96327 17.8755 9.59371L18.0327 8.39917C18.0816 8.02713 18.2764 7.68978 18.5741 7.46134C18.8718 7.23289 19.2481 7.13207 19.6201 7.18106C19.9922 7.23004 20.3295 7.42481 20.558 7.72252C20.7864 8.02023 20.8872 8.39649 20.8382 8.76853L20.6811 9.96307C20.6284 10.3323 20.4321 10.6658 20.1348 10.8909C19.8375 11.1161 19.4633 11.2148 19.0936 11.1655Z"
          fill="#6134C6"
        />
        <path
          d="M5.04216 15.4403C4.91292 15.2899 4.81562 15.1148 4.75618 14.9257C4.69673 14.7365 4.67638 14.5373 4.69638 14.34C4.71861 14.1657 4.77521 13.9975 4.86291 13.8452C4.95061 13.6929 5.06766 13.5596 5.20727 13.4529C5.34689 13.3461 5.5063 13.2682 5.67626 13.2235C5.84622 13.1788 6.02336 13.1683 6.19741 13.1926C6.50247 13.2332 6.78209 13.3841 6.98329 13.617C7.24445 14.0392 7.59523 14.3989 8.01079 14.6705C8.42636 14.9421 8.89655 15.119 9.38808 15.1888C10.9127 15.3931 12.0601 14.5365 12.3351 12.4225L13.2625 5.61675C13.3341 5.26814 13.5352 4.95964 13.8253 4.75341C14.1153 4.54719 14.4728 4.45857 14.8255 4.50541C15.1783 4.55225 15.5003 4.73107 15.7265 5.00583C15.9527 5.2806 16.0663 5.63089 16.0445 5.98611L15.1407 12.8626C15.0843 13.6289 14.8752 14.3762 14.5257 15.0605C14.1761 15.7447 13.6932 16.3522 13.1053 16.847C12.5309 17.2532 11.8814 17.541 11.1946 17.6935C10.5078 17.8459 9.79748 17.8601 9.10516 17.735C8.30763 17.6483 7.53611 17.4001 6.83759 17.0056C6.13907 16.6111 5.52819 16.0785 5.04216 15.4403Z"
          fill="#6134C6"
        />
        <path
          d="M7.1326 23.7713C4.7694 23.428 2.61241 22.2349 1.06561 20.4156C0.847726 20.1187 0.754217 19.7485 0.804952 19.3838C0.855688 19.019 1.04667 18.6884 1.3373 18.4623C1.62794 18.2361 1.99532 18.1322 2.36136 18.1726C2.7274 18.2131 3.06324 18.3946 3.29751 18.6788C4.44781 20.004 6.0561 20.8461 7.8006 21.0364C8.17473 21.0677 8.52111 21.2463 8.76356 21.533C9.006 21.8196 9.12464 22.1908 9.09337 22.565C9.06211 22.9391 8.8835 23.2855 8.59685 23.5279C8.31019 23.7704 7.93896 23.889 7.56484 23.8577L7.1326 23.7713Z"
          fill="#6134C6"
        />
        <path
          d="M4.90863 3.4483C4.60863 3.40242 4.33134 3.26127 4.11772 3.0457C3.90409 2.83013 3.76546 2.55158 3.72229 2.25118C3.67913 1.95078 3.73372 1.64446 3.878 1.37746C4.02228 1.11046 4.24861 0.896947 4.52355 0.768448C6.87182 -0.222442 9.51437 -0.256285 11.8872 0.674142C12.2312 0.825253 12.5009 1.10679 12.6373 1.45682C12.7736 1.80685 12.7653 2.1967 12.6142 2.54061C12.4631 2.88452 12.1815 3.15431 11.8315 3.29064C11.4815 3.42696 11.0916 3.41866 10.7477 3.26755C9.10399 2.65235 7.28905 2.67759 5.66307 3.33828C5.4264 3.44331 5.16544 3.48136 4.90863 3.4483Z"
          fill="#6134C6"
        />
      </svg>
    </div>
  );
}

export default Logo;
