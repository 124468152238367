import React from "react";
import "./DocusignNavigationTabs.scss";

class Tabs extends React.Component {
  state = {
    activeTab: this.props.children[0].props.label,
  };
  changeTab = (tab) => {
    this.setState({ activeTab: tab });
  };
  render() {
    let content;
    let buttons = [];
    return (
      <div>
        {React.Children.map(this.props.children, (child) => {
          buttons.push(child.props.label);
          if (child.props.label === this.state.activeTab) content = child.props.children;
        })}

        <TabButtons activeTab={this.state.activeTab} buttons={buttons} changeTab={this.changeTab} />
        <hr className="tab-indicator" />
        <div className="tab-content">{content}</div>
      </div>
    );
  }
}

const TabButtons = ({ buttons, changeTab, activeTab }) => {
  return (
    <div className="tab-buttons">
      {buttons.map((button) => {
        return (
          <h2
            key={button}
            className={button === activeTab ? "active ds-tab" : "inactive ds-tab"}
            onClick={() => changeTab(button)}
          >
            {button}
          </h2>
        );
      })}
    </div>
  );
};

export const Tab = (props) => {
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default Tabs;
