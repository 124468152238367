import {
  EditorState,
  RichUtils,
} from "draft-js";
import getState from "../editor/getState";

export default function applyInlineStyles(editorState, styles, offset, length) {
  let updatedEditorState = editorState;
  let { selectionState } = getState(updatedEditorState);

  selectionState = selectionState.merge({
    'anchorOffset': offset,
    'focusOffset': offset + length
  });
  updatedEditorState = EditorState.forceSelection(updatedEditorState, selectionState);
  styles.forEach((v) => {
    updatedEditorState = RichUtils.toggleInlineStyle(updatedEditorState, v);
  });
  return updatedEditorState;
}
