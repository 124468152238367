import { observable, action, computed, decorate } from "mobx";

class ConstantsStore {
  constants = [];

  setData(data) {
    for (const constant in data) {
      // set the constant on state obj first
      this.constants[constant] = {};

      const elem = data[constant];
      elem.forEach((o) => {
        this.constants[constant][o.id] = o;
      });
    }
  }

  get allConstants() {
    return this.constants.slice();
  }

  allEnvelopeTemplateRecipientsType() {
    return this.constants["envelope_template_recipient_types"];
  }

  allEnvelopeTemplateRecipientsSourceType() {
    return this.constants["envelope_template_recipient_source_types"];
  }

  allDocusignTabTypes() {
    return this.constants["envelope_template_tab_types"];
  }

  allVariableTypes() {
    return this.constants["variable_types"];
  }

  operationById(id) {
    return this.constants["operations"][id];
  }

  responseTypeById(id) {
    return this.constants["response_types"][id];
  }

  variableTypeOperationById(id) {
    return this.constants["variable_type_operations"][id]["operations"];
  }

  variableTypeById(id) {
    return this.constants["variable_types"][id];
  }

  variableSourceById(id) {
    return this.constants["variable_sources"][id];
  }

  inputTypeById(id) {
    return this.constants["input_types"][id];
  }

  operationsByVariableTypeId(variableTypeId) {
    return this.variableTypeOperationById(variableTypeId).map((id) => this.operationById(id));
  }
}

decorate(ConstantsStore, {
  constants: observable,
  setData: action,
  allConstants: computed,
  inputTypeById: action,
});

export default new ConstantsStore();
