import React, { Component } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import debounce from "lodash.debounce";
import JoButton from "../common/JoButton.js";
import JoLink from "../common/JoLink";
import RuleGroupSummary from "./RuleGroupSummary";
import JoIcon from "../common/JoIcon.js";
import Rule from "./Rule";
import ToggleSwitch from "../common/ToggleSwitch";
import "./RuleGroup.scss";
import { Color, VariableType, InputType, VariableSource } from "../../utils/constants";
import classNames from "classnames";
import { clog } from "../../utils/helpers";
import { getDateMiddayInTimezone } from "@joseflegal/ui-lib/src/utils/helpers";

class RuleGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      creating: false,
      ruleGroupLogic: this.props.ruleGroup.logic,
      ruleGroupName: this.props.ruleGroup.name,
      selectedRuleId: null,
    };

    this.groupName = React.createRef();
    this.onAddRule = this.onAddRule.bind(this);
    this.onChangeLogic = this.onChangeLogic.bind(this);
    this.updateRuleGroup = this.updateRuleGroup.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.handleSelectRule = this.handleSelectRule.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.ruleGroup.id !== this.props.ruleGroup.id) {
      this.setState({
        creating: false,
        ruleGroupLogic: this.props.ruleGroup.logic,
        ruleGroupName: this.props.ruleGroup.name,
      });
    }
  }
  renderRules(children) {
    const { selectedRuleId } = this.state;
    const { isDisabled } = this.props;

    return children.map((item) => {
      if (item.hasOwnProperty("rule_group_id")) {
        return (
          <Rule
            key={item.id}
            rule={item}
            variables={this.props.variables}
            operation={this.state.ruleGroupLogic}
            isSelected={selectedRuleId === item.id}
            clickHandler={this.handleSelectRule}
            isDisabled={isDisabled}
          />
        );
      }
      return false;
    });
  }

  updateRuleGroup() {
    console.log("we hit api");

    this.props.ruleGroupsStore
      .updateOne({
        updatedRuleGroup: {
          id: this.props.ruleGroup.id,
          logic: this.state.ruleGroupLogic,
          name: this.state.ruleGroupName,
        },
      })
      .then((res) => {
        console.log(res);
      });
  }

  onChangeLogic(e) {
    this.limitedUpdate();
    const logic = e ? "all" : "any";
    let { ruleGroupLogic } = this.state;
    ruleGroupLogic = logic;
    this.setState({
      ruleGroupLogic,
    });
  }

  onChangeName() {
    // so that this doesnt fire too often, we will call our debounce function
    this.limitedUpdate();
    this.setState({
      ruleGroupName: this.groupName.current.innerText,
    });
  }

  // debounceable function to call on for input text changes in the rules sidebar
  limitedUpdate = debounce((e) => {
    console.log(e);
    clog("Hitting >>> debounced limitedUpdate()", Color.blue);

    this.updateRuleGroup();
  }, 500);

  // set the default props of the rules.
  // we're defaulting to the first question in the variables array,
  // and setting a default first operation and answer accordingly
  onAddRule() {
    this.setState({
      creating: true,
    });
    const { variables, ruleGroup } = this.props;

    let operableVariableCandidate = variables[0];

    for (let i = 0; i < variables.length; i++) {
      const operations = this.props.constantsStore.operationsByVariableTypeId(variables[i].variable_type_id);
      if (operations && operations.length > 0) {
        operableVariableCandidate = variables[i];
        break;
      }
    }

    const variableType = this.props.constantsStore.variableTypeById(operableVariableCandidate.variable_type_id);
    const variableSource = this.props.constantsStore.variableSourceById(operableVariableCandidate.variable_source_id);
    const inputType = this.props.constantsStore.inputTypeById(operableVariableCandidate.input_type_id);
    const parsedDate = getDateMiddayInTimezone({
      date: new Date(),
      targetTimezone: this.props.i18nStore.valuesById("timezone"),
    });
    const initialValue =
      variableType.shortname === VariableType.Date
        ? parsedDate
        : variableType.shortname === VariableType.Number ||
          variableType.shortname === VariableType.Currency ||
          variableType.shortname === VariableType.Percentage
        ? "1"
        : variableType.shortname === VariableType.Duration
        ? JSON.stringify({
            value: 1,
            unit: "day",
          })
        : "*edit this text*";

    // set up default answerText or get 1st predefined_answer if there are predefined_answers
    const answer =
      variableSource.shortname !== VariableSource.API_Request &&
      [InputType.McMultiple, InputType.McSingle, InputType.McSingleScoring].includes(inputType.shortname)
        ? { value: null, mc_option_ids: [operableVariableCandidate.mc_option_ids[0]] }
        : { value: initialValue, mc_option_ids: [] };

    this.props.rulesStore
      .create({
        createdRule: {
          rule_group_id: ruleGroup.id,
          variable_id: operableVariableCandidate.id,
          operation_id: 1,
          answer,
        },
      })
      .then((rule) => {
        this.setState({
          creating: false,

          selectedRuleId: rule.id,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          creating: false,
        });
      });
  }

  handleSelectRule(ruleId) {
    this.setState({
      selectedRuleId: ruleId,
    });
  }

  render() {
    const { ruleGroup, variables, isDisabled } = this.props;
    if (!ruleGroup) {
      return null;
    } else {
      // get all the rules from rulesStore that whose rule_group_id match the baseRuleGroup
      const rules = this.props.rulesStore.allRules.filter((rule) => rule.rule_group_id === ruleGroup.id);

      const variablesWithOperations = variables
        ? variables.filter((v) => {
            const variableTypeOperations = this.props.constantsStore.variableTypeOperationById(v.variable_type_id);
            console.log(variableTypeOperations);
            return variableTypeOperations.length;
          })
        : [];

      const children = [...rules].sort((a, b) => a.id - b.id);
      const containerClasses = classNames({
        "rule-groups-container": true,
        "rule-groups-container--slide-rulegroup": this.state.selectedRuleGroupId,
      });
      return (
        <div className={containerClasses}>
          <div className="rule-group--child">
            <div className="rule-group__name-container">
              <JoLink iconLeft="ArrowLeft1" title="Back" clickHandler={this.props.closeHandler} />
              <h2
                className="rule-group__name"
                contentEditable="true"
                suppressContentEditableWarning={true}
                ref={this.groupName}
                onInput={this.onChangeName}
              >
                {ruleGroup.name}
              </h2>
            </div>
            <RuleGroupSummary logic={this.state.ruleGroupLogic} rulesLength={rules.length} />
            {rules.length > 1 && (
              <div className="switch">
                <span>Any</span>
                <ToggleSwitch
                  leftLabel="Any"
                  rightLabel="All"
                  switchState={this.state.ruleGroupLogic !== "any"}
                  changeHandler={this.onChangeLogic}
                  disabled={isDisabled}
                />
                <span>All</span>
              </div>
            )}
            {children.length > 0 && (
              <div className="b-rule-group__wrapper">
                {/* Iterate through children */}
                {/* Render Rule component */}
                {this.renderRules(children)}
                {/* else if has hasOwnProperty rules is a nested ruleGroup not MVP */}
              </div>
            )}
            <div className="rule-actions-wrapper">
              <div className="rule-actions-add">
                <JoButton
                  variant="outline"
                  disabled={this.state.creating || !variablesWithOperations.length || isDisabled}
                  clickHandler={this.onAddRule}
                >
                  <JoIcon title="add rule" icon="Add" spacing="right" />
                  Add rule
                </JoButton>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

RuleGroup.propTypes = {
  isDisabled: PropTypes.bool,
  variables: PropTypes.array,
  ruleGroup: PropTypes.object,
  ruleGroupsStore: PropTypes.object,
  rulesStore: PropTypes.object,
  textrulesStore: PropTypes.object,
  constantsStore: PropTypes.object,
  closeHandler: PropTypes.func,
  i18nStore: PropTypes.object,
};
// mobx stores are injected into AutomationEditor so we can access them on component props observer makes component reactive to changes in state
export default inject(
  "textrulesStore",
  "ruleGroupsStore",
  "rulesStore",
  "constantsStore",
  "i18nStore"
)(observer(RuleGroup));
