import React, { Component } from "react";
import PropTypes from "prop-types";
import JoIcon from "../../components/common/JoIcon.js";
import { errorMessages } from "../../utils/constants";
import "./Error.scss";
import { getDashboardURL } from "../../utils/url.js";

class Error extends Component {
  static defaultProps = {
    dashboardUrl: getDashboardURL,
  };
  render() {
    const errorMessage = this.props.error ? errorMessages[this.props.error] : this.props.children;
    // console.log(this);
    return (
      <div className="error-page">
        <h1>Something went wrong.</h1>
        <code className="error-page__message">{errorMessage}</code>
        <a className="jo-button" href={this.props.dashboardUrl} target="_blank" rel="noopener noreferrer">
          <JoIcon title="Back" icon="ArrowLeft1" spacing="right" />
          Back to Dashboard
        </a>
      </div>
    );
  }
}

Error.propTypes = {
  children: PropTypes.node,
  error: PropTypes.string,
  dashboardUrl: PropTypes.string,
};

export default Error;
