import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const RuleGroupSummary = ({ ...props }) => {
  const ruleGroupSummary = classNames({
    "rule-group__summary": true,
    "base-margin-bottom": props.rulesLength === 1,
  });
  return (
    <div className={ruleGroupSummary} data-test-id={props.testId}>
      {!props.rulesLength ? (
        <p>Add a rule to determine when the highlighted text will appear in the document.</p>
      ) : (
        <Fragment>
          <span>The highlighted text will appear </span>
          {props.rulesLength === 1 ? (
            <span>if the following rule is true.</span>
          ) : (
            <span>
              {` if `}
              <strong>{props.logic}</strong>
              {` of the following rules ${props.logic === "all" ? "are" : "is"} true.`}
            </span>
          )}
        </Fragment>
      )}
    </div>
  );
};

RuleGroupSummary.propTypes = {
  logic: PropTypes.string,
  rulesLength: PropTypes.number,
  testId: PropTypes.string,
};

export default RuleGroupSummary;
