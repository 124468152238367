// detect whether the focused block has changed between newState and previousState (used in onChange)
export default function focusedBlocksHasChanged(newFocusedBlocks, oldFocusedBlocks) {
  if (!newFocusedBlocks || !oldFocusedBlocks) {
    return true;
  }
  if (newFocusedBlocks.length !== oldFocusedBlocks.length) {
    return true;
  } else {
    if (newFocusedBlocks[0] !== oldFocusedBlocks[0]) {
      return true;
    } else {
      return false;
    }
  }
}
