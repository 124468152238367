export function getQueryStringValue(key) {
  return decodeURIComponent(
    window.location.search.replace(
      new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[.+*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"),
      "$1"
    )
  );
}

// find if value is between ranges inclusive used by replaceInlineStyleByRange uses for TEXTRULE_ACTIVE
export function between(x, start, end, length) {
  // console.log(x, start, end, length);
  if (!end) {
    end = start + length;
  }
  return x >= start && x <= end;
}

// Introducing clog
// For colourful console.log's
export function clog(message, color) {
  const colorString = color ? "color: " + color + ";" : null;
  const messageString = "%c" + message;
  console.log(messageString, colorString);
}

// find if cursor position value is between ranges inclusive of end. This is different from the original between function (above)
// as it appears we need to treat cursor position different from a textrule offset
export function cursorBetweenRange(x, start, end, length) {
  // console.log(x, start, end, length);
  if (!end) {
    end = start + length;
  }
  return x > start && x <= end;
}

// nested list
export function getListItemMark(listType, format, depth) {
  if (listType === "Decimal") {
    listType = "decimal";
  } else if (listType === "LowerLetter") {
    listType = "lower-alpha";
  } else if (listType === "UpperLetter") {
    listType = "upper-alpha";
  } else if (listType === "LowerRoman") {
    listType = "lower-roman";
  } else if (listType === "UpperRoman") {
    listType = "upper-roman";
  }
  var reg = new RegExp(/%[0-9]*/g);
  var index = -1;
  var arr = format.match(reg);
  if (arr === null) {
    return null;
  }
  var len = arr.length;
  var start_append = false;
  var end_append = false;
  var depth_start = depth - len + 1;
  var res = format.replace(reg, function (match, offset) {
    var rep = "";
    index++;
    var level = index + depth_start;
    if (index === 0) {
      if (offset !== 0) {
        rep += '" ';
        start_append = true;
      }
      rep += "counter(level" + level + ", " + listType + ")";
      if (index < len - 1) {
        rep += ' "';
      }
      if (index === len - 1 && offset + match.length < format.length) {
        rep += ' "';
        end_append = true;
      }
    } else if (index < len - 1) {
      rep = '" counter(level' + level + ", " + listType + ') "';
    } else if (index === len - 1) {
      rep = '" counter(level' + level + ", " + listType + ")";
      if (offset + match.length < format.length) {
        rep += ' "';
        end_append = true;
      }
    }
    return rep;
  });
  if (start_append) {
    res = '"' + res;
  }
  if (end_append) {
    res = res + '"';
  }
  return res;
}

export function isListItem(block) {
  const listItem = block.getIn(["data", "listItem"]);

  /*
    Now we don't support list inside table.
    Later we should check only 'listType'
  */
  if (block.type === "unordered-list-item" || block.type === "ordered-list-item") {
    if (
      listItem !== undefined &&
      listItem !== null &&
      listItem.listType !== null &&
      listItem.format !== null &&
      listItem.format !== ""
    ) {
      return true;
    }
  }
  return false;
}

export function twipToPx(twip) {
  return twip / 15;
}

export function pxToPt(px) {
  // 1inch = 72pt
  // 1inch = 96px
  return (px * 72) / 96;
}

/* Creates a uppercase hex number with at least length digits from a given number */
function fixedHex(number, length) {
  var str = number.toString(16).toUpperCase();
  while (str.length < length) str = "0" + str;
  return str;
}

/* Creates a unicode literal based on the string */

export function unicodeLiteral(str) {
  var i;
  var result = "";
  for (i = 0; i < str.length; ++i) {
    /* You should probably replace this by an isASCII test */
    if (str.charCodeAt(i) > 126 || str.charCodeAt(i) < 32) result += "\\u" + fixedHex(str.charCodeAt(i), 4);
    else result += str[i];
  }

  return result;
}

// Generates a unique Id of length checking against supplied existing Ids
export function generateId(existingIdsArray, length = 8, isLowerCase = false) {
  let id = "";
  var chars = isLowerCase ? "abcdef123456789" : "ABCDEF123456789";
  const charsLength = chars.length;

  for (let i = 0; i < length; i++) {
    id += chars.charAt(Math.floor(Math.random() * charsLength));
  }
  // while id is found in existingIdsArray iterate through string changing chars till id is unique
  while (existingIdsArray.includes(id)) {
    for (let j = 0; j < length; j++) {
      id[j] = chars.charAt(Math.floor(Math.random() * charsLength));
    }
  }
  return id;
}

export const LEVEL = {
  TRACE: 0,
  DEBUG: 1,
  INFO: 2,
  WARNING: 3,
  ERROR: 4,
};
var logLevel = LEVEL.ERROR;
export function dlog(level, indentNum, ...msg) {
  console.log("DLOG!!", level, indentNum, ...msg);
  var indent = "";
  for (var i = 0; i < indentNum; i++) {
    indent += "\t";
  }
  if (logLevel <= level) {
    console.log(indent, ...msg);
  }
}

export function dtable(level, obj) {
  if (logLevel <= level) {
    console.table(obj);
  }
}

export const queryString = (function (a) {
  if (a === "") return {};
  var b = {};
  for (var i = 0; i < a.length; ++i) {
    var p = a[i].split("=", 2);
    if (p.length === 1) b[p[0]] = "";
    else b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
  }
  return b;
})(window.location.search.substr(1).split("&"));

// https://github.com/30-seconds/30-seconds-of-code/blob/master/snippets/debounce-promise.md
// self arg added for this binding
export const debouncePromise = (fn, ms = 0, self = this) => {
  let timeoutId;
  const pending = [];
  return (...args) =>
    new Promise((res, rej) => {
      // Each time the debounced function is invoked, clear the current pending timeout with clearTimeout() and use setTimeout()
      // to create a new timeout that delays invoking the function until at least ms milliseconds has elapsed.
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        const currentPending = [...pending];
        pending.length = 0;
        Promise.resolve(fn.apply(self, args)).then(
          (data) => {
            console.log(data);
            currentPending.forEach(({ resolve }) => resolve(data));
          },
          (error) => {
            currentPending.forEach(({ reject }) => reject(error));
          }
        );
      }, ms);
      pending.push({ resolve: res, reject: rej });
    });
};
