// Will return the start (offset) and length of a given selectionState
export default function getOffsetAndLength(selectionState) {
  const startOffset = selectionState.getStartOffset();
  const endOffset = selectionState.getEndOffset();
  const length = endOffset - startOffset;
  return {
    offset: startOffset,
    length: length,
  };
}
