import * as React from "react";
const SvgDocx = (props) => (
  <svg fill="none" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Docx</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m19.209 1.086 4.22 4.328c.365.375.57.884.571 1.414V22.5c0 .53-.205 1.04-.571 1.414a1.926 1.926 0 0 1-1.379.586H6.45c-.517 0-1.013-.21-1.379-.586A2.026 2.026 0 0 1 4.5 22.5v-10H15v-9H4.5v-1c0-.53.205-1.04.571-1.414A1.926 1.926 0 0 1 6.45.5h11.38c.517 0 1.013.21 1.379.586ZM8.887 20h4.388a.963.963 0 0 0 .69-.293c.182-.187.285-.442.285-.707 0-.265-.103-.52-.286-.707a.963.963 0 0 0-.689-.293H8.887a.963.963 0 0 0-.689.293 1.013 1.013 0 0 0-.286.707c0 .265.103.52.286.707.183.188.43.293.69.293Zm0-4h8.776a.963.963 0 0 0 .689-.293c.183-.187.285-.442.285-.707 0-.265-.102-.52-.285-.707a.963.963 0 0 0-.69-.293H8.889a.963.963 0 0 0-.69.293 1.013 1.013 0 0 0-.286.707c0 .265.103.52.286.707.183.188.43.293.69.293Z"
    />
    <path sd="M.5 4h14v8H.5z" />
    <path d="M3 6.55h1.33c.263 0 .475.035.636.106.162.071.296.174.401.307.106.133.182.288.23.464.047.177.07.364.07.562 0 .31-.035.55-.106.722-.07.17-.167.313-.293.43a.963.963 0 0 1-.403.228c-.197.053-.375.08-.534.08H3v-2.9Zm.896.656V8.79h.22c.187 0 .32-.02.399-.061A.428.428 0 0 0 4.7 8.51c.045-.104.067-.272.067-.504 0-.307-.05-.518-.15-.63-.1-.114-.267-.171-.499-.171h-.223ZM6.028 8.001c0-.473.132-.842.395-1.105.264-.264.631-.396 1.102-.396.482 0 .854.13 1.115.39.261.258.392.62.392 1.087 0 .34-.058.617-.172.835a1.22 1.22 0 0 1-.495.506c-.215.12-.483.18-.805.18-.327 0-.598-.052-.812-.156a1.224 1.224 0 0 1-.52-.495c-.134-.225-.2-.507-.2-.846Zm.896.004c0 .293.054.503.162.63.11.129.257.193.445.193.192 0 .341-.063.447-.188.105-.126.158-.35.158-.675 0-.273-.056-.472-.166-.597a.56.56 0 0 0-.447-.19.544.544 0 0 0-.435.192c-.11.128-.164.34-.164.635ZM11.444 8.264l.785.237c-.052.22-.135.404-.249.552a1.08 1.08 0 0 1-.423.334 1.572 1.572 0 0 1-.64.113c-.316 0-.574-.045-.774-.136a1.233 1.233 0 0 1-.516-.485c-.145-.23-.218-.526-.218-.886 0-.48.128-.848.382-1.105.256-.259.617-.388 1.084-.388.365 0 .652.074.86.221.21.148.365.375.467.68l-.791.177a.515.515 0 0 0-.514-.407.526.526 0 0 0-.467.245c-.082.12-.123.311-.123.571 0 .322.049.543.146.663a.503.503 0 0 0 .412.178c.171 0 .3-.048.387-.145a.888.888 0 0 0 .192-.419Z" />
  </svg>
);
export default SvgDocx;
