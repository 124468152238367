import * as React from "react";
const SvgPdf = (props) => (
  <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3 6.5H4.54093C4.87653 6.5 5.12756 6.57981 5.294 6.73943C5.4618 6.89905 5.5457 7.12619 5.5457 7.42087C5.5457 7.72374 5.4543 7.96044 5.27149 8.13097C5.09004 8.3015 4.81241 8.38677 4.43861 8.38677H3.93111V9.5H3V6.5ZM3.93111 7.77899H4.15825C4.33697 7.77899 4.46248 7.74829 4.53479 7.6869C4.60709 7.62415 4.64325 7.54434 4.64325 7.44748C4.64325 7.35334 4.61187 7.27353 4.54911 7.20805C4.48636 7.14256 4.36835 7.10982 4.19509 7.10982H3.93111V7.77899Z"
      fill="#E82425"
    />
    <path
      d="M6.04502 6.5H7.42224C7.69372 6.5 7.91269 6.53683 8.07913 6.6105C8.24693 6.68417 8.3854 6.7899 8.49454 6.92769C8.60368 7.06548 8.68281 7.22578 8.73192 7.40859C8.78104 7.59141 8.80559 7.78513 8.80559 7.98977C8.80559 8.31037 8.76876 8.55935 8.69509 8.7367C8.62278 8.91269 8.52183 9.06071 8.39222 9.18076C8.26262 9.29945 8.12347 9.37858 7.97476 9.41814C7.77149 9.47271 7.58731 9.5 7.42224 9.5H6.04502V6.5ZM6.97203 7.1794V8.81855H7.19918C7.39291 8.81855 7.5307 8.79741 7.61255 8.75512C7.69441 8.71146 7.75853 8.63643 7.80491 8.53001C7.8513 8.42224 7.87449 8.24829 7.87449 8.00819C7.87449 7.69031 7.82265 7.47271 7.71896 7.35539C7.61528 7.23806 7.44338 7.1794 7.20327 7.1794H6.97203Z"
      fill="#E82425"
    />
    <path d="M9.29877 6.5H11.5907V7.14461H10.2299V7.66849H11.3922V8.27422H10.2299V9.5H9.29877V6.5Z" fill="#E82425" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2088 1.086L23.4286 5.414C23.7944 5.78899 23.9999 6.29761 24 6.828V22.5C24 23.0304 23.7946 23.5391 23.4289 23.9142C23.0632 24.2893 22.5672 24.5 22.05 24.5H6.45C5.93283 24.5 5.43684 24.2893 5.07114 23.9142C4.70545 23.5391 4.5 23.0304 4.5 22.5V12.5H15V3.5H4.5V2.5C4.5 1.96957 4.70545 1.46086 5.07114 1.08579C5.43684 0.710714 5.93283 0.5 6.45 0.5H17.8302C18.3473 0.500113 18.8432 0.710901 19.2088 1.086ZM8.8875 20H13.275C13.5336 20 13.7816 19.8946 13.9644 19.7071C14.1473 19.5196 14.25 19.2652 14.25 19C14.25 18.7348 14.1473 18.4804 13.9644 18.2929C13.7816 18.1054 13.5336 18 13.275 18H8.8875C8.62891 18 8.38092 18.1054 8.19807 18.2929C8.01522 18.4804 7.9125 18.7348 7.9125 19C7.9125 19.2652 8.01522 19.5196 8.19807 19.7071C8.38092 19.8946 8.62891 20 8.8875 20ZM8.8875 16H17.6625C17.9211 16 18.1691 15.8946 18.3519 15.7071C18.5348 15.5196 18.6375 15.2652 18.6375 15C18.6375 14.7348 18.5348 14.4804 18.3519 14.2929C18.1691 14.1054 17.9211 14 17.6625 14H8.8875C8.62891 14 8.38092 14.1054 8.19807 14.2929C8.01522 14.4804 7.9125 14.7348 7.9125 15C7.9125 15.2652 8.01522 15.5196 8.19807 15.7071C8.38092 15.8946 8.62891 16 8.8875 16Z"
      fill="#E82425"
    />
  </svg>
);
export default SvgPdf;
