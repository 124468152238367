import React from "react";
import PropTypes from "prop-types";
import "./NavbarGroup.scss";
import classNames from "classnames";

const NavbarGroup = ({ children, classList }) => {
  const classes = classNames({
    navbar__group: true,
    ...classList.reduce((acc, next) => {
      acc[next] = true;
      return acc;
    }, {}),
  });

  return <div className={classes}>{children}</div>;
};

NavbarGroup.propTypes = {
  children: PropTypes.node,
  classList: PropTypes.array,
};

NavbarGroup.defaultProps = {
  classList: [],
};

export default NavbarGroup;
