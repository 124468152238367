export const Inline = {
  BOLD: "BOLD",
  ITALIC: "ITALIC",
  UNDERLINE: "UNDERLINE",
  CAPS: "CAPS",
};

export const EntityTypes = {
  ANSWER: "ANSWER",
  DOCUSIGN_TAB: "DOCUSIGN_TAB",
  TAB: "TAB",
  XREF: "XREF",
  LINK: "LINK",
};

// TODO
export const Block = {
  UNSTYLED: "unstyled",
};

// TODO
export const KEY_COMMANDS = {
  addNewBlock: () => "add-new-block",
  changeType: (type = "") => `changetype:${type}`,
  showLinkInput: () => "showlinkinput",
  unlink: () => "unlink",
  toggleInline: (type = "") => `toggleinline:${type}`,
  deleteBlock: () => "delete-block",
};

export const Color = {
  purple: "#6134c6",
  darkPurple: "#4620b0",
  lightPurple: "#e9e3f9",
  red: "#d13e3e",
  green: "#00ff9e",
  blue: "#009ce9",
  darkBlue: "#0075af",
  white: "#ffffff",
  iron: "#cccccc",
  steel: "#666666",
  purpleSoft: "#ccbcf8",
  neutral: "#f8f8f9",
};

export const standardStyleMap = {
  BOLD: { fontWeight: "bold" },
  CODE: { fontFamily: "monospace", wordWrap: "break-word" },
  ITALIC: { fontStyle: "italic" },
  STRIKETHROUGH: { textDecoration: "line-through" },
  UNDERLINE: { textDecoration: "underline" },
  CAPS: { textTransform: "uppercase" },
};

export const customStyleMap = {
  TEXTRULE: {
    // Hacky way to target a textrule via [style*="font-family: TEXTRULE"]
    // see AutomationEditor.scss for styles
    fontFamily: "TEXTRULE",
  },
  TEXTRULE_ACTIVE: {
    // Hacky way to target a textrule via [style*="font-family: TEXTRULE_ACTIVE"]
    // see AutomationEditor.scss for styles
    fontFamily: "TEXTRULE_ACTIVE",
  },
};

// map of classes to reset on the react select
export const resetSelectStyles = {
  option: () => {},
  control: () => {},
  menu: () => {},
  menuList: () => {},
  indicatorSeparator: () => {},
  singleValue: () => {},
  valueContainer: () => {},
};

export const errorMessages = {
  401: "You are not authorized. Please return to the dashboard and launch editor again.",
  404: "Sorry that page cannnot be found.",
  429: "Too many requests",
};

export const defaultStyles = {
  fontFamily: "Chivo, sans-serif",
};

export const capsStyles = {
  UPPERCASE: "UPPERCASE",
};

export const DocuSignRecipientTypeId = {
  Signer: 1,
  CarbonCopy: 2,
  Agent: 3,
  Editor: 4,
};

export const ExportFormat = {
  WORD: {
    id: 1,
    label: "Microsoft Word",
    extension: "Docx",
  },
  PDF: {
    id: 2,
    label: "PDF",
    extension: "Pdf",
  },
};

export const BlockType = {
  Table: "Table",
};

export const Links = {
  HelpUnlock: "https://support.joseflegal.com/hc/en-us/articles/360048481773-Unlocking-unsupported-content-and-blocks",
};

export const VariableType = {
  Text: "text",
  Email: "email",
  Fullname: "fullname",
  Number: "number",
  Currency: "currency",
  Percentage: "percentage",
  Duration: "duration",
  Address: "address",
  Phone: "phone",
  Url: "url",
  Date: "date",
  RichText: "rich_text",
};

export const VariableSource = {
  Response: "response",
  Calculation: "calculation",
  API_Request: "api_request",
};

export const DocuSignRecipientSourceType = {
  Manual: "Entered manually",
  Bot: "Collected from bot interaction",
  Docusign: "Selected from DocuSign contacts",
};

export const InputType = {
  Text: "free",
  McSingle: "mc_single",
  McMultiple: "mc_multiple",
  McSingleScoring: "mc_single_with_score",
  File: "upload",
  Date: "date_picker",
  Multiline: "multiline",
  Duration: "duration",
};

export const Calendricals = ["day", "week", "month", "year"];
