import { EditorState, SelectionState, Modifier } from "draft-js";
import convertTextruleStyleName from "./convertTextruleStyleName";
import getState from "../editor/getState";

// This function well clear all TEXTRULEACTIVE_[id] customStyleRanges for example when user clicks on another block after being focused on a inline textrule
// This function is very coupled/specific to TEXTRULE - worth considering making it more generic in the future
export default function resetTextruleStyleInBlock(editorState, newSelectionState, blockKey, selectTextruleId = null) {
  const { contentState } = getState(editorState);
  const blockByKey = blockKey ? contentState.getBlockForKey(blockKey) : null;
  editorState = EditorState.set(editorState, { allowUndo: false });
  const regex = /^TEXTRULEACTIVE_/;
  let dynTextruleActiveStyle = false;
  blockByKey &&
    blockByKey.findStyleRanges(
      (char) => {
        // We need to detect that TEXTRULEACTIVE_ exists but also in the event it does place into dynTextruleActiveStyle for use in applyInlineStyle()
        const stylesList = char.getStyle();

        if (!stylesList.size) return false;
        dynTextruleActiveStyle = false;
        stylesList.forEach((style) => {
          // prevent detected TEXTRULEACTIVE_ from proceeding if its the textrule user is already inside ie id = selectTextruleId (as there's no need to remove it)
          const styleId = parseInt(style.split("_").pop());
          if (regex.test(style) && selectTextruleId !== styleId) {
            dynTextruleActiveStyle = style;
          }
        });
        return dynTextruleActiveStyle;
      },
      (start, end) => {
        let newStart = start;
        let newEnd = end;
        // The start and end value may not match actual inline_textrules as they are effected by BOLD, ITALIC etc so get the id from our styleRange
        // and use it to access inline_textrule data for use in updatedSelectionState
        const id = parseInt(dynTextruleActiveStyle.split("_").pop());
        const inlineTextrule = blockByKey.getIn(["data", "inline_textrules"]).find((rule) => rule.id === id);
        if (inlineTextrule) {
          newStart = inlineTextrule.offset;
          newEnd = inlineTextrule.offset + inlineTextrule.length;
        }

        // update our selection to the range of the found TEXTRULEACTIVE
        const updatedSelectionState = new SelectionState({
          anchorKey: blockByKey.getKey(),
          anchorOffset: newStart,
          focusKey: blockByKey.getKey(),
          focusOffset: newEnd,
          hasFocus: true,
          isBackward: false,
        });

        let updatedStylesContent = Modifier.removeInlineStyle(
          contentState,
          updatedSelectionState,
          dynTextruleActiveStyle
        );
        // We need to revert back to the original TEXTRULE_[id]
        const dynTextruleStyle = convertTextruleStyleName(dynTextruleActiveStyle);
        updatedStylesContent = Modifier.applyInlineStyle(updatedStylesContent, updatedSelectionState, dynTextruleStyle);

        editorState = EditorState.push(editorState, updatedStylesContent, "change-inline-style");

        // Return selection to its original state before pushing the adjusted editorState
        editorState = EditorState.acceptSelection(editorState, newSelectionState);
      }
    );
  editorState = EditorState.set(editorState, { allowUndo: true });
  return editorState;
}
