import { Modifier, RichUtils, EditorState } from "draft-js";
import getState from "../editor/getState";
// In place of draft-js default behaviour of splitting blocks this function
// returns a new editorState with added new line (clearing any content if selection is range)
export default function handleSoftReturns() {
  const editorState = this.state.editorState;
  const { contentState, selectionState } = getState(editorState);
  let updatedEditorState;
  if (selectionState.isCollapsed()) {
    updatedEditorState = RichUtils.insertSoftNewline(editorState);
  } else {
    // clear selection and insert newline
    let newContentState = Modifier.removeRange(contentState, selectionState, "forward");
    const newSelectionState = newContentState.getSelectionAfter();
    const block = newContentState.getBlockForKey(newSelectionState.getStartKey());
    // create new contentState with "\n" for editorState we will return
    newContentState = Modifier.insertText(
      newContentState,
      newSelectionState,
      "\n",
      block.getInlineStyleAt(newSelectionState.getStartOffset()),
      null
    );

    updatedEditorState = EditorState.push(editorState, newContentState, "insert-fragment");
  }
  this.onChange(updatedEditorState);
}
