export default function isInSameTableRow(src, dst) {
  if (!src || !dst) {
    return false;
  }

  if (
    dst.get("type") === "Table" &&
    dst.getIn(["data", "row"]) === src.data.get("row") &&
    dst.getIn(["data", "tableId"]) === src.data.get("tableId")
  ) {
    return true;
  }
  return false;
}
