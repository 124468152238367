import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import { DocuSignRecipientTypeId, resetSelectStyles } from "../../utils/constants";
import DropdownIndicator from "../common/DropdownIndicator";
import CtaMessageBar from "../common/CtaMessageBar";
import JoButton from "../common/JoButton.js";
import JoIcon from "../common/JoIcon.js";
import "./InsertAnswer.scss";

class InsertDocusignTag extends Component {
  constructor(props) {
    super(props);

    const recipients = this.props.recipientsStore.allRecipients;

    const docusignRecipientOptions = recipients.reduce((accumlator, recipient, index) => {
      // we only want recipients that are saved in the database
      if (
        recipient.id &&
        (recipient.envelope_template_recipient_type_id === DocuSignRecipientTypeId.Signer ||
          recipient.envelope_template_recipient_type_id === DocuSignRecipientTypeId.Agent)
      )
        accumlator.push({
          value: recipient,
          label:
            "Recipient " +
            (index + 1) +
            (this.props.recipientsStore.signingOrderStatus ? " | Sign Order " + recipient.routing_order : ""),
        });
      return accumlator;
    }, []);

    // Tab type Options
    // here docusignTabConstants is returned as object of objects
    const docusignTabConstants = this.props.constantsStore.allDocusignTabTypes();
    var convertedTabArray = [];
    // Converting an object of objects into an array of objects.
    for (let key in docusignTabConstants) {
      convertedTabArray.push(Object.assign(docusignTabConstants[key], { name: key }));
    }
    const tabTypeOptions = convertedTabArray.map((options) => {
      return {
        value: options.id,
        label: options.display_name,
      };
    });

    this.state = {
      docusignRecipientOptions,
      tabTypeOptions,
      selectedDocusignRecipientOption: docusignRecipientOptions[0],
      selectedDocusignTabOption: tabTypeOptions[0],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  handleSubmit() {
    const { selectedDocusignRecipientOption, selectedDocusignTabOption } = this.state;

    const documentId = this.props.recipientsStore.documentId;
    const anchor =
      "\\" + documentId + "-" + selectedDocusignRecipientOption.value.id + "\\" + selectedDocusignTabOption.label;

    const value = selectedDocusignRecipientOption.value.id; // this is the recipient id
    // Creating the tab in the backend
    this.props.recipientsStore.createTab({
      anchor: anchor,
      envelope_template_tab_type_id: selectedDocusignTabOption.value,
      envelope_template_recipient_id: selectedDocusignRecipientOption.value.id,
    });

    this.props.onSubmit({
      recipientId: value,
      text: anchor,
    });

    this.props.closeSideBarHandler();
  }

  handleChangeRecipient = (selectedDocusignRecipientOption) => {
    this.setState({ selectedDocusignRecipientOption });
  };

  handleChangeTabType = (selectedDocusignTabOption) => {
    this.setState({ selectedDocusignTabOption });
  };

  render() {
    const { docusignRecipientOptions, tabTypeOptions, selectedDocusignTabOption, selectedDocusignRecipientOption } =
      this.state;

    const noRecipientsCta = (
      <div className="no-recipient-div">
        You have to add recipients before adding tags to your document.
        <br></br>
        <br></br>
        Click on the “Recipients” tab to get started.
      </div>
    );

    return (
      <div className="insert-answer">
        <h2 className="tab-header">Insert DocuSign tags</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSubmit();
          }}
        >
          {docusignRecipientOptions.length !== 0 && (
            <>
              <label className="attachment-label">DocuSign recipient</label>
              <Select
                value={selectedDocusignRecipientOption}
                styles={resetSelectStyles}
                components={{ DropdownIndicator }}
                classNamePrefix="react-select"
                onChange={this.handleChangeRecipient}
                options={docusignRecipientOptions}
                data-test-id="select-variable"
              />
              <label className="attachment-label">Tab type</label>
              <Select
                value={selectedDocusignTabOption}
                styles={resetSelectStyles}
                components={{ DropdownIndicator }}
                classNamePrefix="react-select"
                onChange={this.handleChangeTabType}
                options={tabTypeOptions}
                data-test-id="select-variable"
              />
              <div className="actions-wrapper">
                <JoButton clickHandler={this.handleSubmit} type="submit" testId="confirm-insert-variable">
                  <JoIcon title="insert answer" icon="CheckCircle1" spacing="right" />
                  Insert tag
                </JoButton>
                <JoButton clickHandler={this.props.closeSideBarHandler} variant="outline" testId="cancel-sidebar">
                  Cancel
                </JoButton>
              </div>
            </>
          )}

          {!docusignRecipientOptions.length && (
            <div className="no-recipient-wrapper">
              <CtaMessageBar
                variant="info"
                children={noRecipientsCta}
                testId="docusign-no-recipient"
                noMaxHeight={true}
              ></CtaMessageBar>
            </div>
          )}
        </form>
      </div>
    );
  }
}

InsertDocusignTag.propTypes = {
  constantsStore: PropTypes.object,
  recipientsStore: PropTypes.object,
  closeSideBarHandler: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default inject("constantsStore", "recipientsStore")(observer(InsertDocusignTag));
