import React from "react";
import PropTypes from "prop-types";
import "./JoButton.scss";
import classNames from "classnames";

class JoButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testId: props.testId,
    };
    this.classes = classNames({
      "jo-button": true,
      "is-blue": this.props.variant === "blue",
      "is-gradient": this.props.variant === "gradient",
      "is-outline": this.props.variant === "outline",
      "is-selected": this.props.variant === "selected",
      "is-flush": this.props.flush === "all",
      "is-flush-right": this.props.flush === "right",
      "is-flush-bottom": this.props.flush === "bottom",
      "is-light": this.props.variant === "light",
      "is-slim": this.props.size === "slim",
    });
  }

  clickHandler(e) {
    if (this.props.buttonType === "Editor") {
      e.preventDefault();
    }
    this.props.clickHandler();
  }

  render() {
    // by explicitly collecting our props up we can preserve any additional events applied to the parent
    // eg mouse events for popovers
    const { children, ...props } = this.props;

    // with our remaining props stored in "props" we need to remove ones used for configuration
    // that would be invalid if applied to a DOM element, so we can preserve any that are valid, and desired
    delete props["clickHandler"];
    delete props["buttonType"];
    delete props["flush"];
    delete props["varant"];
    delete props["size"];
    delete props["testId"];

    return (
      <button
        type={props.type}
        className={this.classes}
        onMouseDown={(e) => this.clickHandler(e)}
        data-test-id={this.state.testId}
        {...props}
      >
        {children}
      </button>
    );
  }
}

JoButton.propTypes = {
  buttonType: PropTypes.string,
  children: PropTypes.node,
  clickHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  flush: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
  testId: PropTypes.string,
};

/* buttonType setting this prop to "Editor" will prevent focus leaving editor when button is clicked */
JoButton.defaultProps = {
  buttonType: "Standard",
  type: "button",
  size: "normal",
};

export default JoButton;
