import authStore from "../../store/authStore";
import { navigate } from "hookrouter";

const requestInterceptor = config => {
  if (authStore.token) {
    config.headers["Authorization"] = `Bearer ${authStore.token}`;
  }

  return config;
};

const responseInterceptor = response => {
  return response;
};

const authErrorInterceptor = error => {
  console.log(error);
  // No network Error
  if (error.request.readyState === 4 && !error.response) {
    return Promise.reject(error);
    // Authentication Error
  } else if (
    (error.response.status === 401 && error.request.responseURL && error.request.responseURL.indexOf("auth") === -1) ||
    !error.request.responseURL
  ) {
    authStore.setToken(null);
    navigate("/error/401", true);
  }

  return Promise.reject(error);
};

export { requestInterceptor, responseInterceptor, authErrorInterceptor };
