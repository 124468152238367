import React from "react";

const SvgTaskListDownload = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M20.5 2h-5a.241.241 0 01-.208-.121 3.827 3.827 0 00-6.588 0A.241.241 0 018.5 2h-5A1.5 1.5 0 002 3.5v19A1.5 1.5 0 003.5 24h17a1.5 1.5 0 001.5-1.5v-19A1.5 1.5 0 0020.5 2zM19 16.26a2.5 2.5 0 01-.6 1.626l-1.919 2.24a2.5 2.5 0 01-1.9.873H5.5a.5.5 0 01-.5-.5V5.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5z" />
    <path d="M15.5 13.5h-2a.25.25 0 01-.25-.25V9.5a1.25 1.25 0 00-2.5 0v3.75a.25.25 0 01-.25.25h-2a.5.5 0 00-.354.854l3.5 3.5a.5.5 0 00.325.145H12a.544.544 0 00.057 0 .493.493 0 00.265-.115.231.231 0 00.032-.03l3.5-3.5a.5.5 0 00-.354-.854z" />
  </svg>
);

export default SvgTaskListDownload;
