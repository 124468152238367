import { gaEvent } from "../../../../utils/analytics"

export default function blockStyleCopy() {
  const focusedBlocks = this.state.focusedBlocks;
  if (!focusedBlocks || focusedBlocks.length > 1) {
    return true;
  }
  const clipboard = this.state.clipboard;

  if (clipboard.key) {
    this.setState({ clipboard: { ...clipboard, key: null } });
    document.getElementById("container-root").classList.remove("painter-selected");
  } else {
    clipboard.key = focusedBlocks[0]
    this.setState({ clipboard: clipboard });

    document.getElementById("container-root").classList.add("painter-selected");
  }

  // Send a request to Google Analystics to register this event
  gaEvent({
    category: "Clipboard",
    action: "Copy Block Style",
    label: makeClipboardReport(clipboard),
  });
}

function makeClipboardReport(clipboard) {
  return "Source block is " + clipboard.key;
}