import { inject, observer } from "mobx-react";
import Collapsible from "react-collapsible";
import DatePicker, { registerLocale } from "react-datepicker";
import debounce from "lodash.debounce";
import enAU from "date-fns/locale/en-AU";
import JoLink from "../common/JoLink";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";
import "./Rule.scss";

import { Color, resetSelectStyles, VariableType, VariableSource, InputType, Calendricals } from "../../utils/constants";
import DropdownIndicator from "../common/DropdownIndicator";

import {
  formatCurrency,
  formatDate,
  formatNumber,
  formatPercentage,
  getCurrency,
  getDateFormat,
} from "../../utils/i18nUtils";
import { clog } from "../../utils/helpers";
import { getDateMiddayInTimezone } from "@joseflegal/ui-lib/src/utils/helpers";

class Rule extends Component {
  constructor(props) {
    registerLocale("en-AU", enAU);

    // clog("Calling >>> constructor()", Color.green);
    super(props);
    // get the variable we are attaching this to initially
    const variable = this.props.variables.find((item) => item.id === this.props.rule.variable_id);
    const variableType = this.props.constantsStore.variableTypeById(variable?.variable_type_id);
    const isDuration = variableType?.shortname === VariableType.Duration;
    const { unit, value } = isDuration ? JSON.parse(this.props.rule.answer.value) : { unit: null, number: null };
    // find initial variable options
    const operableVariables = this.props.variables.filter((variable) => {
      const operations = this.props.constantsStore.operationsByVariableTypeId(variable.variable_type_id);
      return operations && operations.length > 0;
    });
    const initialVariableOptions = operableVariables.map((item) => ({ value: item.id, label: item.name }));

    const operations = variable?.variable_type_id
      ? this.props.constantsStore.operationsByVariableTypeId(variable?.variable_type_id)
      : [];

    // sets initial state for this rule
    // for new rules we are selecting the first variable, with it's first operation and first possible answer
    this.state = {
      variable,
      variableOptions: initialVariableOptions,
      selectedVariableId: this.props.rule.variable_id,
      answer: this.props.rule.answer,
      answerDurationNumber: isDuration ? value : null,
      answerDurationUnit: isDuration ? unit : null,
      operations: operations.map((item) => ({ value: item.id, label: item.name })),
      selectedOperationId: this.props.rule.operation.id,
      answerFocused: false,
    };

    // handle our event bindings
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChangeDurationInput = this.handleChangeDurationInput.bind(this);
    this.handleChangeDurationUnit = this.handleChangeDurationUnit.bind(this);
    this.deleteRule = this.deleteRule.bind(this);
  }

  getDisplayAnswer(variable, answer) {
    const inputType = this.props.constantsStore.inputTypeById(variable.input_type_id);
    const variableType = this.props.constantsStore.variableTypeById(variable.variable_type_id);
    const variableSource = this.props.constantsStore.variableSourceById(variable.variable_source_id);

    if (
      [InputType.McMultiple, InputType.McSingle, InputType.McSingleScoring].includes(inputType.shortname) &&
      variableSource.shortname !== VariableSource.API_Request
    ) {
      if (answer.mc_option_ids && answer.mc_option_ids.length > 0) {
        const id = answer.mc_option_ids[0];
        const option = this.props.mcOptionsStore.optionById(id).value;
        return this.displayValue(option, variableType.shortname);
      }
    } else if (variableType?.shortname === VariableType.Duration) {
      const pluralisation = this.state.answerDurationNumber > 1 ? "s" : "";
      return `${this.state.answerDurationNumber} ${this.state.answerDurationUnit}${pluralisation}`;
    } else {
      return this.displayValue(answer.value, variableType.shortname);
    }
  }

  handleChangeDate(date) {
    /**
     * [cu-29aby58]
     * We should convert the date of `local timezone` to the midday of `account.timezone`.
     * For example, if the date is `Apr. 29 Fri 12:00 PM GMT-4` when `local timezone` is `GMT-4`,
     * then the UTC date string `04-29T02:00:00.000Z` which is actually `04-29T12:00:00+10:00` should be generated
     * when `account.timezone` is `Australia/Melbourne`.
     */
    const parsedDate = getDateMiddayInTimezone({
      date,
      isAccountTimezone: false, // `date` is `local timezone` so always NOT `account.timezone`.
      targetTimezone: this.props.i18nStore.valuesById("timezone"),
    });

    this.setState({
      answer: {
        value: parsedDate,
        mc_option_ids: [],
      },
    });

    this.limitedUpdate();
  }

  // input change in the rules sidebar text field
  handleChangeInput(e) {
    // so that this doesnt fire too often, we will call our debounce function
    this.limitedUpdate();
    this.setState({
      answer: { value: e.target.value, mc_option_ids: [] },
    });
  }

  handleChangeDurationInput(e) {
    this.limitedUpdateDuration();
    this.setState({
      answerDurationNumber: e.target.value,
    });
  }

  handleChangeDurationUnit(e) {
    this.limitedUpdateDuration();
    this.setState({
      answerDurationUnit: e.value,
    });
  }

  // debounceable function to call on for input text changes in the rules sidebar
  limitedUpdate = debounce(() => {
    if (this.state.answer?.value) {
      this.updateRule({
        answer: this.state.answer,
      });
    }
  }, 500);

  limitedUpdateDuration = debounce(() => {
    this.updateRule({
      answer: {
        value: JSON.stringify({
          value: this.state.answerDurationNumber,
          unit: this.state.answerDurationUnit,
        }),
        mc_option_ids: [],
      },
    });
  }, 500);

  // changing selected variable in rules sidebar
  handleVariableChange = (selectedOption) => {
    // store a reference to our selected variable
    const variable = this.props.variables.find((item) => item.id === selectedOption.value);
    const variableType = this.props.constantsStore.variableTypeById(variable.variable_type_id);
    const parsedDate = getDateMiddayInTimezone({
      date: new Date(),
      targetTimezone: this.props.i18nStore.valuesById("timezone"),
    });

    // if we have changed to multiple choice variable we need to refresh the answer to equal one of its options
    const answer = {
      mc_option_ids: variable.mc_option_ids && variable.mc_option_ids.length > 0 ? [variable.mc_option_ids[0]] : [],
      value:
        variableType.shortname === VariableType.Date
          ? parsedDate
          : variableType.shortname === VariableType.Number ||
              variableType.shortname === VariableType.Currency ||
              variableType.shortname === VariableType.Percentage
            ? "1"
            : "*edit this text*",
    };

    if (variableType.shortname === VariableType.Date) {
      answer.value = parsedDate;
    } else if (
      variableType.shortname === VariableType.Number ||
      variableType.shortname === VariableType.Currency ||
      variableType.shortname === VariableType.Percentage
    ) {
      answer.value = "1";
    } else if (variableType.shortname === VariableType.Duration) {
      answer.value = JSON.stringify({
        value: 1,
        unit: "day",
      });
    }

    const operations = this.props.constantsStore.operationsByVariableTypeId(variable.variable_type_id);

    // set up our new list of possible operations from the matched variable
    const newOperations = operations.map((item) => ({ value: item.id, label: item.name }));

    // set state for our variable, and then trigger an updateRule API request
    // we need to update all 3 selections because the variable changes the other 2
    this.updateRule({
      variable,
      operations: newOperations,
      selectedVariableId: selectedOption.value,
      selectedOperationId: newOperations.length > 0 ? newOperations[0].value : null,
      answer,
    });
  };

  // changing selected operation in rules sidebar
  handleOperationChange = (selectedOption) => {
    clog("Calling >>> handleOperationChange()", Color.green);

    this.updateRule({
      selectedOperationId: selectedOption.value,
    });
  };

  // changing selected answer in rules sidebar
  handleChangeAnswer = (selectedOption) => {
    const inputType = this.props.constantsStore.inputTypeById(this.state.variable.input_type_id);
    if ([InputType.McMultiple, InputType.McSingle, InputType.McSingleScoring].includes(inputType.shortname)) {
      this.updateRule({
        answer: { value: null, mc_option_ids: [selectedOption.value] },
      });
    } else {
      this.updateRule({
        answer: { value: selectedOption.label, mc_option_ids: [] },
      });
    }
  };

  // update data in the API (and on success: apply to app state)
  updateRule = (selectedOptions) => {
    // clog("Calling >>> updateRule()", Color.green);

    // for each of variableId, operationId and answer, we'll check if we've been asked to update it
    // an update request comes as a key on the selectedOptions object
    // if the key isn't present, we will update from the existing item in the app state instead
    const variableToUpdate = selectedOptions.variable || this.state.variable;
    const variableIdToUpdate = selectedOptions.selectedVariableId || this.state.selectedVariableId;
    const operationsToUpdate = selectedOptions.operations || this.state.operations;
    const operationIdToUpdate = selectedOptions.selectedOperationId || this.state.selectedOperationId;
    const answerToUpdate = selectedOptions.answer || this.state.answer;

    // We'll update state and then send an API request to sync up
    this.setState(
      {
        variable: variableToUpdate,
        selectedVariableId: variableIdToUpdate,
        operations: operationsToUpdate,
        selectedOperationId: operationIdToUpdate,
        answer: answerToUpdate,
      },
      () => {
        // this is our actual request to the API to update these records from our newly updated app state
        this.props.rulesStore
          .updateOne({
            updatedRule: {
              id: this.props.rule.id,
              variable_id: this.state.selectedVariableId,
              operation_id: this.state.selectedOperationId,
              answer: this.state.answer,
            },
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };

  // delete a rule from the API
  deleteRule() {
    this.props.rulesStore.delete({
      deletedRule: {
        id: this.props.rule.id,
        rule_group_id: this.props.rule.rule_group_id,
      },
    });
  }

  isFormatted(format) {
    return (
      format &&
      (format === VariableType.Currency ||
        format === VariableType.Percentage ||
        format === VariableType.Date ||
        format === VariableType.Number)
    );
  }

  displayValue(value, format) {
    if (value) {
      if (format === VariableType.Date) {
        return formatDate(
          value,
          this.props.i18nStore.valuesById("date_formats"),
          this.props.i18nStore.valuesById("date_format_id"),
          this.props.i18nStore.valuesById("timezone")
        );
      }

      if (format === VariableType.Number) {
        return formatNumber(value);
      }

      if (format === VariableType.Currency) {
        return formatCurrency(
          value,
          this.props.i18nStore.valuesById("currencies"),
          this.props.i18nStore.valuesById("currency_id")
        );
      }

      if (format === VariableType.Percentage) {
        return formatPercentage(value);
      }
    }

    return value;
  }

  // render function for our answer select or text input depending on variable type
  renderAnswerOption(variable, answer) {
    const inputType = this.props.constantsStore.inputTypeById(variable.input_type_id);
    const variableType = this.props.constantsStore.variableTypeById(variable.variable_type_id);
    const variableSource = this.props.constantsStore.variableSourceById(variable.variable_source_id);
    const { isDisabled } = this.props;

    // render a react select if input is predefined or multiple choice
    if (
      [InputType.McMultiple, InputType.McSingle, InputType.McSingleScoring].includes(inputType.shortname) &&
      variableSource.shortname !== VariableSource.API_Request
    ) {
      // break out our options from a string
      const answerArray = variable.mc_option_ids;
      const answerOptions = answerArray.map((id) => ({
        value: id,
        label: this.props.mcOptionsStore.optionById(id).value,
      }));

      let parsedAnswerOptions = answerOptions;

      // Format dropdown options
      if (this.isFormatted(variableType.shortname)) {
        parsedAnswerOptions = parsedAnswerOptions.map((option) => ({
          ...option,
          label: this.displayValue(option.label, variableType.shortname),
        }));
      }

      // find the matched option
      const answerMatched = answerOptions.find((item) => {
        if (answer.mc_option_ids && answer.mc_option_ids.length > 0) {
          const id = answer.mc_option_ids[0];
          const option = this.props.mcOptionsStore.optionById(id).value;
          return item.label === option;
        }
        return false;
      });

      // display formatted labels if applicable
      let value = answerMatched;
      if (this.isFormatted(variableType.shortname)) {
        value = parsedAnswerOptions.find((answer) => {
          return answer.value === answerMatched.value;
        });
      }

      return (
        <Select
          id="textrule-response"
          name="textrule-response"
          className="react-select-container"
          classNamePrefix="react-select"
          defaultValue={answerMatched}
          value={value}
          options={parsedAnswerOptions}
          onChange={this.handleChangeAnswer}
          styles={resetSelectStyles}
          components={{ DropdownIndicator }}
          isDisabled={isDisabled}
        ></Select>
      );
    } else if (variableType?.shortname === VariableType.Duration) {
      const calendricalsOptions = Calendricals.map((item) => ({
        value: item,
        label: `${item}(s)`,
      }));
      const answerMatched = calendricalsOptions.find((item) => item.value === this.state.answerDurationUnit);
      return (
        <div className="rule-answer__duration-wrapper">
          <input
            type="number"
            id="textrule-response-duration-input"
            name="textrule-response-duration-input"
            className="rule-answer__duration-input"
            value={this.state.answerDurationNumber}
            onChange={this.handleChangeDurationInput}
            disabled={isDisabled}
          />
          <Select
            id="textrule-response-duration-unit-select"
            name="textrule-response-duration-unit-select"
            className="react-select-container rule-answer__duration-unit-select"
            classNamePrefix="react-select"
            options={calendricalsOptions}
            defaultValue={answerMatched}
            value={answerMatched}
            onChange={this.handleChangeDurationUnit}
            styles={resetSelectStyles}
            components={{ DropdownIndicator }}
            isDisabled={isDisabled}
          ></Select>
        </div>
      );
    } else {
      // otherwise render a text input for a text variable

      const type =
        variableType.shortname === VariableType.Number ||
        variableType.shortname === VariableType.Currency ||
        variableType.shortname === VariableType.Percentage
          ? "number"
          : "text";

      return (
        <div
          className={`rule-answer ${this.state.answerFocused ? "rule-answer--focused" : ""}`}
          onFocus={() => this.setState({ answerFocused: true })}
          onBlur={() => this.setState({ answerFocused: false })}
          tabIndex="0"
        >
          {this.isFormatted(variableType.shortname) && (
            <span className="rule-answer__display">
              {this.displayValue(this.state.answer.value, variableType.shortname)}
            </span>
          )}
          {variableType.shortname === VariableType.Date && (
            <DatePicker
              /**
               * [cu-29aby58]
               * `localValue` is always formatted with `account.timezone`.
               * We should populate the midday of `local timezone` into the datePicker.
               * For example, if `localValue` is `04-29T12:00:00+10:00` when `account.timezone` is `Australia/Melbourne`,
               * then `04-29T12:00:00-04:00` should be populated when `local timezone` is `GMT-4`.
               */
              selected={
                new Date(
                  getDateMiddayInTimezone({
                    date: this.state.answer.value,
                    isAccountTimezone: true, //`localValue` is always formatted with `account.timezone`.
                    sourceTimezone: this.props.i18nStore.valuesById("timezone"),
                    targetTimezone: new Date().getTimezoneOffset(),
                  })
                )
              }
              onChange={(date) => this.handleChangeDate(date)}
              locale={
                getCurrency(
                  this.props.i18nStore.valuesById("currencies"),
                  this.props.i18nStore.valuesById("currency_id")
                ).locale
              }
              dateFormat={
                getDateFormat(
                  this.props.i18nStore.valuesById("date_formats"),
                  this.props.i18nStore.valuesById("date_format_id")
                ).format
              }
              minDate={new Date(1900, 0, 1)}
            />
          )}
          {variableType.shortname !== VariableType.Date && (
            <input
              type={type}
              id="textrule-response"
              name="textrule-response"
              className={`rule-answer__input ${
                this.isFormatted(variableType.shortname) ? "rule-answer__input--formatted" : ""
              }`}
              value={this.state.answer.value}
              onChange={this.handleChangeInput}
              {...(type === "number" ? { pattern: "[0-9]*" } : {})}
              disabled={isDisabled}
            />
          )}
        </div>
      );
    }
  }

  renderRuleBody({ matchedOperation, matchedVariable }) {
    // retrieve from props and state
    const { isDisabled } = this.props;
    const { variable, answer, variableOptions, operations } = this.state;

    return (
      <>
        <div className="input-field">
          <div className="input-field__label-wrapper">
            <label className="input-field__label" htmlFor="textrule-message">
              Variable
            </label>
          </div>

          <Select
            id="textrule-message"
            name="textrule-message"
            data-test-id="textrule-message"
            className="react-select-container"
            classNamePrefix="react-select"
            defaultValue={matchedVariable}
            placeholder="Search for a variable"
            options={variableOptions}
            onChange={this.handleVariableChange}
            styles={resetSelectStyles}
            components={{ DropdownIndicator }}
            isSearchable={true}
            isDisabled={isDisabled}
          ></Select>
        </div>

        <div className="input-field">
          <div className="input-field__label-wrapper">
            <label className="input-field__label" htmlFor="textrule-operation">
              Operation
            </label>
          </div>

          <Select
            id="textrule-operation"
            name="textrule-operation"
            data-test-id="textrule-operation"
            className="react-select-container react-select-container--no-search"
            classNamePrefix="react-select"
            defaultValue={matchedOperation.label}
            value={matchedOperation}
            options={operations}
            onChange={this.handleOperationChange}
            styles={resetSelectStyles}
            components={{ DropdownIndicator }}
            isSearchable={false}
            isDisabled={isDisabled}
          ></Select>
        </div>

        <div className="input-field">
          <div className="input-field__label-wrapper">
            <label
              className="input-field__label"
              id="textrule-response"
              name="textrule-response"
              data-test-id="textrule-response"
            >
              Value
            </label>
          </div>
          {this.renderAnswerOption(variable, answer)}
        </div>
      </>
    );
  }

  // main render function of component
  render() {
    // retrieve from props and state
    const { operation, isSelected, clickHandler, rule, isDisabled } = this.props;
    const { variable, answer, selectedVariableId, variableOptions, operations, selectedOperationId } = this.state;

    // get our matched variable to feed in as the default value of our select menu
    const matchedVariable = variableOptions.filter((item) => {
      return item.value === selectedVariableId;
    });

    // get our matched operation to feed in as the default value of our select menu
    const matchedOperation = operations.filter((item) => {
      return item.value === selectedOperationId;
    });

    // store our desired operation text
    const operationText = operation === "all" ? "and" : "or";
    // this is the label text at the top of the collapsible
    const collapsibleLabel = variable
      ? matchedVariable[0]?.label + " " + matchedOperation[0]?.label + " " + this.getDisplayAnswer(variable, answer)
      : "Variable was deleted";
    const collapsibleElement = (
      <span
        className={`Collapsible__trigger-element ${!variable ? " Collapsible__trigger-element--missing" : ""}`}
        data-operation={operationText}
      >
        {collapsibleLabel}{" "}
        <JoLink
          iconLeft="Bin"
          disabled={isDisabled}
          title="Delete rule"
          testId="delete-rule"
          clickHandler={this.deleteRule}
        ></JoLink>
      </span>
    );

    const operationLabel = () => <span className="Collapsible__operation-label" data-operation={operationText} />;
    const triggerClickProp = isSelected ? null : rule.id;

    return (
      <Collapsible
        trigger={collapsibleElement}
        triggerSibling={operationLabel}
        overflowWhenOpen="visible"
        transitionTime={200}
        easing="ease-out"
        open={isSelected}
        disabled={!variable}
        handleTriggerClick={() => variable && clickHandler(triggerClickProp)}
      >
        {variable && this.renderRuleBody({ matchedOperation, matchedVariable })}
      </Collapsible>
    );
  }
}

Rule.propTypes = {
  isDisabled: PropTypes.bool,
  rule: PropTypes.object.isRequired,
  operation: PropTypes.string,
  variables: PropTypes.array,
  isSelected: PropTypes.bool,
  clickHandler: PropTypes.func,
  rulesStore: PropTypes.object,
  constantsStore: PropTypes.object,
  mcOptionsStore: PropTypes.object,
  i18nStore: PropTypes.object,
};

export default inject("rulesStore", "constantsStore", "mcOptionsStore", "i18nStore")(observer(Rule));
