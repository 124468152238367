import getState from "../editor/getState";
import { EditorState, SelectionState } from "draft-js";

// updates selectionState to include entire contents of block (cmd A)
export default function handleSelectAll() {
  const editorState = this.state.editorState;
  const { contentState } = getState(editorState);
  if (!this.state.focusedBlocks) {
    return;
  }
  const blockByKey = contentState.getBlockForKey(this.state.focusedBlocks[0]);
  // create new selectionState from start to end of block
  const newSelectionState = new SelectionState({
    anchorKey: blockByKey.key,
    anchorOffset: 0,
    focusKey: blockByKey.key,
    focusOffset: blockByKey.text.length,
    hasFocus: true,
    isBackward: false,
  });
  // create newEditorState and feed to updateEditorState
  const newEditorState = EditorState.forceSelection(editorState, newSelectionState);
  this.updateEditorState({
    newEditorState,
  });
}
