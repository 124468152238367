import React from "react";
import PropTypes from "prop-types";
import { EntityTypes } from "../../../utils/constants";
import JoIcon from "../../common/JoIcon";
import { inject, observer } from "mobx-react";

/*  Answer Entity strategies and components */

// find answer entities in a content block
export function findDocusignTabEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === EntityTypes.DOCUSIGN_TAB;
  }, callback);
}

export const DocusignTabComponent = inject("recipientsStore")(
  observer((props) => {
    const handleClick = () => {
      props.focusDocusignTab(props.blockKey, props.start);
    };
    const entityData = props.contentState.getEntity(props.entityKey).get("data");

    const allRecipients = props.recipientsStore.allRecipients;

    // find the recipient by id
    const recipient = allRecipients.find((recipient) => recipient.id === entityData.recipientId);

    // get index of recipient by id
    // To preserve styles on ansers we need to detect the styles on the first child and apply them to the parent
    const styleSet = props.children[0].props.styleSet.toJS();
    const styleObject = createStylesFromStyleSet(styleSet);

    if (recipient) {
      const recipientIndex = allRecipients.indexOf(recipient);
      return (
        // this span is hidden and contains the unique identifier for the DS entity
        <span className="docusign-tab-answer-wrapper">
          <JoIcon icon="DocuSignIconSmall" title="docusign" spacing="none" />
          <span
            title={"Recipient " + (recipientIndex + 1) + " | " + entityData.text.split("\\")[2]}
            className="docusign-tab-answer"
            contentEditable={true}
            readOnly
            onClick={handleClick}
            suppressContentEditableWarning
            style={styleObject}
          >
            {props.children}
          </span>
        </span>
      );
    } else {
      return (
        <span className="docusign-tab-not-found-wrapper">
          <JoIcon icon="AlertCircle" title="Not found" />
          <span
            title="DocuSign Recipient removed"
            className="docusign-tab-removed"
            contentEditable={true}
            readOnly
            onClick={handleClick}
            suppressContentEditableWarning
            style={styleObject}
          >
            {props.children}
          </span>
        </span>
      );
    }
  })
);

DocusignTabComponent.propTypes = {
  children: PropTypes.node,
  blockKey: PropTypes.string,
  start: PropTypes.number,
  entityKey: PropTypes.string,
  focusDocusignTab: PropTypes.func,
  contentState: PropTypes.shape({
    getEntity: PropTypes.func,
  }),
};

function createStylesFromStyleSet(styleSet) {
  let styleObject = {};

  styleSet.forEach((styleKeyword) => {
    switch (styleKeyword) {
      case "BOLD":
        styleObject.fontWeight = "bold";
        break;
      case "ITALIC":
        styleObject.fontStyle = "italic";
        break;
      case "UNDERLINE":
        styleObject.textDecoration = "underline";
        break;
      default:
        break;
    }
  });

  return styleObject;
}
