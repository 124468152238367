import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { components } from "react-select";
import JoIcon from "./JoIcon";
import "./DropdownIndicator.scss";

// dropdown indicator for react select
const DropdownIndicator = ({ ...props }) => {
  const classes = classNames({
    "react-select__dropdown-icon": true,
    "is-grey": props.variant === "grey",
  });

  return (
    <components.DropdownIndicator {...props}>
      <span className={classes}>
        <JoIcon icon="ArrowDown" />
      </span>
    </components.DropdownIndicator>
  );
};

DropdownIndicator.propTypes = {
  variant: PropTypes.string,
};

DropdownIndicator.defaultProps = {
  variant: "grey",
};

export default DropdownIndicator;
