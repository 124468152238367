import { getState } from "../editor";

// There a numerous situtation when we want to prevent user being able to apply inline-styles.
export default function getIsInlineStylesBtnDisabled(
  editorState,
  focusedBlocks
) {
  if (!focusedBlocks) {
    return true;
  }
  const { contentState } = getState(editorState, false, true);
  const blockByKey = focusedBlocks ? contentState.getBlockForKey(focusedBlocks[0]) : null;
  const isMultipleBlocks = focusedBlocks.length > 1;
  const blockIsFake = blockByKey.getIn(["data", "is_fake"]);
  const blockIsUnsupported = blockByKey.getIn(["data", "unsupported_content"]);
  return isMultipleBlocks || blockIsFake || blockIsUnsupported;
}
