import React from "react";
import PropTypes from "prop-types";
import "./JoTooltip.scss";
import ReactTooltip from "react-tooltip";
import { Links } from "../../utils/constants";

// Usage:
// Wrap the element you want to trigger it in this component.
// Under the hood it uses https://github.com/wwayne/react-tooltip

class JoTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    return (
      <ReactTooltip
        id={this.props.id}
        effect="solid"
        className="jo-tooltip"
        delayHide={500}
        type="light"
        place={this.props.position} // preferred position
        globalEventOff="click"
        getContent={(dataTip) => (
          <div className="body">
            {dataTip === "attachment" && (
              <div className="attachment">
                Images can be inserted as a link or inline, while documents will appear as a link or an appendix.
                <br />
                <a
                  href="https://support.joseflegal.com/hc/en-us/articles/360040771054#h_01FB89RMS60HPPWK3J4XTRX7HN"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
              </div>
            )}
            {dataTip !== "attachment" && (
              <>
                {dataTip}
                <br></br>
                {dataTip === "Unlock unsupported block" && (
                  <a href={Links.HelpUnlock} target="_blank" rel="noopener noreferrer">
                    More info →
                  </a>
                )}
              </>
            )}
          </div>
        )}
      ></ReactTooltip>
    );
  }
}

JoTooltip.propTypes = {
  id: PropTypes.string,
  position: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  content: PropTypes.string,
  disabled: PropTypes.bool,
};

JoTooltip.defaultProps = {
  disabled: false,
};

export default JoTooltip;
