/* eslint-disable eqeqeq */
import { BlockType } from "../../../../utils/constants";

// Will check supplied block for a textrule_id value equal to supplied textruleId
export default function getIsBlockTextrule(blockByKey, textruleId) {
  return blockByKey.getIn(["data", "textrule_id"]) === textruleId;
}

export function getIsRowTextrule(blockByKey) {
  return blockByKey && blockByKey.get("type") === BlockType.Table;
}

export function isShouldBlockLevel(focusedBlocks, contentState, selectionState) {
  const block = focusedBlocks ? contentState.getBlockForKey(focusedBlocks[0]) : null;
  let isEntireBlock = selectionState.isCollapsed();
  if (!isEntireBlock && block &&
    (
      block.type !== "Table" ||
      // we should not allow inline textrule for unsupported table blocks
      // but normal table cells will have inline textrule if selecting the whole text of cell
      (block.type === "Table" && block.getIn(["data", "unsupported_content"]))
    )
  ) {
    isEntireBlock =
      (
        (
          selectionState.anchorOffset == 0 &&
          // FocusOffset might be 0 if multiple selection.
          // Then it also needs block-level textrule.
          selectionState.focusOffset == 0
        ) ||
        (
          selectionState.anchorOffset == 0 &&
          (block.text && selectionState.focusOffset == block.text.length)
        ) ||
        (
          selectionState.focusOffset == 0 &&
          (block.text && selectionState.anchorOffset == block.text.length)
        )
      );
  }
  const isMultipleBlocks = focusedBlocks && focusedBlocks.length > 1;
  return isEntireBlock || isMultipleBlocks;
}