export default function blockStyleCopyIsBtnDisabled() {
  if (this.props.isDisabled) {
    return true;
  }

  const focusedBlocks = this.state.focusedBlocks;
  if (!focusedBlocks || focusedBlocks.length > 1) {
    return true;
  }
  return false;
}
