// validates that all selection is a valid table selection eg cells in same row or not including non table elements
export default function validateTableSelection(focusedBlocks, currentContent) {
  let row;
  return focusedBlocks.reduce((acc, next, i) => {
    const blockData = currentContent.getBlockForKey(next);
    if (i === 0) {
      row = blockData.data.get("row");
    } else if (blockData.data.get("row") !== row) {
      acc = false;
    }

    if (blockData.type !== "Table") {
      acc = false;
    }
    return acc;
  }, true);
}