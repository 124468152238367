// checks if there is at least one active block that isn't deleted
export default function hasActiveBlock(editorState) {
  // get our blocks
  let contentState = editorState.getCurrentContent();
  let blocks = contentState.getBlocksAsArray();
  let activeBlockExists = false;
  for (let i = 0; i < blocks.length; i++) {
    if (!blocks[i].getIn(["data", "is_deleted"])) {
      activeBlockExists = true;
      break;
    }
  }

  return activeBlockExists;
}
