import React, { Component } from "react";
import PropTypes from "prop-types";
import "./CtaMessageBar.scss";
import classNames from "classnames";

class CtaMessageBar extends Component {
  constructor(props) {
    super(props);
    this.classes = classNames({
      "cta-message-bar": true,
      "is-primary": this.props.variant === "primary",
      "is-info": this.props.variant === "info",
      "is-warning": this.props.variant === "warning",
      "is-danger": this.props.variant === "danger",
      "no-max-height": this.props.noMaxHeight,
    });
  }

  render() {
    const { children } = this.props;
    return (
      <div className={this.classes} data-test-id={this.props.testId}>
        <span>{children}</span>
      </div>
    );
  }
}

CtaMessageBar.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node,
  content: PropTypes.string,
  testId: PropTypes.string,
};

/* buttonType setting this prop to "Editor" will prevent focus leaving editor when button is clicked */
CtaMessageBar.defaultProps = {
  variant: "primary",
  noMaxHeight: false,
};

export default CtaMessageBar;
