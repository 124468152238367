import React from "react";

const SvgAppWindowCloud = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M7.81 17.251h8.2a2.857 2.857 0 002.544-2.732 2.774 2.774 0 00-2.78-2.728.249.249 0 01-.216-.131A3.972 3.972 0 0012.05 9.6a3.906 3.906 0 00-3.924 3.371.219.219 0 01-.239.191A2.093 2.093 0 005.55 15.2c0 2.083 2.26 2.051 2.26 2.051z" />
    <path d="M24 4.751a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h18a3 3 0 003-3zm-14.346-1a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.562.091a1 1 0 01.908-.591.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.987.987 0 01.092-.409zM22 19.251a1 1 0 01-1 1H3a1 1 0 01-1-1V7a.25.25 0 01.25-.25h19.5A.25.25 0 0122 7z" />
  </svg>
);

export default SvgAppWindowCloud;
