import React, { Component } from "react";
import PropTypes from "prop-types";
import { EditorBlock } from "draft-js";
import styled from "styled-components";

import { twipToPx, pxToPt } from "../../utils/helpers";
import { defaultStyles, capsStyles } from "../../utils/constants";

function getListItemMark(listType, format, depth) {
  switch (listType) {
    case "Decimal":
      listType = "decimal";
      break;
    case "LowerLetter":
      listType = "lower-alpha";
      break;
    case "UpperLetter":
      listType = "upper-alpha";
      break;
    case "LowerRoman":
      listType = "lower-roman";
      break;
    case "UpperRoman":
      listType = "upper-roman";
      break;
    default:
      break;
  }
  var reg = new RegExp(/%[0-9]*/g);
  var index = -1;
  var arr = format.match(reg);
  if (arr === null) {
    return null;
  }
  var len = arr.length;
  var start_append = false;
  var end_append = false;
  var depth_start = depth - len + 1;
  var res = format.replace(reg, function (match, offset) {
    var rep = "";
    if (listType === "None") {
      // Sometimes document includes listItem without intention of numbering.
      // Then we should only remove `%1` in listItem format string.
      start_append = true;
      end_append = true;
      return rep;
    }
    index++;
    var level = index + depth_start;
    if (index === 0) {
      if (offset !== 0) {
        rep += '" ';
        start_append = true;
      }
      rep += "counter(level" + level + ", " + listType + ")";
      if (index < len - 1) {
        rep += ' "';
      }
      if (index === len - 1 && offset + match.length < format.length) {
        rep += ' "';
        end_append = true;
      }
    } else if (index < len - 1) {
      rep = '" counter(level' + level + ", " + listType + ') "';
    } else if (index === len - 1) {
      rep = '" counter(level' + level + ", " + listType + ")";
      if (offset + match.length < format.length) {
        rep += ' "';
        end_append = true;
      }
    }
    return rep;
  });
  if (start_append) {
    res = '"' + res;
  }
  if (end_append) {
    res = res + '"';
  }
  return res;
}

class ListItem extends Component {
  constructor(props) {
    super(props);

    this.htmlEl = document.getElementsByTagName("html")[0];
  }

  shouldComponentUpdate(nextProps) {
    return this.props.block !== nextProps.block;
  }

  render() {
    const { block } = this.props;

    const depth = block.depth;
    const listItem = block.getIn(['data', 'listItem']);
    const listType = listItem.listType;
    let format = listItem.format;
    if (format === null) {
      // some docx files might not define the format for the list paragraph
      format = "";
    }

    // we use `level0` CSS counter name.
    var level = "level" + depth;

    // we get the content string of `counter(level0) "." counter(level1)` for the format `%1.%2`
    var prefixContent = getListItemMark(listType, format, depth);

    // if the numbering format doesn't include the ordered format like `%3`, it's the unordered list.
    let unordered = false;
    if (prefixContent === null) {
      unordered = true;
    }

    // to calculate our rem output value, we first we need to retrieve the current root font size from the DOM
    // will resolve to either 12 or 14 with our current design system, depending on the responsive breakpoint
    // (narrow screens have a smaller root font size on the html element)
    const rootFontSize = pxToPt(parseInt(window.getComputedStyle(this.htmlEl).fontSize));
    const blockFontSize = block.getIn(['data', 'font_size']);
    const fontSize =
      blockFontSize !== undefined && blockFontSize !== null && blockFontSize !== 0
        ? blockFontSize / rootFontSize : 1;

    const blockFontFamily = block.getIn(['data', 'font_family']);
    const fontFamily =
      blockFontFamily !== undefined && blockFontFamily !== null && blockFontFamily !== ""
        ? blockFontFamily : defaultStyles.fontFamily;

    const blockCaps = block.getIn(['data', 'caps']);
    const transStyle = blockCaps === capsStyles.UPPERCASE ? "uppercase" : "initial";

    const numberingFontSize = listItem.font_size;
    const beforeFontSize =
      numberingFontSize !== undefined && numberingFontSize !== null && numberingFontSize !== 0
        ? numberingFontSize / rootFontSize : fontSize;

    const numberingFontFamily = listItem.font_family;
    const beforeFontFamily =
      numberingFontFamily !== undefined && numberingFontFamily !== null && numberingFontFamily !== ""
        ? numberingFontFamily : fontFamily;

    let beforeFontWeight = "normal";
    let beforeFontStyle = "normal";
    if (listItem.styles) {
      listItem.styles.forEach(s => {
        if (s === "BOLD") {
          beforeFontWeight = "bold";
        }
        if (s === "ITALIC") {
          beforeFontStyle = "italic";
        }
      });
    }

    // calculate the indentation for the list and the numbering prefix
    var itemLeft = 0;
    var itemRight = 0;
    // indents are too big by default, we need to reduce them by a consistent value
    // TODO there might be a better way to calculate a smaller indent
    const reduceTo = 0.6;

    // our indent width comes out to this value
    // TODO handle this without the magic number
    // This is just the number needed to make the bullets line up with the next level indent
    // note this gets divided by our root font size to prep them for rem unit output
    let beforeWidth = 44 / rootFontSize;

    if (listItem.indent !== null) {
      // note these get divided by our root font size to prep them for rem unit output
      itemLeft =
        (twipToPx(listItem.indent.left) * reduceTo - twipToPx(listItem.indent.hanging) * reduceTo) / rootFontSize;
      itemRight = (twipToPx(listItem.indent.right) * reduceTo) / rootFontSize;
    }

    let Item = styled.div`
      display: flex;
      font-size: ${fontSize}rem;
      font-family: ${fontFamily};
      text-transform: ${transStyle};
      margin-left: ${itemLeft}rem;
      margin-right: ${itemRight}rem;
    `;

    let Before = styled.div`
      &::before {
        display: inline-block;
        width: ${beforeWidth}rem;
        flex: 0 0 ${beforeWidth}rem;
        content: ${prefixContent};
        counter-increment: ${level};
        font-size: ${beforeFontSize}rem;
        font-family: ${beforeFontFamily};
        font-weight: ${beforeFontWeight};
        font-style: ${beforeFontStyle};
      }
    `;

    // We will change the way we render these elements for the unordered lists
    if (unordered) {
      switch (format) {
        case "\u{F0B7}":
          format = "disc";
          break;
        case "\u{F0A7}":
          format = "square";
          break;
        case "o":
          format = "circle";
          break;
        default:
          break;
      }

      // TODO handle this without the magic number
      // This is just the number needed to make the bullets line up with the next level indent
      // note this gets divided by our root font size to prep them for rem unit output
      beforeWidth = 14 / rootFontSize;

      // Item itself will get the props to make this indented
      Item = styled.div`
        display: flex;
        font-size: ${fontSize}rem;
        font-family: ${fontFamily};
        text-transform: ${transStyle};
        margin-left: ${itemLeft}rem;
        margin-right: ${itemRight}rem;
      `;
      Before = styled.div`
        display: list-item;
        list-style-type: ${format};
        list-style-position: inside;
        font-size: ${beforeFontSize}rem;
        font-family: ${beforeFontFamily};
        font-weight: ${beforeFontWeight};
        font-style: ${beforeFontStyle};
      `;
    }
    // const data = contentState.getEntity(block.getEntityAt(0)).getData();
    // Return a <figure> or some other content using this data.
    return (
      <Item>
        <Before />
        <EditorBlock {...this.props}>{this.props.block.text}</EditorBlock>
      </Item>
    );
  }
}

ListItem.propTypes = {
  block: PropTypes.shape({
    text: PropTypes.string,
    getIn: PropTypes.func,
    depth: PropTypes.number,
  })
}

export default ListItem;
