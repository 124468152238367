import React from "react";

const SvgTaskListUpload = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M20.5 2h-5a.241.241 0 01-.208-.121 3.827 3.827 0 00-6.588 0A.241.241 0 018.5 2h-5A1.5 1.5 0 002 3.5v19A1.5 1.5 0 003.5 24h17a1.5 1.5 0 001.5-1.5v-19A1.5 1.5 0 0020.5 2zM19 16.26a2.5 2.5 0 01-.6 1.626l-1.919 2.24a2.5 2.5 0 01-1.9.873H5.5a.5.5 0 01-.5-.5V5.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5z" />
    <path d="M12.356 7.649a.231.231 0 00-.032-.03.5.5 0 00-.262-.115.435.435 0 00-.088 0 .5.5 0 00-.325.146l-3.5 3.5A.5.5 0 008.5 12h2a.25.25 0 01.25.25v4.25a1.25 1.25 0 002.5 0v-4.25a.25.25 0 01.25-.25h2a.5.5 0 00.354-.853z" />
  </svg>
);

export default SvgTaskListUpload;
