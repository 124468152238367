import { observable, action, decorate } from "mobx";

class McOptionsStore {
  mcOptions = {};

  setData(data) {
    if (data) {
      data.forEach((o) => {
        this.mcOptions[o.id] = o;
      });
    }
  }

  get allMcOptions() {
    return this.mcOptions.slice();
  }

  optionById(id) {
    return this.mcOptions[id];
  }
}

decorate(McOptionsStore, {
  constants: observable,
  setData: action,
});

export default new McOptionsStore();
